import { makeStyles } from "@material-ui/core/styles";

export const useHelpInfoStyles = makeStyles({
  drawerContent: {
    padding: "20px",
    width: "250px",
    whiteSpace: "pre-line",
  },
  helpInfoItem: {
    marginTop: "15px",
  }
});
