import React, { useState } from "react";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import Typography from "@material-ui/core/Typography";
import { useHelpInfoStyles } from "./styles";

export function HelpInfo({ helpInfos }) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useHelpInfoStyles();

  const handleToggleDrawer = () => setIsOpen(!isOpen);

  return (
    <>
      <IconButton onClick={handleToggleDrawer}>
        <HelpIcon fontSize="small" />
      </IconButton>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleToggleDrawer}
      >
        <div className={classes.drawerContent}>
          <Typography variant="body2">
            {helpInfos.map(helpInfo => (
              <div key={helpInfo[0]} className={classes.helpInfoItem}>
                <b>{helpInfo[0]}</b>
                <div>{helpInfo[1]}</div>
              </div>
            ))}
          </Typography>
        </div>
      </Drawer>
    </>
  )
}

HelpInfo.propTypes = {
  // [['title', 'description'], ['title2', 'description2']]
  helpInfos: PropTypes.arrayOf(PropTypes.array)
};
