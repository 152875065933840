import { makeStyles } from "@material-ui/core";

export const useTrackListStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  filterCard: {
    position: "relative",
  },
  helpButton: {
    position: "absolute",
    top: "0px",
    right: "0px"
  },
  streaming: {
    display: "flex",
    alignItems: "center",
  },
  streamingLink: {
    color: "black",
    cursor: "pointer",
    paddingLeft: "3px",
    display: "flex",
    alignItems: "center",
    "& svg": {
      maxHeight: "25px",
    }
  },
  trackDetail: {
    backgroundColor: "#FAFAFA",
    margin: "-6px -16px",
    padding: "6px 16px",
    "& th": {
      backgroundColor: "#FAFAFA",
    }
  }
}));
