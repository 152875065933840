import React from "react";
import PropTypes from "prop-types";
import { useMutation, useNotify, useRecordContext, useDataProvider } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { sleep } from "lib/utils";

const useRemoveClassFieldStyles = makeStyles(() => ({
  removeClassField: {
    display: "flex",
    alignItems: "center"
  },
  closeIcon: {
    color: "#AB003C",
    cursor: "pointer",
  }
}));

export function RemoveClassField({ categoryId, ...props }) {
  const classes = useRemoveClassFieldStyles();
  const dataProvider = useDataProvider();
  const classRecord = useRecordContext(props);

  const notify = useNotify();

  const [removeClass] = useMutation(
    {
      type: "update",
      resource: "classes"
    }, {
      onSuccess: async () => {
        notify("Successfully removed the class from the category. RELOADING...");
        await sleep(1000);
        window.location.reload();
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  async function handleClick() {
    // Have to refetch here b/c the one passed in props only has the category this component is rendered in.
    // Not sure why but has something to do with the source being said category.
    const { data } = await dataProvider.getOne("classes", {
      id: classRecord.id
    });

    const categoryIds = data.categoryIds.filter(cid => cid !== categoryId);

    await removeClass({
      payload: {
        data: {
          uuid: classRecord?.uuid,
          categoryIds
        }
      }
    });
  }

  return (
    <div className={classes.removeClassField}>
      <CloseIcon
        fontSize="small"
        className={classes.closeIcon}
        onClick={() => handleClick()}
      />
    </div>
  );
}

RemoveClassField.propTypes = {
  categoryId: PropTypes.string,
};
