import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";
import { authProvider } from "../app/providers/auth";
import { reportErrorToSentry } from "../lib/utils/sentry";

const URI = `${process.env.REACT_APP_API_DOMAIN}/graphql`;

const cache = new InMemoryCache({});

const contextLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    ...(await createAuthHeader()),
    ...createSentryHeader(),
    "apollographql-client-name": "shield",
    "apollographql-client-version": process.env.REACT_APP_VERSION,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      switch (err.extensions.code) {
        case "INTERNAL_SERVER_ERROR":
          reportErrorToSentry(new GraphQLError(err.message));
          break;
        default:
          break;
      }
    });
  }
  if (networkError) {
    reportErrorToSentry(networkError);
  }
});

const httpLink = new HttpLink({ uri: URI });

export const apolloClient = new ApolloClient({
  uri: URI,
  cache,
  link: from([contextLink, errorLink, httpLink]),
});

async function createAuthHeader() {
  const header = {};
  const user = await authProvider.getAuthUser();
  if (!user) {
    return header;
  }
  const token = await user.getIdToken();
  header["x-auth-token"] = `Bearer ${token}`;
  return header;
}

function createSentryHeader() {
  const header = {};
  header["x-transaction-id"] = sessionStorage.getItem("txid");
  return header;
}
