import { CardContent } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import React from "react";
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { HelpInfo } from "lib/components/HelpInfo";
import { LoginAsUserField } from "./components/LoginAsUserField";
import { ExportPIIField } from "./components/ExportPIIField";
import { ResetPasswordField } from "./components/ResetPasswordField";
import { UserIcon } from "../index";
import { DeleteUserAuthField } from "./components/DeleteUserAuthField";
import { useUserListStyles } from "./styles";

const userFilters = [
  <TextInput label="UID" source="uid" alwaysOn style={{ minWidth: "340px" }} />,
  <TextInput label="Email" source="email" alwaysOn style={{ minWidth: "200px" }} />,
  <TextInput label="ID" source="id"  style={{ minWidth: "340px" }} />,
];

export function UserList(props) {
  return (
    <List
      {...props}
      perPage={10}
      bulkActionButtons={false}
      filters={userFilters}
      actions={<UserListActions />}
      aside={<UserFilterSidebar />}
    >
      <Datagrid>
        <TextField source="email" label="Email" sortable={false} />
        <FunctionField label="IDs" render={record => (
          <div>
            <div>
              <strong>UID: </strong>
              <div onClick={() => {navigator.clipboard.writeText(record.uid)}}>
                {record.uid}
              </div>
            </div>
            <div>
              <strong>ID: </strong>
              <div onClick={() => {navigator.clipboard.writeText(record.id)}}>
                {record.id}
              </div>
            </div>
          </div>
        )} />
        <LoginAsUserField />
        {/*<FunctionField label="Pin" render={record => (*/}
        {/*  <div>{record.parentalControls.pin || "Not set"}</div>*/}
        {/*)} />*/}
        <ExportPIIField />
        <ResetPasswordField />
        <DeleteUserAuthField />
        <EditButton
          label="Edit User"
          icon={<WorkIcon />}
          basePath="/users" />
      </Datagrid>
    </List>
  )
}

function UserListActions() {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <ExportButton />
      {/*<ImportButton {...props} label="Bulk Enable Community" />*/}
      {/*<HelpInfo helpInfos={[*/}
      {/*  [*/}
      {/*    "Bulk Enable Community",*/}
      {/*    `Enable Community feature for all users from a csv. Upload a CSV file where there is one column. The first line is "email" and each line below it is an email. Example:*/}

      {/*   email*/}
      {/*   test@test.com*/}
      {/*   test2@test.com`*/}
      {/*  ],*/}
      {/*]} />*/}
    </TopToolbar>
  );
}

function UserFilterSidebar() {
  const classes = useUserListStyles();
  return (
    <FilterSidebar>
      <CardContent className={classes.filterCard}>
        <div className={classes.helpButton}>
          <HelpInfo helpInfos={[
            ["Filters"],
            ["Role", "This is the user's role, which defines which permissions they have."],
            ["Has Community Access", "Determines whether or not the user has access to community features."],
          ]} />
        </div>
        <FilterList label="Role" icon={<UserIcon />}>
          <FilterListItem label="Admin" value={{ role: "admin" }} />
        </FilterList>
        <FilterList label="Has Community Access" icon={<UserIcon />}>
          <FilterListItem label="Yes" value={{ hasCommunityAccess: true }} />
          <FilterListItem label="No" value={{ hasCommunityAccess: false }} />
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
}
