import * as React from "react";
import {
  Datagrid,
  EditButton,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { Link } from "react-router-dom";
import { DateTimeField } from "lib/components/DateTime";
import { CardContent } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { withEnums } from "data/utils";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { HelpInfo } from "lib/components/HelpInfo";
import { TimeFilterOptionsList } from "lib/components/TimeFilterOptionsList";
import { useChallengeListStyles } from "./styles";

const challengeFilters = [
  <TextInput
    label="Class ID"
    source="classId"
    alwaysOn
    style={{ minWidth: "340px" }}
  />,
];

const ChallengesFilterSidebar = withEnums(({ enums }) => {
  const classes = useChallengeListStyles();

  return (
    <FilterSidebar>
      <CardContent className={classes.filterCard}>
        <div className={classes.helpButton}>
          <HelpInfo
            helpInfos={[
              ["Filters"],
              [
                "Active",
                "Active indicates the time between the start and end times for the challenge.",
              ],
              [
                "Status",
                "None is when the challenge is not promoted and hidden. Approved is when the challenge is promoted and visible. Deleted is when the challenge is soft deleted and hidden.",
              ],
            ]}
          />
        </div>
        <TimeFilterOptionsList label="Active" filterFieldPrefix="active" />
        <FilterList label="Status" icon={<FilterListIcon />}>
          {enums.challengeStatus.map((challengeStatus) => (
            <FilterListItem
              label={challengeStatus}
              key={challengeStatus}
              value={{ challengeStatus }}
            />
          ))}
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
});

export function ChallengeList(props) {
  const classes = useChallengeListStyles();
  return (
    <List
      {...props}
      perPage={10}
      sort={{ field: "startTime", order: "DESC" }}
      bulkActionButtons={false}
      filters={challengeFilters}
      aside={<ChallengesFilterSidebar />}
    >
      <Datagrid>
        <TextField source="title" sortable={false} />
        <DateTimeField label="Start Time" source="startTime" />
        <DateTimeField label="End Time" source="endTime" />
        <TextField label="Status" source="challengeStatus" sortable={false} />
        <FunctionField
          render={(record) => (
            <Link
              className={classes.link}
              to={`/videoUploads?filter={"challengeId":"${record.id}"}`}
            >
              Videos
            </Link>
          )}
        />
        <EditButton className={classes.editButton} basePath="/challenges" />
      </Datagrid>
    </List>
  );
}
