import { makeStyles } from "@material-ui/core/styles";

const useAddNewTrackStyles = makeStyles(() => ({
  addNewTrack: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  modalBody: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "24px",
    width: "80%",
    minHeight: "50vh",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    position: "absolute",
    top: "10px",
    left: "16px",
  },
  close: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  modalForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  createButton: {
    width: "30%",
    marginTop: "30px"
  },
  isrcInput: {
    marginBottom: "10px",
    width: "100%",
  },
  startsAtStrInput: {
    width: "100%",
  }
}));

export default useAddNewTrackStyles;
