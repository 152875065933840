import * as React from "react";
import {
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceManyField,
  useMutation,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { withEnums } from "data/utils";
import { DateTimeField } from "lib/components/DateTime";
import { PlayVideoField } from "lib/components/PlayVideoField";
import { EncodingStatusField } from "lib/components/EncodingStatusField";
import { UploadVideo } from "lib/components/UploadVideo";
import { ConfirmButtonAndModal } from "lib/components/ConfirmButtonAndModal";
import { HelpInfo } from "lib/components/HelpInfo";

export const Videos = withEnums(({ enums, ...props }) => (
    <Box p={1}>
    <Box display="flex" alignItems="center" p={1}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Videos</Typography>
        <HelpInfo helpInfos={[
          [
            "Preview Video",
            "The preview video is a short video that can be played in the thumbnail of a class in a list of categories."
          ],
          [
            "Encoding Status",
            `Requested is when you have made the intent to start uploading a video.

            Uploaded is when you have successfully uploaded a video. 

            Available is when the video has finished encoding and is ready to watch.

            Error is when there has been an issue with encoding. Click on the encoding status to see the specific error.`
          ]
        ]} />
      </div>
      <UploadVideo
        ownerId={props.record?.id}
        ownerType="category"
        subtitle={props.record?.name}
      />
    </Box>
    <Box pl={1} pb={1} style={{ display: "flex" }}>
      <div>
        <Typography variant="overline">Preview Video</Typography>
        <PlayVideoField source="previewVideo.hlsPlaybackUrls" />
      </div>
    </Box>
      <ReferenceManyField
        label="Videos"
        reference="categoryVideos"
        target="id"
        sort={{ field: "createdAt", order: "DESC" }}
        pagination={<Pagination />}
        perPage={100}
      >
        <Datagrid>
          <EncodingStatusField source="encodingStatus" label="Encoding Status" sortable={false} />
          <DateTimeField label="Created At" source="createdAt" sortable />
          <PlayVideoField label="HLS Video" source="hlsPlaybackUrls" sortable={false} />
          <FunctionField render={record => {
            if (props.record?.previewVideo?.id !== record.id && record.encodingStatus === "available") {
              return (<SetPreviewVideoField categoryId={props.record?.id} />);
            }
            return null;
          }} />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  ));

export default Videos;

function SetPreviewVideoField({categoryId, ...props}) {

  const record = useRecordContext(props);

  const notify = useNotify();

  const [updatePreviewVideo, { updatingPreviewVideo }] = useMutation(
    {
      type: "update",
      resource: "categories",
      payload: {
        data: {
          id: categoryId,
          previewVideoId: record?.id
        }
      }
    }, {
      onSuccess: () => {
        notify("Successfully updated category preview video");
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  return (
    <div>
      <ConfirmButtonAndModal
        isUpdating={updatingPreviewVideo}
        onConfirm={updatePreviewVideo}
        buttonText="Set as preview"
        title="Confirm Setting Video as Preview Video"
      />
    </div>
  );
}

SetPreviewVideoField.propTypes = {
  categoryId: PropTypes.string,
}
