import React, { useState } from "react";
import { FunctionField, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { updateUserLogin } from "../../../../data/users";
import { generateRandomPassword } from "../../../../lib/utils";

export function ResetPasswordField() {
  const [ password, setPassword ] = useState("");
  const notify = useNotify();

  return (
    <FunctionField
      label="Reset Password"
      render={record => {
        if (password) {
          return (<p>{password}</p>);
        }

        return (
          <Button
            onClick={async () => {
              const password = generateRandomPassword();

              await updateUserLogin({ uid: record.uid, password });
              await navigator.clipboard.writeText(password);

              setPassword(password);

              notify("New password copied to clipboard!");
            }}
            color="primary"
            variant="contained"
            size="small"
          >
            Reset Password
          </Button>
        );
      }}
    />
  );
}
