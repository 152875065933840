import React from "react";
import { Admin } from "react-admin";
import { authProvider } from "app/providers/auth";
import { dataProvider } from "data";
import { Resources } from "../resources";
import { theme } from "../theme";
import { Dashboard } from "./components/Dashboard";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { CustomLayout } from "./components/Layout";
import { LoginPage } from "./components/LoginPage";
import { i18nProvider } from "./providers/i18nProvider";

export function App() {
  return (
    <ErrorBoundary>
      <Admin
        title="STEEZY Admin"
        layout={CustomLayout}
        theme={theme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        loginPage={LoginPage}
        dashboard={Dashboard}
      >
        { Resources }
      </Admin>
    </ErrorBoundary>
  );
}
