import React from "react";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  endOfToday,
} from "date-fns";
import PropTypes from "prop-types";
import {
  FilterList,
  FilterListItem,
} from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export function TimeFilterOptionsList({ filterFieldPrefix, label }) {
  const todayFilter = () => ({
    [`${filterFieldPrefix}After`]: endOfYesterday().toISOString(),
    [`${filterFieldPrefix}Before`]: endOfToday().toISOString(),
  });

  const thisWeekFilter = () => ({
    [`${filterFieldPrefix}After`]: startOfWeek(new Date()).toISOString(),
    [`${filterFieldPrefix}Before`]: endOfToday().toISOString(),
  });

  const lastWeekFilter = () => ({
    [`${filterFieldPrefix}After`]: subWeeks(startOfWeek(new Date()), 1).toISOString(),
    [`${filterFieldPrefix}Before`]: startOfWeek(new Date()).toISOString()
  });

  const thisMonthFilter = () => ({
    [`${filterFieldPrefix}After`]: startOfMonth(new Date()).toISOString(),
    [`${filterFieldPrefix}Before`]: endOfToday().toISOString(),
  });

  const lastMonthFilter = () => ({
    [`${filterFieldPrefix}After`]: subMonths(startOfMonth(new Date()),1).toISOString(),
    [`${filterFieldPrefix}Before`]: startOfMonth(new Date()).toISOString()
  });

  return (
    <FilterList label={label} icon={<AccessTimeIcon />}>
      <FilterListItem label="Today" value={todayFilter()}/>
      <FilterListItem label="This week" value={thisWeekFilter()}/>
      <FilterListItem label="Last week" value={lastWeekFilter()}/>
      <FilterListItem label="This month" value={thisMonthFilter()}/>
      <FilterListItem label="Last month" value={lastMonthFilter()}/>
    </FilterList>
  );
}

TimeFilterOptionsList.propTypes = {
  filterFieldPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
