import React from "react";
import { useQuery, useRecordContext, useInput } from "react-admin";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { RemoveCircle } from "@material-ui/icons";
import { normalizeById } from "../../../../lib/utils";

export function TagsTab(props) {
  const { tags: originalTags } = useRecordContext(props);

  const { input: { value, onChange } } = useInput(props);

  const { tags, loadingTags, errorTags } = useAllTags();

  if (loadingTags) {
    return <>Loading!</>;
  } else if (errorTags) {
    return <>Error loading tags!</>;
  }

  const originalTagsById = normalizeById(originalTags);
  const updatedTagsById = normalizeById(value);

  const tagsById = normalizeById(tags);
  const tagsByTagType = normalizeTagsByTagType(tags);

  const GroupedCheckboxes = Object.entries(tagsByTagType).map(([ tagType, tags ]) => {
    const Checkboxes = tags.map(({ id: tagId, name: tagName }) => {
      const isAdding = (updatedTagsById[tagId] && !originalTagsById[tagId]);
      const isRemoving = (originalTagsById[tagId] && !updatedTagsById[tagId]);

      const updatingOriginalTags = (isAdding || isRemoving);

      const ControlElement = (
        <Checkbox
          checked={!!updatedTagsById[tagId]}
          color={updatingOriginalTags ? "secondary" : "primary"}
          icon={updatingOriginalTags ? <RemoveCircle/> : undefined}
          name={tagName}
          onChange={e => toggleCheckbox(e.target.value)}
          style={{padding: "6px 9px 6px 12px"}}
          value={tagId}
        />
      );

      return (
        <FormControlLabel
          control={ControlElement}
          label={tagName}
        />
      );
    });

    return (
      <>
        <h1 style={{margin: "0.7rem 0", textDecoration: "underline"}}>
          {tagType}
        </h1>
        <FormGroup>
          {Checkboxes}
        </FormGroup>
      </>
    )
  });

  function toggleCheckbox(tagId) {
    const checked = updatedTagsById[tagId];
    const newValue = {...updatedTagsById};

    if (checked) {
      delete newValue[tagId];
    } else {
      newValue[tagId] = tagsById[tagId];
    }

    onChange(Object.values(newValue));
  }

  return (
    <div>
      { GroupedCheckboxes }
    </div>
  );
}

function useAllTags() {
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "tags",
    payload: {
      pagination: {
        page: 1,
        perPage: 2000 // Realistically should never pass this number.
      }
    }
  });

  return {
    tags: data,
    loadingTags: loading,
    errorTags: error,
  };
}

function normalizeTagsByTagType(tags) {
  return tags.reduce((acc, t) => {
    const tagType = t.type;

    if (!acc[tagType]) {
      acc[tagType] = [];
    }

    acc[tagType].push(t);

    return acc;
  }, {});
}
