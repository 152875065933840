import React from "react";
import { useQuery } from "react-admin";
import { UNIQUE_ENUMS_ID } from "./enums";

export function convertAdminPgToGraphqlRelayPg(pagination) {
  if (!pagination) {
    return null;
  }
  const afterCursor = (pagination.page - 1) * pagination.perPage;
  return {
    // "after" works because cursor is currently skip number, but if we want real cursor, we'll need to save this state somewhere
    after: `${afterCursor}`,
    first: pagination.perPage,
  };
}

export function convertAdminSortToGraphqlSort(sort) {
  if (!sort) {
    return null;
  }
  return (
    {
      sortBy: sort.field,
      sortOrder: sort.order === "DESC" ? "desc" : "asc",
    }
  );
}

// @TODO rewrite these and other `with` getters as hooks
export function withEnums(Component) {
  const WithEnumsComponent = props => {
    const { data, loading, error } = useQuery({
      type: "getOne",
      resource: "enums",
      payload: { id: UNIQUE_ENUMS_ID }
    });

    if (loading || !!error || !data) {
      return null;
    }

    return <Component enums={data} {...props} />;
  };

  return WithEnumsComponent;
}

export async function uploadAssetToS3(signedUrl, file, contentType) {
  await fetch(signedUrl, {
    method: "PUT",
    headers: { "Content-Type": contentType },
    body: file.slice(0, file.size)
  });
}
