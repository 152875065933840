import { makeStyles } from "@material-ui/core";

export const usePublishEditToolbarStyles = makeStyles(theme => ({
  toolbar: {
    position: "absolute",
    top: "70px",
    right: "16px",
    backgroundColor: "transparent",
    maxWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      right: "0px"
    },
    flexDirection: "column",
    alignItems: "flex-start",
  },
  saveButton: {
    width: "200px",
  }
}));

