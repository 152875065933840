import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, Modal } from "@material-ui/core";
import { PlayVideoField } from "lib/components/PlayVideoField";
import Comment from "@material-ui/icons/Comment";

const useFlaggedActivityContentFieldStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
  },
  videoField: {
    display: "flex",
    alignItems: "center"
  },
  commentModalContent: {
    backgroundColor: "white",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  }
}));

export function FlaggedActivityContentField(props) {
  const classes = useFlaggedActivityContentFieldStyles();
  const record = useRecordContext(props);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      {record.activityType === "user_video" && (
        <div className={classes.videoField}>
          <PlayVideoField source="content.playbackUrl" />
          <Link
            className={classes.link}
            to={`/videoUploads?displayedFilters={"id":true}&filter={"id":"${record.activityId}"}`}>
              Video
          </Link>
        </div>
      )}
      {record.activityType === "user_comment" && (
        <div>
          <Comment title="commentButton" onClick={handleToggleModal} />
          <Modal open={isModalOpen} onClose={handleToggleModal}>
            <DialogContent className={classes.commentModalContent} onClick={handleToggleModal}>
              <span>{record.content?.body}</span>
            </DialogContent>
          </Modal>
        </div>
      )}
    </div>
  );
};

FlaggedActivityContentField.defaultProps = {
  addLabel: true
};

FlaggedActivityContentField.propTypes = {
  addLabel: PropTypes.bool, // provided by SimpleShowLayout, TabbedShowLayout, SimpleForm, TabbedForm which looks at prop value for child component (DateField)
};
