import { gql } from "@apollo/client";

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
  }
`;

export const GET_LIST_ROLES = gql`
  ${ROLE_FRAGMENT}
  query Roles {
    admin {
      roles {
          ...RoleFragment
      }  
    }
  }
`;