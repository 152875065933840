import omitDeep from "omit-deep";
import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
  uploadAssetToS3,
} from "../utils";
import {
  CREATE_STUDIO_CLASS,
  CREATE_STUDIO_CLASS_ASSET,
  GET_CLASS_LEVELS,
  GET_CLASS_STYLES,
  GET_CLASS_TYPES,
  GET_LIST_STUDIO_CLASS,
  GET_LIST_STUDIO_CLASS_BY_PLAYLIST_ID,
  GET_ONE_STUDIO_CLASS,
  PUBLISH_STUDIO_CLASS,
  STUDIO_CLASS_ADD_TRACK,
  STUDIO_CLASS_REMOVE_TRACK,
  UNPUBLISH_STUDIO_CLASS,
  UPDATE_STUDIO_CLASS,
} from "./schema";

export async function classCreate(params) {
  const { data } = params;

  const res = await client.mutate(CREATE_STUDIO_CLASS, {
    input: {
      classId: data.classId,
      title: data.title,
    },
  });

  const createdClass = res.adminStudioClassCreate;

  return {
    data: transformReturnClassData(createdClass),
  };
}

export async function getListClasses(params) {
  let data = null;

  if (params.target === "playlistId") {
    const res = await client.query(GET_LIST_STUDIO_CLASS_BY_PLAYLIST_ID, {
      playlistId: params.filter.playlistId,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort),
    });

    data = res.playlist.studioClasses;
  } else {
    data = await client.query(
      GET_LIST_STUDIO_CLASS,
      {
        filter: params.filter,
        pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
        sort: convertAdminSortToGraphqlSort(params.sort),
      },
      "studioClasses"
    );
  }

  return {
    data: data.edges.map((e) =>
      transformReturnClassData({
        hasMutedCuepoints: !!e.node.mutedCuepoints?.length,
        hasSections: !!e.node.sections?.length,
        hasTracks: !!e.node.tracks?.length,
        ...e.node,
      })
    ),
    total: data.totalCount,
  };
}

export async function getManyReferenceClasses(params) {
  return getListClasses({
    ...params,
    filter: {
      [params.target]: params.id,
    },
  });
}

export async function getOneClass({ id }) {
  const aClass = await client.query(
    GET_ONE_STUDIO_CLASS,
    {
      classId: Number(id),
    },
    "studioClassByClassId"
  );

  return {
    data: transformReturnClassData(aClass),
  };
}

export async function getManyClasses(params) {
  const results = { data: [] };

  // eslint-disable-next-line no-restricted-syntax
  for (const id of params?.ids) {
    // eslint-disable-next-line no-await-in-loop
    const { data: aClass } = await getOneClass({ id });
    results.data.push(transformReturnClassData(aClass));
  }

  return results;
}

export async function classUpdate({ uuid, data }) {
  const thumbnailImageId =
    data.thumbnailImageUrl?.rawFile instanceof File
      ? await uploadNewAsset(
          data.thumbnailImageUrl.rawFile,
          "thumbnail",
          "image/jpeg"
        )
      : null;

  let sections = null;
  if (data?.sections) {
    sections = omitDeep(data.sections, ["__typename"]);
  }

  let tagIds = null;
  if (data?.tags) {
    tagIds = data.tags.map((t) => t.id);
  }

  // Only need to pass in the fields that are required to be updated
  const normalizedMutedCuepoints = data?.mutedCuepoints?.map(
    (mutedCuepoint) => {
      return {
        externalUrl: mutedCuepoint.externalUrl,
        endsAtTimeSec: mutedCuepoint.endsAtTimeSec,
        startsAtTimeSec: mutedCuepoint.startsAtTimeSec,
      };
    }
  );

  const res = await client.mutate(UPDATE_STUDIO_CLASS, {
    input: {
      classId: uuid || data?.uuid,
      accessType: data?.accessType,
      hidden: data?.hidden,
      free: data?.free,
      isFamilyFriendly: data?.isFamilyFriendly,
      mutedCuepoints: normalizedMutedCuepoints,
      externalPreviewUrl: data?.externalPreviewUrl,
      shouldBeMuted: data?.shouldBeMuted,
      title: data?.title,
      slug: data?.slug,
      instructorId: data?.instructor?.id,
      level: data?.level,
      type: data?.type,
      categoryIds: data?.categoryIds,
      style: data?.style,
      videoId: data?.videoId,
      previewVideoId: data?.previewVideoId,
      publishDate: data?.publishDate,
      ...(sections ? { sections } : {}),
      ...(tagIds ? { tagIds } : {}),
      ...(thumbnailImageId ? { thumbnailImageId } : {}),
    },
  });

  const updatedClass = res.adminStudioClassUpdate;

  return {
    data: transformReturnClassData(updatedClass),
  };
}

export async function classPublish(params) {
  const { uuid } = params;

  const res = await client.mutate(PUBLISH_STUDIO_CLASS, {
    studioClassId: uuid,
  });

  const updatedClass = res.adminStudioClassPublish;

  return {
    data: transformReturnClassData(updatedClass),
  };
}

export async function classUnPublish(params) {
  const { uuid } = params;

  const res = await client.mutate(UNPUBLISH_STUDIO_CLASS, {
    studioClassId: uuid,
  });

  const updatedClass = res.adminStudioClassUnPublish;

  return {
    data: transformReturnClassData(updatedClass),
  };
}

export async function addClassTrack(params) {
  const {
    data: { uuid: classId, isrc, startsAt },
  } = params;

  const res = await client.mutate(STUDIO_CLASS_ADD_TRACK, {
    input: { classId, isrc, startsAt },
  });

  const updatedClass = res.adminStudioClassAddTrack;

  return {
    data: transformReturnClassData(updatedClass),
  };
}

export async function removeClassTrack(params) {
  const {
    data: { uuid: classId, isrc, startsAt },
  } = params;

  const res = await client.mutate(STUDIO_CLASS_REMOVE_TRACK, {
    input: { classId, isrc, startsAt },
  });

  const updatedClass = res.adminStudioClassRemoveTrack;

  return {
    data: transformReturnClassData(updatedClass),
  };
}

const CLASS_TYPES_CACHE_DURATION = 60 * 60 * 1000; // 1 hour

function getValidUntil() {
  const validUntil = new Date();
  validUntil.setTime(validUntil.getTime() + CLASS_TYPES_CACHE_DURATION);

  return validUntil;
}

export async function getClassStyles() {
  const data = await client.query(GET_CLASS_STYLES, null, "allClassStyles");

  return {
    data: data.map((it) => ({ id: it.slug, name: it.name, slug: it.slug })),
    total: data.length,
    validUntil: getValidUntil(),
  };
}

export async function getClassLevels() {
  const data = await client.query(GET_CLASS_LEVELS, null, "allClassLevels");

  return {
    data: data.map((it) => ({ id: it.name, name: it.name })),
    total: data.length,
    validUntil: getValidUntil(),
  };
}

export async function getClassTypes() {
  const data = await client.query(GET_CLASS_TYPES, null, "allClassTypes");

  return {
    data: data.map((it) => ({ id: it.name, name: it.name })),
    total: data.length,
    validUntil: getValidUntil(),
  };
}

async function uploadNewAsset(file, assetType, contentType) {
  const res = await client.mutate(CREATE_STUDIO_CLASS_ASSET, {
    assetType,
  });

  const { id, signedUrl } = res.adminStudioClassAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}

function transformReturnClassData(studioClass) {
  const newStudioClass = {
    ...studioClass,
    id: studioClass.classId,
    uuid: studioClass.id,
  };

  if (newStudioClass?.categories) {
    newStudioClass.categoryIds = studioClass?.categories?.map((it) => it.id);
  }

  return newStudioClass;
}
