import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { useRecordContext } from "react-admin";
import { Typography } from "@material-ui/core";

export function ReadOnlyField({ source, ...props }) {
  const record = useRecordContext(props);

  return (
    <Typography>
      {get(record,source)}
    </Typography>
  );
}

ReadOnlyField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

