import { gql } from "@apollo/client";

const INSTRUCTOR_FRAGMENT = gql`
  fragment InstructorFragment on ClassInstructor {
    id
    createdAt
    updatedAt
    slug
    hidden
    bio
    credits
    avatarImageId
    avatarImageUrl
    profileImageId
    profileImageUrl  
    location
    name
    facebook
    instagram
    twitter
    youtube
    tiktok
    featuredClass {
      id
      classId
      title  
    }  
  }
`;

export const GET_ALL_INSTRUCTOR_CREDITS = gql`
  query InstructorCredits {
    admin {
      instructorCredits {
        id
        name
      }
    }
  }
`;

export const GET_LIST_INSTRUCTORS = gql`
  ${INSTRUCTOR_FRAGMENT}

  query AllInstructors($sort: OrderByInput, $pagination: ConnectionArgs, $filter: InstructorFilterInput) {
    admin {
      instructors(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...InstructorFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_INSTRUCTOR = gql`
  ${INSTRUCTOR_FRAGMENT}
  query InstructorById($id: String!) {
    admin {
      instructorById(id: $id) {
        ...InstructorFragment
      }
    }
  }
`;

export const CREATE_INSTRUCTOR = gql`
  ${INSTRUCTOR_FRAGMENT}
  mutation CreateInstructor($input: InstructorCreateInput!) {
    adminInstructorCreate(input: $input) {
      ...InstructorFragment
    }
  }
`;

export const UPDATE_INSTRUCTOR = gql`
  ${INSTRUCTOR_FRAGMENT}
  mutation UpdateInstructor($input: InstructorUpdateInput!) {
    adminInstructorUpdate(input: $input) {
      ...InstructorFragment
    }
  }
`;

export const CREATE_INSTRUCTOR_ASSET = gql`
    mutation InstructorAssetUploadCreate($assetType: InstructorAssetType!) {
        adminInstructorAssetUploadCreate(assetType: $assetType) {
            id
            signedUrl
        }
    }
`;
