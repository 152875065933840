import { makeStyles } from "@material-ui/core";

export const useConfirmModalStyles = makeStyles(() => ({
  modalBody: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    paddingTop: "60px",
    width: "80%",
    minHeight: "10vh",
    maxWidth: "350px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTitle: {
    position: "absolute",
    top: "10px",
    left: "10px",
    fontSize: "16px",
  },
  close: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  actionButtonConfirm: {
    marginBottom: "10px"
  },
  actionButtonCancel: {
    opacity: 0.8
  }
}));
