import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort
} from "../utils";
import {
  CREATE_CHALLENGE,
  GET_LIST_CHALLENGES,
  GET_ONE_CHALLENGE,
  UPDATE_CHALLENGE
} from "./schema";

export async function challengeCreate(params) {
  const { data } = params;

  const challenge = await client.mutate(
    CREATE_CHALLENGE,
    {
      input: {
        classId: data.forClass.id,
        endTime: data.endTime,
        title: data.title,
        description: data.description,
        startTime: data.startTime,
        challengeStatus: data.challengeStatus,
      }
    },
    "challengeCreate"
  );

  return {
    data: challenge
  };
}

export async function getListChallenges(params) {
  const data = await client.query(
    GET_LIST_CHALLENGES,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "challenges"
  );

  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function getOneChallenge({ id }) {
  const data = await client.query(
    GET_ONE_CHALLENGE,
    {
      id
    },
    "challengeById"
  );

  return { data };
}

export async function challengeUpdate(params) {
  const { id, data } = params;

  const challenge = await client.mutate(
    UPDATE_CHALLENGE,
    {
      input: {
        challengeId: id,
        endTime: data.endTime,
        title: data.title,
        description: data.description,
        startTime: data.startTime,
        challengeStatus: data.challengeStatus,
      }
    },
    "challengeUpdate"
  );

  return {
    data: challenge
  };
}
