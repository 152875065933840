import { CardContent, Typography } from "@material-ui/core";
import { Apple, Explicit, VerifiedUser, YouTube } from "@material-ui/icons";
import React from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FilterList,
  FilterListItem,
  FunctionField,
  linkToRecord,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import { Link } from "react-router-dom";
import { HelpInfo } from "lib/components/HelpInfo";
import { SoundcloudLogo, SpotifyLogo, TikTokLogo } from "lib/components/Icon";
import { FilterSidebar } from "lib/components/FilterSidebar";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useEnums } from "lib/hooks";
import { useTrackListStyles } from "./styles";

const trackFilters = [
  <TextInput
    label="ISRC"
    source="isrc"
    alwaysOn
    style={{ minWidth: "340px" }}
  />,
  <TextInput
    label="Artist Name (exact full name)"
    alwaysOn
    source="artist"
    style={{ minWidth: "340px" }}
  />,
  <TextInput
    label="Title"
    alwaysOn
    source="title"
    style={{ minWidth: "340px" }}
  />,
  <TextInput label="Class Number" source="classNum" />,
  <TextInput label="Track ID" source="trackId" style={{ minWidth: "340px" }} />,
  <TextInput label="Label Name" source="label" style={{ minWidth: "340px" }} />,
  <TextInput
    label="Class DB ID"
    source="classId"
    style={{ minWidth: "340px" }}
  />,
];

export function TrackList(props) {
  const classes = useTrackListStyles();

  return (
    <List
      {...props}
      perPage={50}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={false}
      actions={<TrackListActions />}
      filters={trackFilters}
      aside={<TracksFilterSidebar />}
    >
      <Datagrid expand={<TrackDetail />}>
        <TextField label="ISRC" source="isrc" />
        <BooleanField
          label="Explicit"
          source="isExplicit"
          TrueIcon={Explicit}
          FalseIcon={() => null}
        />
        <BooleanField
          label="Licensed"
          source="isLicensed"
          TrueIcon={VerifiedUser}
          FalseIcon={() => null}
        />
        <TextField source="title" />
        <FunctionField
          label="Artists"
          render={(record) => <span>{record.artists.join(", ")}</span>}
        />
        <TextField source="label" />
        <TextField source="releaseDate" />
        <FunctionField
          label="Streaming"
          render={(record) => <StreamingLinks {...record} />}
        />
        <TextField label="Source" source="source" sortable={false} />
        <EditButton className={classes.editButton} basePath="/tracks" />
      </Datagrid>
    </List>
  );
}

function TrackDetail() {
  const classes = useTrackListStyles();

  return (
    <div className={classes.trackDetail}>
      <Typography variable="header1">Classes</Typography>
      <ReferenceManyField
        addLabel={false}
        reference="classes"
        target="trackId"
        pagination={<Pagination />}
      >
        <Datagrid empty={<span>No classes linked</span>}>
          <FunctionField
            label="ID"
            render={(record) => {
              const linkToClass = linkToRecord("/classes", record.id);
              return <Link to={linkToClass}>{record.classId}</Link>;
            }}
          />
          <TextField source="title" sortable />
          <TextField label="Instructor" source="instructor.name" sortable />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
}

function StreamingLinks(record) {
  const { youtube, appleMusic, spotify, tikTokSound, soundcloud } = record;

  const classes = useTrackListStyles();

  const renderLink = (linkValue, linkSettings) => {
    const SvgComponent = linkSettings.svg;
    return (
      <a
        title={linkSettings.label}
        aria-label={linkSettings.label}
        href={linkValue}
        target="_blank"
        rel="noreferrer"
        className={classes.streamingLink}
      >
        <SvgComponent style={{ height: linkSettings.height }} />
      </a>
    );
  };

  return (
    <div className={classes.streaming}>
      {youtube && renderLink(youtube, { label: "YouTube", svg: YouTube })}
      {appleMusic &&
        renderLink(appleMusic, { label: "Apple Music", svg: Apple })}
      {spotify &&
        renderLink(spotify, {
          label: "Spotify",
          svg: SpotifyLogo,
          height: "18px",
        })}
      {tikTokSound &&
        renderLink(tikTokSound, {
          label: "Tik Tok",
          svg: TikTokLogo,
          height: "18px",
        })}
      {soundcloud &&
        renderLink(soundcloud, { label: "Soundcloud", svg: SoundcloudLogo })}
    </div>
  );
}

const importConfig = {
  transformRows: (csvRows) =>
    csvRows.map((song) => ({
      isrc: song.isrc,
      trackId: song["song id"],
      songId: song["song id"],
      appleMusic: song.apple_music,
      spotify: song.spotify,
      soundcloud: song.soundcloud,
      youtube: song.youtube,
      tikTokSound: song.tiktok_sound,
      classes: song.Classes
        ? song.Classes.split(",").map((id) => ({ id }))
        : [],
    })),
};

function TrackListActions(props) {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <ImportButton {...props} {...importConfig} label="Bulk Import" />
      <HelpInfo
        helpInfos={[
          [
            "Bulk Import Classes",
            `Upload a CSV file that has the following columns. The first row contains the column names.

        isrc
        song id
        apple_music
        spotify
        soundcloud
        youtube
        tiktok_sound
        classes - value is comma separated string of class DB ids
        `,
          ],
        ]}
      />
    </TopToolbar>
  );
}

function TracksFilterSidebar() {
  const classes = useTrackListStyles();
  const { enums } = useEnums();

  return (
    <FilterSidebar>
      <CardContent style={{ position: "relative" }}>
        <div className={classes.helpButton}>
          <HelpInfo
            helpInfos={[
              ["Filters"],
              [
                "Sources",
                "Source is where the track data originated either added manually or pulled from Tuned Global.",
              ],
              ["Licensed", "Whether track is considered licensed or not."],
            ]}
          />
        </div>
        {enums && (
          <FilterList label="Source" icon={<FilterListIcon />}>
            {enums.trackSource.map((source) => (
              <FilterListItem label={source} key={source} value={{ source }} />
            ))}
          </FilterList>
        )}

        <FilterList label="Licensed" icon={<VerifiedUser />}>
          <FilterListItem label="licensed" value={{ isLicensed: true }} />
          <FilterListItem label="not licensed" value={{ isLicensed: false }} />
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
}
