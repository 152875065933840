import { client } from "../gqlClient";
import { GET_LIST_ROLES } from "./schema";

export async function getListRoles() {
  const { roles } = await client.query(GET_LIST_ROLES);

  return {
    data: roles,
    total: roles.length,
  };
}

// Note: This is automatically called by react-admin during role input selection
// to allow us to display more information about the role. We don't need
// this feature yet.
export async function getManyRoles() {
  return {
    data: []
  };
}
