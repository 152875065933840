import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useDataProvider, useMutation, useNotify } from "react-admin";
import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import debounce from "lodash.debounce";
import { sleep } from "lib/utils";
import { useAddClassStyles } from "./styles";

export function AddClass({ categoryId }) {
  // modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  // class num
  const [classNum, setClassNum] = useState("");
  const [invalidClassNum, setInvalidClassNum] = useState(false);

  // class title
  const [selectedClass, setSelectedClass] = useState(null);
  const [isAutocompleteOpen, setAutocompleteOpen] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const dataProvider = useDataProvider();
  const classes = useAddClassStyles();
  const notify = useNotify();

  const [addClassFn, { loading: addingClass }] = useMutation(
    {
      type: "update",
      resource: "classes",
      payload: {
        uuid: selectedClass?.uuid
      }
    }, {
      onSuccess: async () => {
        notify("Successfully added the class to the category. RELOADING...");
        window.location.reload();
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  const handleToggleModal = () => {
    if (!isModalOpen) {
      setClassNum("");
      setInvalidClassNum(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  const onAddClass = async () => {
    setInvalidClassNum(false);

    if ((!classNum || classNum.length === 0) && !selectedClass) {
      notify("Please select a class or provide a class number.");
      return;
    }
    let classRecord = null;
    if (classNum && classNum.length > 0) {
      const listResult = await dataProvider.getList("classes", {
        filter: {
          classId: classNum
        }
      });
      if (listResult.data.length === 0) {
        setInvalidClassNum(true);
        notify("Please provide valid class number.");
        return;
      }
      // eslint-disable-next-line prefer-destructuring
      classRecord = listResult.data[0];
    } else {
      classRecord = selectedClass;
    }

    await addClassFn({
      payload: {
        data: {
          uuid: classRecord.uuid,
          categoryIds: [...classRecord.categories.map(c => c.id), categoryId],
        }
      }
    })
    await sleep(1000);

    setIsModalOpen(false);
  }

  const handleClassNumChange = e => {
    setClassNum(e.target.value);
    setSelectedClass(null);
  };

  const handleAutocompleteInputChange = debounce(async e => {
    const { value } = e.target;
    setInputValue(value);
    setClassNum("");
    updateAutocompleteOptions(value);
  }, 500);

  const updateAutocompleteOptions = async searchValue => {
    setLoading(true);
    const options = { filter: { } };
    if (searchValue && searchValue.length > 0) {
      options.filter.title = searchValue;
    }
    const listResult = await dataProvider.getList("classes", options);
    setAutocompleteOptions(listResult.data);
    setLoading(false);
  };

  const handleSelectClass = (e, value) => {
    setClassNum("");
    setSelectedClass(value);
  };

  useEffect(() => {
    if (!isAutocompleteOpen) {
      setAutocompleteOptions([]);
    } else {
      updateAutocompleteOptions("");
    }
  }, [isAutocompleteOpen]);

  return (
    <div className={classes.addClass}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleToggleModal}
        size="small"
      >
        Add Class
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleToggleModal}
      >
        <div className={classes.modalBody}>
          <Typography className={classes.modalTitle} variant="h6">Add Class to Category</Typography>
          <CloseIcon className={classes.close} title="close" onClick={handleToggleModal} />
          <div className={classes.modalForm}>
            <Autocomplete
              className={classes.classNumInput}
              open={isAutocompleteOpen}
              onOpen={() => { setAutocompleteOpen(true); }}
              onClose={() => { setAutocompleteOpen(false); }}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={option => option.title}
              options={autocompleteOptions}
              loading={loading}
              value={selectedClass}
              onChange={handleSelectClass}
              filterOptions={options => options}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Class Title"
                  value={inputValue}
                  variant="filled"
                  onChange={handleAutocompleteInputChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Typography style={{ textAlign: "center", width: "100%" }}>OR</Typography>
            <TextField
              label="Class Number"
              className={classes.classNumInput}
              value={classNum}
              onChange={handleClassNumChange}
              variant="filled"
              error={invalidClassNum}
            />
            <Button
              className={classes.createButton}
              disabled={addingClass}
              color="primary"
              variant="contained"
              onClick={onAddClass}
              size="medium"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

AddClass.propTypes = {
  categoryId: PropTypes.string,
};
