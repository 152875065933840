import { convertAdminPgToGraphqlRelayPg, convertAdminSortToGraphqlSort } from "data/utils";
import { client } from "../gqlClient";
import {
  BULK_ENABLE_COMMUNITY,
  CREATE_AUTO_LOGIN_TOKEN,
  DELETE_USER_AUTH,
  GET_LIST_USERS,
  GET_ME,
  GET_ONE_USER,
  GET_ONE_USER_BY_EMAIL,
  GET_ONE_USER_BY_UID,
  GET_ONE_USER_BY_USERID,
  GET_USER_PII, RANDOMIZE_USER_PERSONALIZED_PLAYLIST,
  UPDATE_USER,
  UPDATE_USER_LOGIN,
} from "./schema";

export const ADMIN_USER_ROLE = "admin";

export async function getMe() {
  const data = await client.query(GET_ME, {}, "me", "cache-first");

  return { data };
}

export async function getMeRoles() {
  const getMeResp = await getMe();

  return getMeResp && getMeResp.data && getMeResp.data.roles;
}

export async function getListUsers(params) {
  const { filter } = params;

  if (!Object.keys(filter).length) {
    return { data: [], total: 1 };
  }

  const filterForLookupFn = user =>
    (!filter.role || (filter.role && user.roles.map(r => r.name).indexOf(filter.role) >= 0)) &&
    (filter.hasCommunityAccess == null || (
      filter.hasCommunityAccess && user.hasCommunityAccess === filter.hasCommunityAccess
    ));

  if (filter.uid) {
    const data = await client.query(GET_ONE_USER_BY_UID, { uid: filter.uid }, "userByUid" );
    const results = [data].filter(filterForLookupFn);
    return { data: results, total: results.length };
  }

  if (filter.id) {
    const data = await client.query(GET_ONE_USER_BY_USERID, { id: filter.id }, "userById" );
    const results = [data].filter(filterForLookupFn);
    return { data: results, total: results.length };
  }

  if (filter.email) {
    const data = await client.query(GET_ONE_USER_BY_EMAIL, { email: filter.email }, "userByEmail");
    const results = [data].filter(filterForLookupFn);
    return { data: results, total: results.length };
  }

  const data = await client.query(
    GET_LIST_USERS,
    {
      filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "users"
  );

  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function getOneUser(params) {
  const data = await client.query(GET_ONE_USER, { id: params.id }, "userById");

  return {
    data: transformUserResponse(data),
  };
}

export async function updateUser(params) {
  const { id, data } = params;

  const user = await client.mutate(
    UPDATE_USER,
    {
      input: {
        userId: id,
        roles: data.roles,
        hasCommunityAccess: data.hasCommunityAccess,
      },
    },
    "userUpdate"
  );

  return {
    data: transformUserResponse(user)
  };
}

export async function bulkEnableCommunity(params) {
  const { data } = params;

  const count = await client.mutate(
    BULK_ENABLE_COMMUNITY,
    {
      emails: data.map(entry => entry.email),
    },
    "userBulkEnableCommunity"
  );

  return {
    data: count
  };
}

export async function getUserPII(uid) {
  const { user: { PII } } = await client.query(GET_USER_PII, { uid });

  return PII;
}

export async function updateUserLogin(input) {
  await client.mutate(UPDATE_USER_LOGIN, { input }, "adminUserUpdateLogin");
}

export async function createAutoLoginToken(uid) {
  const {
    adminUserAutoLoginToken: token
  } = await client.query(CREATE_AUTO_LOGIN_TOKEN, { uid });

  return token;
}

export async function deleteUserAuth(uid) {
  await client.mutate(DELETE_USER_AUTH, { uid });
}

export async function randomizeUserPersonalizedPlaylist(uid) {
  await client.mutate(RANDOMIZE_USER_PERSONALIZED_PLAYLIST, { uid });
}

// Note: select input for the roles field cannot take an array of objects (only an array of strings)
function transformUserResponse(user) {
  return {
    ...user,
    roles: user.roles.map(r => r.name)
  };
}
