import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
  uploadAssetToS3,
} from "../utils";
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_ASSET,
  GET_LIST_CATEGORIES,
  GET_ONE_CATEGORY,
  UPDATE_CATEGORY,
} from "./schema";

export async function categoriesBulkCreate(params) {
  const { data } = params;

  let success = 0;
  let errors = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const category of data) {
    try {
      // create the category first, then update it with the data
      // eslint-disable-next-line no-await-in-loop
      const { data: { id }} = await categoryCreate({ data: { name: category.name }})
      // eslint-disable-next-line no-await-in-loop
      await categoryUpdate({
        data: {
          ...category,
          id
        }
      })
      success += 1;
    } catch (e) {
      errors += 1;
    }
  }

  return {
    success,
    errors
  }
}

export async function categoryCreate(params) {
  const { data } = params;

  const res = await client.mutate(
    CREATE_CATEGORY,
    {
      input: {
        ...data,
      }
    },
  );

  const category = res.adminCategoryCreate;

  return {
    data: category
  };
}

export async function getListCategories(params) {
  const data = await client.query(
    GET_LIST_CATEGORIES,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "categories"
  );

  return {
    data: data.edges.map(e => ({
      hasMedia: {
        previewVideo: !!e.node.previewVideo,
        thumbnail: !!(e.node.thumbnailImageId || e.node.thumbnailImageUrl),
      },
      hasMeta: {
        description: !!e.node.description
      },
      ...e.node
    })),
    total: data.totalCount,
  };
}

export async function getOneCategory({ id }) {
  const data = await client.query(
    GET_ONE_CATEGORY,
    {
      id
    },
    "categoryById"
  );

  return { data };
}

export async function getManyCategories(params) {
  const results = { data: []};

  // eslint-disable-next-line no-restricted-syntax
  for (const id of params?.ids) {
    // eslint-disable-next-line no-await-in-loop
    const { data: category } = await getOneCategory({ id });
    results.data.push(category);
  }

  return results;
}

export async function categoryUpdate(params) {
  const { data } = params;
  // if a new image was uploaded, we'll have files passed as parameters
  // upload them and use the new assetId and pass it in the update
  const thumbnailImageId = data.thumbnailImageUrl?.rawFile instanceof File
    ? await uploadNewAsset(data.thumbnailImageUrl.rawFile, "thumbnail", "image/jpeg")
    : null;

  const res = await client.mutate(
    UPDATE_CATEGORY,
    {
      input: {
        categoryId: data.id,
        slug: data.slug,
        name: data.name,
        description: data.description,
        sortIndex: data.sortIndex,
        isOnboarding: data.isOnboarding,
        previewVideoId: data.previewVideoId,
        ...(thumbnailImageId ? { thumbnailImageId } : {}),
      }
    },
  );

  const category = res.adminCategoryUpdate;

  return {
    data: category
  };
}

export async function categoryPublish(params) {
  const { id } = params;

  const res = await client.mutate(
    UPDATE_CATEGORY,
    {
      input: {
        categoryId: id,
        hidden: false
      }
    },
  );

  const data = res.adminCategoryUpdate;

  return { data };
}

export async function categoryUnPublish(params) {
  const { id } = params;

  const res = await client.mutate(
    UPDATE_CATEGORY,
    {
      input: {
        categoryId: id,
        hidden: true
      }
    },
  );

  const data = res.adminCategoryUpdate;

  return { data };
}

async function uploadNewAsset(file, assetType, contentType) {
  const res = await client.mutate(
    CREATE_CATEGORY_ASSET,
    {
      assetType
    },
  );

  const { id, signedUrl } = res.adminCategoryAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}
