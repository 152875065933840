import * as React from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  TopToolbar,
  linkToRecord,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { ImportButton } from "react-admin-import-csv";
import { Link } from "react-router-dom";
import { HelpInfo } from "lib/components/HelpInfo";
import {
  PUBLISHED_ENTITY_COLOR,
  UNPUBLISHED_ENTITY_COLOR,
  usePublishedEntityListStyles
} from "../utils";

const instructorFilters = [
  <TextInput label="Name" source="name" alwaysOn style={{ minWidth: "340px" }} />,
  <TextInput label="Slug" source="slug" style={{ minWidth: "340px" }} />
];

const importConfig = {
  transformRows: csvRows => csvRows.map(instructor => ({
    slug: instructor.slug,
    name: instructor.name,
    bio: instructor.bio,
    location: instructor.location,
    facebook: instructor.facebook,
    youtube: instructor.youtube,
    instagram: instructor.instagram,
    twitter: instructor.twitter,
    tiktok: instructor.tiktok,
    credits: instructor.credits ? instructor.credits.split(",") : []
  }))
};

export function InstructorList(props) {
  const classes = usePublishedEntityListStyles();

  return (
    <List
      {...props}
      perPage={50}
      sort={{ field: "name", order: "ASC" }}
      bulkActionButtons={false}
      actions={<InstructorListActions />}
      filters={instructorFilters}
    >
      <Datagrid expand={<InstructorDetail />} rowStyle={rowStyle}>
        <TextField source="name" />
        <TextField label="Featured Class" source="featuredClass.title" sortable={false}/>
        <BooleanField label="Bio" source="hasMeta.bio" sortable={false}/>
        <TextField source="location" />
        <BooleanField label="Avatar Image" source="hasMedia.avatar" sortable={false}/>
        <BooleanField label="Profile Image" source="hasMedia.profile" sortable={false}/>
        <TextField label="Credits" source="credits" sortable={false}/>
        <BooleanField label="FB" source="hasMeta.facebook" sortable={false}/>
        <BooleanField label="IG" source="hasMeta.instagram" sortable={false}/>
        <BooleanField label="Twitter" source="hasMeta.twitter" sortable={false}/>
        <BooleanField label="YouTube" source="hasMeta.youtube" sortable={false}/>
        <EditButton className={classes.editButton} basePath="/instructors" />
      </Datagrid>
    </List>
  );
}

function InstructorListActions(props) {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <ImportButton {...props} {...importConfig} label="Bulk Import" />
      <HelpInfo helpInfos={[
        [
          "Bulk Import Instructors",
          `Upload a CSV file that has the following columns. The first row contains the column names.

         slug
         name
         bio
         location
         facebook
         youtube
         instagram
         twitter
         tiktok
         credits - value is comma separated string of multiple credits
        `
        ],
      ]} />
    </TopToolbar>
  );
}

function InstructorDetail() {
  const classes = usePublishedEntityListStyles();

  return (
    <div className={classes.detail}>
      <Typography variable="header1">Classes</Typography>
      <ReferenceManyField addLabel={false} reference="classes" target="instructor" source="id" pagination={<Pagination />}>
        <Datagrid empty={<span>No classes linked</span>}>
          <FunctionField label="ID" render={record => {
            const linkToClass = linkToRecord("/classes", record.id);
            return (
              <Link to={linkToClass}>{record.classId}</Link>
            );
          }} />
          <TextField source="title" />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
}

function rowStyle(record) {
  return {
    backgroundColor: record.hidden ? UNPUBLISHED_ENTITY_COLOR : PUBLISHED_ENTITY_COLOR,
  };
}
