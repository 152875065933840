/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { useInput, useRecordContext } from "react-admin";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Typography } from "@material-ui/core";
import { VideoPlayer } from "lib/components/VideoPlayer";
import { convertTimeStringToSeconds, convertToTimeString } from "./helpers";

const useClassSectionsStyles = makeStyles(() => ({
  classSectionsInput: {
    display: "flex",
  },
  classSectionsInputLeft: {
    flex: "1",
  },
  classSectionsInputRight: {
    flex: "1",
    paddingLeft: "8px",
  },
  sectionGroup: {
    marginTop: "12px",
  },
  sectionLine: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
  },
  cuepoints: {
    marginLeft: "30px",
  },
  cuepoint: {
    display: "flex",
    marginTop: "6px",
    alignItems: "center",
  },
  cuepointTime: {
    maxWidth: "100px",
  },
  cuepointDash: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0 8px",
  },
  removeButton: {
    cursor: "pointer",
    marginLeft: "8px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  addButton: {
    cursor: "pointer",
    marginTop: "8px",
    marginBottom: "30px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.5)",
  },
  addButtonText: {
    marginLeft: "4px",
  },
}));

const useClassSectionVideoStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    width: "100%",
  },
}));

// sections json format
// [
//   {
//     “name”: “sss”,
//     “cuepoints”: [
//       {
//           “name”: “sddd”,
//           “timeSec”: 5
//       }
//     ]
//   }
// ]

export const SectionsTab = (props) => {
  const record = useRecordContext(props);
  const videoUrl = record?.video?.hlsPlaybackUrls?.defaultUrl;
  const classes = useClassSectionsStyles();
  const videoClasses = useClassSectionVideoStyles();
  const {
    input: { value, onChange },
    meta: { error },
  } = useInput(props);
  const [timeStrs, setTimeStrs] = useState({});

  useEffect(() => {
    if (!value) {
      return;
    }
    const newTimeStrs = {};
    value.forEach((section, sectionIndex) => {
      section.cuepoints.forEach((cuepoint, cuepointIndex) => {
        let timeStr = "";
        if (cuepoint.timeSec >= 0) {
          timeStr = convertToTimeString(cuepoint.timeSec);
        }

        newTimeStrs[`${sectionIndex}-${cuepointIndex}`] = timeStr;
      });
    });

    setTimeStrs(newTimeStrs);
  }, [value]);

  const handleSectionNameChange = (sectionIndex, newName) => {
    const newValue = [...value];
    newValue[sectionIndex] = { ...newValue[sectionIndex] };
    newValue[sectionIndex].name = newName;
    onChange(newValue);
  };

  const handleCuepointNameChange = (sectionIndex, cuepointIndex, newName) => {
    const newValue = [...value];
    newValue[sectionIndex] = { ...newValue[sectionIndex] };
    newValue[sectionIndex].cuepoints = [...newValue[sectionIndex].cuepoints];
    newValue[sectionIndex].cuepoints[cuepointIndex] = {
      ...newValue[sectionIndex].cuepoints[cuepointIndex],
    };
    newValue[sectionIndex].cuepoints[cuepointIndex].name = newName;
    onChange(newValue);
  };

  const handleTimeChange = (
    sectionIndex,
    cuepointIndex,
    newTimeStr,
    forceUpdate
  ) => {
    const newTimeStrs = { ...timeStrs };
    newTimeStrs[`${sectionIndex}-${cuepointIndex}`] = newTimeStr;
    setTimeStrs(newTimeStrs);

    let hourStr;
    let minuteStr;
    let secondStr;

    // hh:mm:ss or mm:ss
    const splitTimeStr = newTimeStr.split(":");
    // Handles the case of mm:ss becoming :mm:ss. The extra `:` implies it has hours & should wait for it.
    const hasHours = splitTimeStr.length === 3;

    const [partOne, partTwo, partThree] = splitTimeStr;

    if (hasHours) {
      hourStr = partOne;
      minuteStr = partTwo;
      secondStr = partThree;
    } else {
      minuteStr = partOne;
      secondStr = partTwo;
    }

    const timeStrHasTooManyChunks = splitTimeStr.length > 3;

    const hoursReady = !hasHours || !!(+hourStr && hourStr.length === 2);
    const minutesReady = !!(+minuteStr && minuteStr.length === 2);
    const secondsReady = !!(+secondStr && secondStr.length === 2);

    const validTimeStr =
      !timeStrHasTooManyChunks && hoursReady && minutesReady && secondsReady;

    if (validTimeStr || forceUpdate) {
      const newValue = [...value];
      newValue[sectionIndex] = { ...newValue[sectionIndex] };
      newValue[sectionIndex].cuepoints = [...newValue[sectionIndex].cuepoints];
      newValue[sectionIndex].cuepoints[cuepointIndex] = {
        ...newValue[sectionIndex].cuepoints[cuepointIndex],
      };
      newValue[sectionIndex].cuepoints[cuepointIndex].timeSec =
        convertTimeStringToSeconds(newTimeStr);
      onChange(newValue);
    }
  };

  const handleCuepointTimeChange = (
    sectionIndex,
    cuepointIndex,
    newTimeStr
  ) => {
    handleTimeChange(sectionIndex, cuepointIndex, newTimeStr, false);
  };

  const handleCuepointTimeBlur = (sectionIndex, cuepointIndex, newTimeStr) => {
    handleTimeChange(sectionIndex, cuepointIndex, newTimeStr, true);
  };

  const handleAddSection = () => {
    const newValue = [...value];
    newValue.push({ name: "", cuepoints: [] });
    onChange(newValue);
  };

  const handleAddCuepoint = (sectionIndex) => {
    const newValue = [...value];
    newValue[sectionIndex] = { ...newValue[sectionIndex] };
    newValue[sectionIndex].cuepoints = [...newValue[sectionIndex].cuepoints];
    newValue[sectionIndex].cuepoints.push({ timeSec: 0, name: "" });
    onChange(newValue);
  };

  const handleRemoveCuepoint = (sectionIndex, cuepointIndex) => {
    const newValue = [...value];
    newValue[sectionIndex] = { ...newValue[sectionIndex] };
    newValue[sectionIndex].cuepoints = [...newValue[sectionIndex].cuepoints];
    newValue[sectionIndex].cuepoints.splice(cuepointIndex, 1);
    onChange(newValue);
  };

  const handleRemoveSection = (sectionIndex) => {
    const newValue = [...value];
    newValue.splice(sectionIndex, 1);
    onChange(newValue);
  };

  return (
    <div className={classes.classSectionsInput}>
      <div className={classes.classSectionsInputLeft}>
        {error && <div className={classes.error}>{error}</div>}
        {value &&
          value.map((section, sectionIndex) => (
            <div className={classes.sectionGroup} key={sectionIndex}>
              <div className={classes.sectionLine}>
                <TextField
                  value={section.name}
                  onChange={(e) =>
                    handleSectionNameChange(sectionIndex, e.target.value)
                  }
                  placeholder="Section Name"
                  variant="outlined"
                  size="small"
                />
                <div
                  className={classes.removeButton}
                  onClick={() => handleRemoveSection(sectionIndex)}
                >
                  <HighlightOffIcon />
                </div>
              </div>
              <div className={classes.cuepoints}>
                {section.cuepoints.map((cuepoint, cuepointIndex) => (
                  <div className={classes.cuepoint}>
                    <input type="hidden" value={cuepoint.timeSec} />
                    <TextField
                      value={cuepoint.name}
                      onChange={(e) =>
                        handleCuepointNameChange(
                          sectionIndex,
                          cuepointIndex,
                          e.target.value
                        )
                      }
                      placeholder="Cuepoint Name"
                      variant="outlined"
                      size="small"
                    />
                    <div className={classes.cuepointDash}>-</div>
                    <TextField
                      placeholder="hh:mm:ss"
                      value={timeStrs[`${sectionIndex}-${cuepointIndex}`]}
                      onChange={(e) =>
                        handleCuepointTimeChange(
                          sectionIndex,
                          cuepointIndex,
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        handleCuepointTimeBlur(
                          sectionIndex,
                          cuepointIndex,
                          e.target.value
                        )
                      }
                      variant="outlined"
                      size="small"
                      className={classes.cuepointTime}
                    />
                    <div
                      className={classes.removeButton}
                      onClick={() =>
                        handleRemoveCuepoint(sectionIndex, cuepointIndex)
                      }
                    >
                      <HighlightOffIcon />
                    </div>
                  </div>
                ))}
                <Typography
                  className={classes.addButton}
                  onClick={() => handleAddCuepoint(sectionIndex)}
                >
                  <AddCircleOutlineIcon />
                  <span className={classes.addButtonText}>Add Cuepoint</span>
                </Typography>
              </div>
            </div>
          ))}
        <Typography className={classes.addButton} onClick={handleAddSection}>
          <AddCircleOutlineIcon />
          <span className={classes.addButtonText}>Add Section</span>
        </Typography>
      </div>
      <div className={classes.classSectionsInputRight}>
        {!videoUrl && <Typography>No video preview available yet.</Typography>}
        {videoUrl && (
          <VideoPlayer playbackUrl={videoUrl} classes={videoClasses} />
        )}
      </div>
    </div>
  );
};
