import { client } from "../gqlClient";
import GET_ENUM_VALUES from "./schema";

export const UNIQUE_ENUMS_ID = "enums-id";
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

// id + validUntil make up the cache key so new requests are not made.
// Cache is Redux store. If someone refreshes the page, then the cache is cleared.
export async function getEnums() {
  const data = await client.query(GET_ENUM_VALUES, {});
  const validUntil = new Date();

  validUntil.setTime(validUntil.getTime() + CACHE_DURATION);
  const result = {
    data: { id: UNIQUE_ENUMS_ID },
    validUntil
  };

  Object.keys(data).forEach(key => {
    result.data[key] = data[key].enumValues.map(e => e.name);
  });

  return result;
}
