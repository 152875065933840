import { Typography } from "@material-ui/core";
import React from "react";
import {
  Create,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { withRouter } from "react-router";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";

export const FlaggedActivityCreate = withRouter(withEnums(
  ({ location, enums, ...props }) => {
    // assumes proper query params are available in query string :)
    const queryParams = location.search.substring(1).split("&").reduce((acc, curr) => {
      const params = curr.split("=");
      return { ...acc, [params[0]]: params[1] };
    }, []);
    return (
      <Create {...props} mutationMode="pessimistic">
        <SimpleForm validate={validateFlaggedActivityReviewCreate}>
          <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
            Flag Content
            <HelpInfo helpInfos={[
              ["Flag Content", "As an admin user, you can proactively report/flag a video/comment and specify the reason for reporting it. As a second step to this, you can then review your own report how you see fit (ex: blocking the content). This flow allows us to properly audit the moderation flow from an admin user or a user. In a typical scenario, a user would report the content and then an admin user would review the report."],
            ]} />
          </Typography>
          <TextInput
            label="Video or Comment ID"
            source="activityId"
            defaultValue={queryParams.activityId}
            fullWidth
            disabled
          />
          <SelectInput
            defaultValue={queryParams.activityType}
            disabled
            source="activityType"
            choices={enums.activityType.map(type => ({
              id: type,
              name: type
            }))}
          />
          <SelectInput
            source="reportReason"
            choices={enums.activityReportReason.map(status => ({
              id: status,
              name: status
            }))}
          />
          <TextInput multiline source="description" />
        </SimpleForm>
      </Create>
    );
  }));

function validateFlaggedActivityReviewCreate(values) {
  const errors = {};
  const required = ["reportReason"];

  required.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
