import React, { useState } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Edit,
  FormTab,
  FunctionField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  Show,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import { DateTimeInput } from "lib/components/DateTime";
import {
  PublishEditAside,
  PublishEditToolbar,
} from "lib/components/PublishEdit";
import PlaceholderImg from "lib/assets/placeholder-image.png";
import { useEnums } from "lib/hooks";
import { validateClass } from "../utils";
import { SectionsTab } from "./components/SectionsTab";
import { MutedCuepointsTab } from "./components/MutedCuepointsTab";
import { TagsTab } from "./components/TagsTab";
import { Tracks } from "./components/Tracks";
import { Videos } from "./components/Videos";

export const ClassEdit = (props) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { enums } = useEnums();

  return (
    <>
      <Edit
        {...props}
        mutationMode="pessimistic"
        aside={
          <PublishEditAside
            config={{
              resource: "classes",
              resourceLabel: "Class",
            }}
            isFormDirty={isFormDirty}
          />
        }
      >
        <TabbedForm
          toolbar={<PublishEditToolbar setIsFormDirty={setIsFormDirty} />}
          validate={validateClass}
          warnWhenUnsavedChanges
        >
          <FormTab label="details">
            <TextField source="uuid" label={"Database ID"} />
            <ReadOnlyField addLabel label="Number" source="classId" />
            <ReadOnlyField source="slug" addLabel />
            <TextInput source="title" fullWidth />
            {enums?.accessType && (
              <SelectInput
                source="accessType"
                choices={enums.accessType.map((accessType) => ({
                  id: accessType,
                  name: accessType,
                }))}
              />
            )}
            {/*<BooleanInput label="Is Family Friendly" source="isFamilyFriendly" />*/}
            <ReferenceInput
              label="Instructor"
              source="instructor.id"
              reference="instructors"
              // @TODO Remove when we have proper validation for instructor being published when publishing class.
              filter={{ hidden: false }}
              sort={{ field: "name", order: "ASC" }}
              filterToQuery={(searchText) =>
                searchText?.length > 0 ? { name: searchText } : {}
              }
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              label="Level"
              source="level"
              reference="classLevels"
            >
              <SelectInput allowNull optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Type" source="type" reference="classTypes">
              <SelectInput allowNull optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput
              label="Categories"
              source="categoryIds"
              reference="categories"
              sort={{ field: "name", order: "ASC" }}
              filterToQuery={categoryFilter}
              allowEmpty
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceInput
              label="Style"
              source="style"
              reference="classStyles"
            >
              <SelectInput allowNull optionText="name" />
            </ReferenceInput>
            <DateTimeInput source="publishDate" />
            <ImageInput
              source="thumbnailImageUrl"
              multiple={false}
              label="Image"
              accept="image/jpeg"
            >
              <FunctionField
                render={(record) => {
                  const url = record?.rawFile
                    ? URL.createObjectURL(record.rawFile)
                    : record;
                  return (
                    <img
                      alt="thumbnail"
                      src={url}
                      style={{ width: "400px" }}
                      onError={(e) => {
                        const { target } = e;
                        target.src = PlaceholderImg;
                      }}
                    />
                  );
                }}
              />
            </ImageInput>
          </FormTab>
          <FormTab label="tags">
            <TagsTab source="tags" />
          </FormTab>
          <FormTab label="sections">
            <SectionsTab source="sections" videoUrl="video.hlsPlaybackUrls" />
          </FormTab>
          <FormTab label="muted cuepoints">
            <MutedCuepointsTab
              source="mutedCuepoints"
              videoUrl="video.hlsPlaybackUrls"
            />
          </FormTab>
        </TabbedForm>
      </Edit>
      <Show {...props} actions={null}>
        <Tracks />
      </Show>
      <Show {...props} actions={null}>
        <Videos />
      </Show>
    </>
  );
};

function categoryFilter(searchText) {
  const filter = { hidden: false };

  if (searchText?.length < 0) {
    filter.name = searchText;
  }

  return filter;
}
