import { Card, withStyles } from "@material-ui/core";

export const FilterSidebar = withStyles(theme => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      marginRight: "1em",
      width: "16em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(Card);
