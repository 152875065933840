import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  ReferenceInput,
  SaveButton,
  SelectInput, NumberInput, TextField,
} from "react-admin";
import { getRequiredFieldErrors } from "../utils";

export function TagEdit(props) {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm toolbar={<TagEditToolbar />}
                  validate={validateEditTag}
                  warnWhenUnsavedChanges
                  syncWithLocation={false}>
        <TextField source="id" label="Database ID"/>
        <TextInput source="name" />
        <TextInput source="slug" />
        <ReferenceInput label="Type" source="type" reference="tagTypes">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <NumberInput label="[OPTIONAL] Display Index" source="displayIndex" />
      </SimpleForm>
    </Edit>
  );
}

function TagEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

const REQUIRED_FIELDS = ["name", "slug", "type"];

function validateEditTag(values) {
  return getRequiredFieldErrors(values, REQUIRED_FIELDS);
}
