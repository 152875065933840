import { gql } from "@apollo/client";

const VIDEO_UPLOAD_FRAGMENT = gql`
  fragment VideoUploadFragment on VideoUpload {
    id
    encodingStatus
    approvalStatus
    playbackUrl
    error
    updatedAt
    isUserSubmitted
    isUserDeleted
    thumbnailUrl
    stepFunctionArn
    description
    uploadedBy {
      id
      username
    }
    flaggedCount
  }
`;

export const GET_LIST_VIDEO_UPLOADS = gql`
  ${VIDEO_UPLOAD_FRAGMENT}
  query AllVideos($sort: OrderByInput, $pagination: ConnectionArgs, $filter: VideoUploadsFilterInput) {
    videoUploads(sort: $sort, pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          ...VideoUploadFragment
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ONE_VIDEO_UPLOAD = gql`
  ${VIDEO_UPLOAD_FRAGMENT}
  query VideoUploadById($input: VideoUploadByIdInput!) {
    videoUploadById(input: $input) {
      ...VideoUploadFragment
    }
  }
`;

export const UPDATE_VIDEO_UPLOAD = gql`
  ${VIDEO_UPLOAD_FRAGMENT}
  mutation VideoUploadAdminUpdate($input: VideoUploadAdminUpdateInput!) {
    videoUploadAdminUpdate(input: $input) {
      ...VideoUploadFragment
    }
  }
`
