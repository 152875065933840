import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { useEncodingStatusFieldStyles } from "./styles";
import { userFriendlyVideoErrors } from "./userFriendlyVideoErrors";

export function EncodingStatusField(props) {
  const { source, classSlug, classUUID } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useEncodingStatusFieldStyles();
  const record = useRecordContext(props);

  const isPreview = (record.classVideoType === "preview");

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span
        onClick={handleClick}
        style={{ cursor: "pointer", textDecoration: "underline" }}
        aria-hidden="true"
      >
        {record[source]}
      </span>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popover
        }}
      >
        <Typography sx={{ p: 2 }} component="div">
          <table>
            <tbody>
            <tr>
              <td>Download: </td>
              <td>
                <a
                  href={`https://s3.console.aws.amazon.com/s3/object/${process.env.REACT_APP_S3_BUCKET_VIDEO_INPUTS}?region=us-east-1&prefix=class_${record.classVideoType}/${classUUID}/${record.id}/original`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link for newer classes
                </a>
              </td>
            </tr>
            <tr>
              <td>Download: </td>
              <td>
                <a
                  href={`https://s3.console.aws.amazon.com/s3/object/steezy?region=us-east-1&prefix=class-${isPreview ? "previews" : "videos"}/${classSlug}${isPreview ? "-preview" : ""}.mp4`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link for older classes
                </a>
              </td>
            </tr>
            <tr><td>Video ID: </td><td>{record.id}</td></tr>
              <tr>
                <td>AWS Jobs: </td>
                <td>
                  {
                    record.stepFunctionArn && (
                      <>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://console.aws.amazon.com/states/home?region=us-east-1#/executions/details/${record.stepFunctionArn}`}>
                          Step Function
                        </a>
                        &nbsp;
                      </>
                    )
                  }
                  {
                    record.mediaConvertJobIds && (
                      record.mediaConvertJobIds.map((jobId, index) => (
                        <>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://console.aws.amazon.com/mediaconvert/home?region=us-east-1#/jobs/summary/${jobId}`}>
                            Job {index + 1}
                          </a>
                          &nbsp;
                        </>
                      ))
                    )
                  }
                </td>
              </tr>
              <tr><td>Error: </td><td>{userFriendlyVideoErrors[record.error] || record.error || "None"}</td></tr>
            </tbody>
          </table>
        </Typography>
      </Popover>
    </div>
  );
}

EncodingStatusField.defaultProps = {
  addLabel: true
};

EncodingStatusField.propTypes = {
  source: PropTypes.string.isRequired,
  classSlug: PropTypes.string,
  classUUID: PropTypes.string,
  addLabel: PropTypes.bool, // provided by SimpleShowLayout, TabbedShowLayout, SimpleForm, TabbedForm which looks at prop value for child component (DateField)
};
