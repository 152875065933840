import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import merge from "lodash.merge";

// messages are detailed here:
// https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/src/index.ts

const overridedMessages = merge(englishMessages, {
  ra: {
    page: {
      empty: "%{name}s are not created yet.",
      invite: "Start by creating one below."
    }
  }
});

export const i18nProvider = polyglotI18nProvider(() => overridedMessages, "en", { allowMissing: true });
