import { makeStyles } from "@material-ui/core/styles";

export const usePlayVideoFieldStyles = makeStyles({
  playVideoField: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px"
  }
});

const sharedStyles = {
  position: "relative",
  left: "50%",
  transform: "translateX(-50%)",
  height: "95vh",
  backgroundColor: "black",
  marginTop: "2.5vh",
};

export const useVideoPlayerStyles = makeStyles({
  video: {
    ...sharedStyles,
    ...{
      maxWidth: "95vw",
      backgroundColor: "black",
    }
  },
  progress: {
    ...sharedStyles,
    ...{
      // predict 9x16 aspect ratio, but video el will size to correct aspect ratio once it loads
      width: "calc(95vh * (9 / 16))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },
});
