import { UNIQUE_ENUMS_ID } from "data/enums";
import { useQuery } from "react-admin";

export function useEnums() {
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "enums",
    payload: { id: UNIQUE_ENUMS_ID },
  });

  if (loading || !!error || !data) {
    return { enums: null };
  }

  return { enums: data };
}
