export function convertToTimeString(timeSeconds = 0) {
  const hours = Math.floor(timeSeconds / 3600);
  const minutes = Math.floor((timeSeconds % 3600) / 60);
  const seconds = Math.floor(timeSeconds % 60);

  let minutesString = `${minutes}`;
  let secondsString = `${seconds}`;

  if (minutes < 10 && hours > 0) {
    minutesString = `0${minutes}`;
  }
  if (seconds < 10) {
    secondsString = `0${seconds}`;
  }

  if (hours === 0) {
    return `${minutesString}:${secondsString}`;
  }
  return `${hours}:${minutesString}:${secondsString}`;
}

export function convertTimeStringToSeconds(timeString) {
  const timeArray = timeString.split(":");
  let timeInSeconds = 0;

  if (timeArray.length === 2) {
    timeInSeconds =
      timeInSeconds + Number(timeArray[0]) * 60 + Number(timeArray[1]);
  } else if (timeArray.length === 3) {
    timeInSeconds =
      timeInSeconds +
      Number(timeArray[0]) * 3600 +
      Number(timeArray[1]) * 60 +
      Number(timeArray[2]);
  }

  return timeInSeconds;
}
