import React from "react";
import PropTypes from "prop-types";

export function FlaggedActivityDetail({ record }) {
  return (
    <div>
      <u>Reviewed By</u>: {record.reviewedBy?.email}<br/>
      <u>Review Notes</u>: {record.reviewNotes}
    </div>
  );
}

FlaggedActivityDetail.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    reviewedBy: PropTypes.shape({
      email: PropTypes.string,
    }),
    reviewNotes: PropTypes.string,
  }),
};
