import React, { useState } from "react";
import {
  BooleanInput,
  Edit,
  FunctionField,
  ImageInput,
  NumberInput,
  Show,
  SimpleForm, TextField,
  TextInput, useRecordContext,
} from "react-admin";
import { Typography } from "@material-ui/core";
import PlaceholderImg from "lib/assets/placeholder-image.png";
import { PublishEditAside, PublishEditToolbar } from "lib/components/PublishEdit";
import { getRequiredFieldErrors } from "../../utils";
import { Classes } from "./components/Classes";
import { Videos } from "./components/Videos";

export function CategoryEdit(props) {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <>
      <Edit
        actions={false}
        {...props}
        mutationMode="pessimistic"
        aside={
          <PublishEditAside
            config={{
              resource: "categories",
              resourceLabel: "Category",
            }}
            isFormDirty={isFormDirty}
          />
        }
      >
        <EditForm setIsFormDirty={setIsFormDirty} />
      </Edit>

      <Show {...props} actions={null}>
        <Classes />
      </Show>

      <Show {...props} actions={null}>
        <Videos />
      </Show>
    </>
  );
}

function EditForm({setIsFormDirty, ...props}) {
  const record = useRecordContext(props);

  return (
    <SimpleForm {...props} toolbar={<PublishEditToolbar setIsFormDirty={setIsFormDirty} />} validate={validateEditCategory} warnWhenUnsavedChanges>
      <Typography variant="h6">Edit Category</Typography>
      <TextField source="id" label={"Database ID"}/>
      {
        record.hidden ? (
          <TextInput source="slug" />
        ) : (
          <TextField source="slug" />
        )
      }
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth required={false} multiline />
      <NumberInput source="sortIndex" step={1} min={0}/>
      <BooleanInput label="Is Onboarding"  source="isOnboarding"  />
      <ImageInput source="thumbnailImageUrl" multiple={false} label="Thumbnail" accept="image/jpeg">
        <FunctionField render={(record => {
          const url = record?.rawFile ? URL.createObjectURL(record.rawFile) : `${record}?w=300`;
          return (
              <img
                  alt="thumbnail"
                  src={url}
                  width={300}
                  onError={e => {
                    const { target } = e;
                    target.src = PlaceholderImg;
                  }}
              />
          );
        })} />
      </ImageInput>
    </SimpleForm>
  )
}



function validateEditCategory(values) {
  return getRequiredFieldErrors(values, ["slug", "name"]);
}
