import { Typography } from "@material-ui/core";
import React from "react";
import { Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { ReadOnlyField } from "lib/components/ReadOnlyField";

export const FlaggedActivityEdit = withEnums(({ enums, ...props }) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm toolbar={<FlaggedActivityReviewEditToolbar />} validate={validateFlaggedActivityReviewEdit}>

      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Review Flagged Content
        <HelpInfo helpInfos={[
          ["Review Flagged Content", "Specify the decision for the video/comment that was reported. If you decide to block, the video/comment will not be viewable by anyone."],
        ]} />
      </Typography>
      <ReadOnlyField addLabel label="Video or Comment ID" source="activityId" />
      <ReadOnlyField addLabel label="Type" source="activityType" />
      <SelectInput
        source="reviewDecision"
        choices={enums.activityReviewDecision.map(status => ({
          id: status,
          name: status
        }))}
      />
      <TextInput multiline source="reviewNotes" />
    </SimpleForm>
  </Edit>
));

function FlaggedActivityReviewEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

function validateFlaggedActivityReviewEdit(values) {
  const errors = {};
  const required = ["reviewDecision"];

  required.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
