import React from "react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  Link,
  Pagination,
  ReferenceManyField,
  TextField,
  linkToRecord,
  useRecordContext,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { AddClass } from "./AddClass";
import { RemoveClassField } from "./RemoveClassField";

export function Classes(props) {
  const categoryRecord = useRecordContext(props);

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center" p={1}>
        <Typography variant="h6">Classes</Typography>
        <AddClass {...props} categoryId={categoryRecord.id} />
      </Box>
      <ReferenceManyField
        addLabel={false}
        reference="classes"
        target="categoryId"
        source="id"
        pagination={<Pagination />}
      >
        <Datagrid empty={<NoClassesLinked />}>
          <FunctionField
            label="Class Number"
            render={(record) => {
              const linkToClass = linkToRecord("/classes", record.id);
              return <Link to={linkToClass}>{record.classId}</Link>;
            }}
          />
          <TextField source="accessType" sortable />
          <TextField source="level" sortable />
          <TextField source="title" sortable />
          <TextField label="Instructor" source="instructor.name" />
          <RemoveClassField
            categoryId={categoryRecord.id}
            parentProps={props}
          />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
}

function NoClassesLinked() {
  return (
    <Box p={1}>
      <Typography>No classes linked</Typography>
    </Box>
  );
}
