import React, { useState } from "react";
import {
  AutocompleteArrayInput,
  Edit,
  FunctionField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useQuery,
} from "react-admin";
import { makeStyles, Typography } from "@material-ui/core";
import { withEnums } from "data/utils";
import PlaceholderImg from "lib/assets/placeholder-image.png";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import { PublishEditAside, PublishEditToolbar } from "lib/components/PublishEdit";
import { getRequiredFieldErrors } from "../utils";

const useInstructorEditStyles = makeStyles(() => ({
  avatar: {
    height: "160px",
    borderRadius: "50%",
  }
}));

export const withInstructorCredits = Component => props => {
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "instructorCredits",
    payload: {}
  });

  if (loading || !!error || !data) {
    return null;
  }

  return <Component instructorCredits={data} {...props} />;
};

export const InstructorEdit = withInstructorCredits(
  withEnums(({ instructorCredits, enums, ...props }) => {
    const classes = useInstructorEditStyles();
    const [isFormDirty, setIsFormDirty] = useState(false);
    return (
      <Edit
        actions={false}
        {...props}
        mutationMode="pessimistic"
        aside={
          <PublishEditAside
            config={{
              resource: "instructors",
              resourceLabel: "Instructor",
            }}
            isFormDirty={isFormDirty}
          />
        }
        warnWhenUnsavedChanges
      >
        <SimpleForm toolbar={<PublishEditToolbar setIsFormDirty={setIsFormDirty} />} validate={validateEditInstructor} warnWhenUnsavedChanges>
          <Typography variant="h6">Edit Instructor</Typography>
          <ReadOnlyField addLabel label="Database ID" source="id" />
          <ReadOnlyField addLabel label="Slug" source="slug" />
          <TextInput label="Name" source="name" fullWidth />
          <TextInput label="Bio" source="bio" fullWidth required={false} multiline />
          <ReferenceInput label="Featured Class"
                          source="featuredClass.classId"
                          reference="classes"
                          filter={{ hidden: false, instructor: props?.id }}
                          sort={{ field: "createdAt", order: "ASC" }}
          >
            <SelectInput allowNull optionText="title" />
          </ReferenceInput>
          <AutocompleteArrayInput
            onCreate={name => {
              let creditName = name
              if (!creditName) {
                // eslint-disable-next-line no-alert
                creditName = prompt("Enter a new credit");
              }
              const newCredit = { id: creditName, name: creditName };
              instructorCredits.push(newCredit);
              return newCredit;
            }}
            source="credits"
            choices={instructorCredits}
          />
          <ImageInput source="avatarImageUrl" multiple={false} label="Avatar" accept="image/png">
            <FunctionField render={(record => {
              const url = record?.rawFile ? URL.createObjectURL(record.rawFile) : record;
              return (
                <img
                  alt="avatar"
                  src={url}
                  className={classes.avatar}
                  onError={e => {
                    const { target } = e;
                    target.src = PlaceholderImg;
                  }}
                />
              );
            })} />
          </ImageInput>
          <ImageInput source="profileImageUrl" multiple={false} label="Profile" accept="image/jpeg">
            <FunctionField render={(record => {
              const url = record?.rawFile ? URL.createObjectURL(record.rawFile) : `${record}?w=150`;
              return (
                <img
                  alt="profile"
                  src={url}
                  width={150}
                  className={classes.profile}
                  onError={e => {
                    const { target } = e;
                    target.src = PlaceholderImg;
                  }}
                />
              );
            })} />
          </ImageInput>
          <TextInput label="Location" source="location" fullWidth required={false} />
          <TextInput label="Facebook" source="facebook" fullWidth required={false} />
          <TextInput label="Instagram" source="instagram" fullWidth required={false} />
          <TextInput label="Twitter" source="twitter" fullWidth required={false} />
          <TextInput label="YouTube" source="youtube" fullWidth required={false} />
          <TextInput label="TikTok" source="tiktok" fullWidth required={false} />
        </SimpleForm>
      </Edit>
    )
  })
);

function validateEditInstructor(values) {
  return getRequiredFieldErrors(values, ["slug", "name"]);
}
