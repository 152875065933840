import { FirebaseAuthProvider } from "react-admin-firebase";
import firebase from "firebase/app";
import "firebase/auth";
import { getMeRoles, ADMIN_USER_ROLE } from "data/users";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

const app = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
const firebaseAuthProvider = FirebaseAuthProvider(firebaseConfig, { app });

export const authProvider = {
  login: async params => firebaseAuthProvider.login(params),
  checkError: error => firebaseAuthProvider.checkError(error),
  checkAuth: async () => {
    const resp = await firebaseAuthProvider.checkAuth();
    const meRoles = await getMeRoles();
    if (!meRoles || meRoles.filter(role => role.name === ADMIN_USER_ROLE).length === 0) {
      throw new Error("Not authorized for admin access.");
    }
    return resp;
  },
  logout: () => firebaseAuthProvider.logout(),
  getIdentity: () => firebaseAuthProvider.getIdentity(),
  getPermissions: async params => {
    const resp = await firebaseAuthProvider.getPermissions(params);
    const meRoles = await getMeRoles();
    return { ...resp, roles: meRoles };
  },
  getAuthUser: () => firebaseAuthProvider.getAuthUser(),
};
