import Axios from "axios";

const FILE_CHUNK_SIZE = 10_000_000;
const CONCURRENT_PARTS_TO_UPLOAD_AT_A_TIME = 10;

export async function uploadFile(
  videoFile,
  videoUploadCreate,
  videoUploadComplete,
  setVideoFileState
) {
  try {
    const axios = Axios.create();
    delete axios.defaults.headers.put["Content-Type"];

    const numParts = Math.ceil(videoFile.size / FILE_CHUNK_SIZE);
    const partIndexToProgress = [...Array(numParts)].map(() => 0);
    const updateVideoProgress = (partIndex, partPercentage) => {
      partIndexToProgress[partIndex] = partPercentage;
      const totalPartProgress = partIndexToProgress.reduce(
        (acc, curr) => acc + curr,
        0
      );
      setVideoFileState(oldVideoFileState => ({
        ...oldVideoFileState,
        percentProgress: Math.round(
          (totalPartProgress / (numParts * 100)) * 100
        ),
      }));
    };

    // create video entity and initiate multi-part upload
    const videoUploadCreateResp = await videoUploadCreate(numParts);
    if (!videoUploadCreateResp) {
      return false;
    }
    const { s3PartsUrls, videoId, uploadId } = videoUploadCreateResp;
    setVideoFileState(oldVideoFileState => ({ ...oldVideoFileState, videoId }));

    // gather all part promises in an array
    const partPromiseFns = [];
    for (let i = 0; i < s3PartsUrls.length; i++) {
      const start = FILE_CHUNK_SIZE * i;
      const end = FILE_CHUNK_SIZE * (i + 1);
      const blob =
        i < s3PartsUrls.length
          ? videoFile.slice(start, end)
          : videoFile.slice(start);
        partPromiseFns.push(
          () =>
            retriedPartUpload(
              axios,
              s3PartsUrls[i].url,
              blob,
              i,
              updateVideoProgress,
              3
            )
      );
    }

    // upload parts and limit to a certain amount of parts at a time
    let index = 0;
    const parts = [];
    const doNextPromise = async () => {
      const i = index++;
      if (i >= partPromiseFns.length) {
        return null;
      }
      const partPromiseFn = partPromiseFns[i];
      parts[i] = await partPromiseFn();
      return doNextPromise();
    };
    await Promise.all(Array.from({ length: CONCURRENT_PARTS_TO_UPLOAD_AT_A_TIME }, doNextPromise));

    // complete upload
    setVideoFileState(oldVideoFileState => ({
      ...oldVideoFileState,
      percentProgress: 100,
    }));
    return await videoUploadComplete(videoId, parts, uploadId);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("Error uploading file ", e);
    return false;
  }
}

async function retriedPartUpload(
  axios,
  url,
  blob,
  partIndex,
  updateVideoProgress,
  numTries
) {
  if (numTries === 0) {
    throw new Error("Failed to upload part. Exhausted all retries");
  }

  try {
    const resp = await axios.put(url, blob, {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        updateVideoProgress(partIndex, percentCompleted);
      },
    });
    updateVideoProgress(partIndex, 100);
    return {
      partNumber: partIndex + 1,
      eTag: resp.headers.etag,
    };
  } catch (e) {
    await timeoutPromise(10000);
    return retriedPartUpload(axios, url, blob, numTries - 1);
  }
}

function timeoutPromise(msTimeout) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), msTimeout);
  });
}
