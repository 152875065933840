/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  TextInput,
  useInput,
  useRecordContext,
} from "react-admin";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { VideoPlayer } from "lib/components/VideoPlayer";
import { convertTimeStringToSeconds } from "./helpers";

const useClassSectionsStyles = makeStyles(() => ({
  classSectionsInput: {
    display: "flex",
  },
  classSectionsInputLeft: {
    flex: "1",
  },
  classSectionsInputRight: {
    flex: "1",
    paddingLeft: "8px",
  },
  cuepoints: {
    marginLeft: "30px",
  },
  cuepoint: {
    display: "flex",
    marginTop: "6px",
    alignItems: "center",
  },
  cuepointTime: {
    maxWidth: "100px",
  },
  cuepointDash: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0 8px",
  },
  removeButton: {
    cursor: "pointer",
    marginLeft: "8px",
    color: "rgba(0, 0, 0, 0.5)",
  },
  addButton: {
    cursor: "pointer",
    marginTop: "8px",
    marginBottom: "30px",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.5)",
  },
  addButtonText: {
    marginLeft: "4px",
  },
  customButton: {
    marginTop: "9px",
    marginLeft: "8px",
  },
}));

const useClassSectionVideoStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    width: "100%",
  },
}));

export const MutedCuepointsTab = (props) => {
  const record = useRecordContext(props);
  const videoUrl = record?.video?.hlsPlaybackUrls?.defaultUrl;
  const classes = useClassSectionsStyles();
  const videoClasses = useClassSectionVideoStyles();
  const {
    input: { value, onChange },
    meta: { error },
  } = useInput(props);
  const [mutedCuepointsVal, setMutedCuepointsVal] = useState([]);
  const [externalPreviewUrl, setExteralPreviewUrl] = useState(
    record.externalPreviewUrl
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    setMutedCuepointsVal([...value]);
  }, [value]);

  const updateEndTime = (cuepointIndex, newEndTime) => {
    const newValue = [...value];
    const newTimeTimeSec = convertTimeStringToSeconds(newEndTime);

    newValue[cuepointIndex].endsAtTime = newEndTime;
    newValue[cuepointIndex].endsAtTimeSec = newTimeTimeSec;
    onChange(newValue);
  };

  const updateStartTime = (cuepointIndex, newStartTime) => {
    const newValue = [...value];
    const newTimeTimeSec = convertTimeStringToSeconds(newStartTime);

    newValue[cuepointIndex].startsAtTime = newStartTime;
    newValue[cuepointIndex].startsAtTimeSec = newTimeTimeSec;
    onChange(newValue);
  };

  const handleAddCuepoint = () => {
    const newValue = [
      ...value,
      {
        startsAtTime: "00:00",
        endsAtTime: "00:00",
        startsAtTimeSec: 0,
        endsAtTimeSec: 0,
      },
    ];

    onChange(newValue);
  };

  const handleExternalUrlChange = (cuepointIndex, newExternalUrl) => {
    const newValue = [...value];

    newValue[cuepointIndex].externalUrl = newExternalUrl;
    onChange(newValue);
  };

  const handleRemoveCuepoint = (cuepointIndex) => {
    const newValue = [...value];
    newValue.splice(cuepointIndex, 1);
    onChange(newValue);
  };

  const setAllExternalUrls = () => {
    const newMutedCuepoints = mutedCuepointsVal.map((mutedCuepoint) => ({
      ...mutedCuepoint,
      externalUrl: externalPreviewUrl,
    }));
    onChange(newMutedCuepoints);
  };

  const clearAllExternalUrls = () => {
    const newMutedCuepoints = mutedCuepointsVal.map((mutedCuepoint) => ({
      ...mutedCuepoint,
      externalUrl: null,
    }));
    onChange(newMutedCuepoints);
  };

  return (
    <div className={classes.classSectionsInput}>
      <div className={classes.classSectionsInputLeft}>
        {error && <div className={classes.error}>{error}</div>}
        <BooleanInput
          label="Should Class Use Muted Cuepoints"
          source="shouldBeMuted"
        />
        <TextInput
          placeholder="External Preview URL"
          source="externalPreviewUrl"
          variant="outlined"
          size="small"
          onChange={(e) => setExteralPreviewUrl(e.target.value)}
        />
        <Button
          className={classes.customButton}
          color="primary"
          variant="contained"
          onClick={setAllExternalUrls}
        >
          Apply URL to all cuepoints
        </Button>
        <Button
          className={classes.customButton}
          color="secondary"
          variant="contained"
          onClick={clearAllExternalUrls}
        >
          Clear All URLs
        </Button>
        <div className={classes.cuepoints}>
          {value &&
            value.map((mutedCuepoint, cuepointIndex) => (
              <div className={classes.cuepoint}>
                <input type="hidden" value={mutedCuepoint.startsAtTime} />
                <TextField
                  placeholder="hh:mm:ss"
                  value={mutedCuepointsVal[cuepointIndex]?.startsAtTime}
                  onChange={(e) =>
                    updateStartTime(cuepointIndex, e.target.value)
                  }
                  onBlur={(e) => updateStartTime(cuepointIndex, e.target.value)}
                  variant="outlined"
                  size="small"
                  className={classes.cuepointTime}
                />
                <div className={classes.cuepointDash}>-</div>
                <TextField
                  placeholder="hh:mm:ss"
                  value={mutedCuepointsVal[cuepointIndex]?.endsAtTime}
                  onChange={(e) => updateEndTime(cuepointIndex, e.target.value)}
                  onBlur={(e) => updateEndTime(cuepointIndex, e.target.value)}
                  variant="outlined"
                  size="small"
                  className={classes.cuepointTime}
                />
                <div className={classes.cuepointDash}> </div>
                <TextField
                  value={mutedCuepointsVal[cuepointIndex]?.externalUrl || ""}
                  onChange={(e) =>
                    handleExternalUrlChange(cuepointIndex, e.target.value)
                  }
                  placeholder="External URL"
                  variant="outlined"
                  size="small"
                />
                <div
                  className={classes.removeButton}
                  onClick={() => handleRemoveCuepoint(cuepointIndex)}
                >
                  <HighlightOffIcon />
                </div>
              </div>
            ))}
          <Typography
            className={classes.addButton}
            onClick={() => handleAddCuepoint()}
          >
            <AddCircleOutlineIcon />
            <span className={classes.addButtonText}>Add Muted Cuepoint</span>
          </Typography>
        </div>
      </div>
      <div className={classes.classSectionsInputRight}>
        {!videoUrl && <Typography>No video preview available yet.</Typography>}
        {videoUrl && (
          <VideoPlayer playbackUrl={videoUrl} classes={videoClasses} />
        )}
      </div>
    </div>
  );
};
