import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export function initSentry() {
  if (
    !process.env.REACT_APP_SENTRY_DSN || // don't initialize sentry if we don't have config variables
    process.env.NODE_ENV === "development" // don't send errors if we are actively developing
  ) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    sampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
    tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
    environment: process.env.NODE_ENV,
    release: `shield@${process.env.REACT_APP_VERSION}`,
  });
}

export function reportErrorToSentry(err) {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return;
  }

  Sentry.captureException(err);
}
