import React from "react";
import { AppBar, Layout, Sidebar } from "react-admin";
import { Typography } from "@material-ui/core";
import { STEEZYLogo } from "../../../lib/components/Icon";
import { useNavbarStyles, useSidebarStyles } from "./styles";

export function CustomLayout(props) {
  return (
    <Layout {...props} appBar={CustomNavbar} sidebar={CustomSidebar} />
  );
}

function CustomSidebar(props) {
  const classes = useSidebarStyles();

  return (
    <Sidebar classes={classes} {...props} />
  );
}

function CustomNavbar(props) {
  const classes = useNavbarStyles();

  return (
    <AppBar {...props} className={classes.appBar}>
      <Typography
        variant="h6"
        className={classes.typography}
      >
        <STEEZYLogo className={classes.logo} />
        <div className={classes.adminTitle}>Admin</div>
      </Typography>
    </AppBar>
  );
}
