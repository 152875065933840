import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort
} from "../utils";
import {
  CATEGORY_VIDEO_FILE_UPLOAD_COMPLETE,
  CATEGORY_VIDEO_FILE_UPLOAD_CREATE,
  GET_LIST_CATEGORY_VIDEOS,
} from "./schema";

export async function getCategoryVideos(params) {
  const data = await client.query(
    GET_LIST_CATEGORY_VIDEOS,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "categoryVideos"
  );

  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function getManyReferenceCategoryVideos(params) {
  const newParams = { ...params, filter: { ...params.filter, categoryId: params.id }};
  return getCategoryVideos(newParams);
}

export async function categoryVideoFileUploadCreate(categoryId, numParts) {
  const res = await client.mutate(
    CATEGORY_VIDEO_FILE_UPLOAD_CREATE,
    {
      input: {
        categoryId,
        parts: numParts,
      }
    },
  );

  const data = res.adminCategoryVideoFileUploadCreate;

  return {
    s3PartsUrls: data.s3PartsUrls,
    videoId: data.id,
    uploadId: data.uploadId,
  };
}

export async function categoryVideoFileUploadComplete(videoId, parts, uploadId) {
  const res = await client.mutate(
    CATEGORY_VIDEO_FILE_UPLOAD_COMPLETE,
    {
      input: {
        videoId,
        parts,
        uploadId,
      }
    },
  );

  const data = res.adminCategoryVideoFileUploadComplete;

  const success = !!data.id;
  return success;
}
