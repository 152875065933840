import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { useDataProvider, useMutation, useNotify } from "react-admin";
import { TextField } from "@material-ui/core"
import { sleep } from "lib/utils";
import useAddNewTrackStyles from "./styles";

export function AddTrack ({ uuid }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isrc, setIsrc] = useState("");
  const [startsAtStr, setStartsAtStr] = useState("");
  const [invalidIsrc, setInvalidIsrc] = useState(false);
  const [invalidStartsAt, setInvalidStartsAt] = useState(false);
  const dataProvider = useDataProvider();
  const classes = useAddNewTrackStyles();
  const notify = useNotify();

  const [addTrack, {addingTrack}] = useMutation(
    {
      type: "create",
      resource: "classTracks",
      payload: {
        data: {
          isrc,
          uuid,
          startsAt: (parseInt(startsAtStr.split(":")[0]) * 60) + parseInt(startsAtStr.split(":")[1])
        }
      }
    }, {
      onSuccess: async () => {
        notify("Successfully added the track to the class. RELOADING...");
        await sleep(1000);
        window.location.reload();
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  const handleToggleModal = () => {
    if (!isModalOpen) {
      setIsrc("");
      setStartsAtStr("");
      setInvalidIsrc(false);
      setInvalidStartsAt(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  const onAddTrack = async () => {
    setInvalidStartsAt(false);
    setInvalidIsrc(false);

    if (startsAtStr.length !== 5) {
      setInvalidStartsAt(true);
      notify("Starts At must be formatted in mm:ss.");
      return;
    }
    const startsAtStrSplit = startsAtStr.split(":");
    if (startsAtStrSplit.length !== 2) {
      setInvalidStartsAt(true);
      notify("Starts At must be formatted in mm:ss.");
      return;
    }
    if (!isrc || isrc === "") {
      setInvalidIsrc(true);
      notify("Please provide valid ISRC.");
      return;
    }
    const listResult = await dataProvider.getList("tracks", {
      filter: {
        isrc
      }
    });
    if (listResult.data.length === 0) {
      setInvalidIsrc(true);
      notify("Please provide valid ISRC.");
      return;
    }

    await addTrack();
    setIsModalOpen(false);
  }

  return (
    <div className={classes.addNewTrack}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleToggleModal}
        size="small"
      >
        Add Track
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleToggleModal}
      >
        <div className={classes.modalBody}>
          <Typography className={classes.modalTitle} variant="h6">Add Track to Class</Typography>
          <CloseIcon className={classes.close} title="close" onClick={handleToggleModal} />
          <div className={classes.modalForm}>
            <TextField
              label="ISRC"
              className={classes.isrcInput}
              value={isrc}
              onChange={event => { setIsrc(event.target.value) }}
              variant="filled"
              error={invalidIsrc}
            />
            <TextField
              label="Starts At"
              className={classes.startsAtStrInput}
              placeholder="mm:ss"
              value={startsAtStr}
              onChange={event => { setStartsAtStr(event.target.value) }}
              variant="filled"
              error={invalidStartsAt}
            />
            <Button
              className={classes.createButton}
              disabled={addingTrack}
              color="primary"
              variant="contained"
              onClick={onAddTrack}
              size="medium"
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

AddTrack.propTypes = {
  uuid: PropTypes.string
};
