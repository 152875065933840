import { useRecordContext } from "react-admin";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import { DATE_FORMAT } from "./constants";

export function DateTimeField(props) {
  const { source } = props;
  const record = useRecordContext(props);

  const datetime = record[source];
  return (
    <span>{datetime ? format(new Date(datetime), DATE_FORMAT) : ""}</span>
  );
}

DateTimeField.defaultProps = {
  addLabel: true
};

DateTimeField.propTypes = {
  source: PropTypes.string.isRequired,
  addLabel: PropTypes.bool, // provided by SimpleShowLayout, TabbedShowLayout, SimpleForm, TabbedForm which looks at prop value for child component (DateField)
};
