import { client } from "../gqlClient";
import {
  UPSERT_TRACK,
  GET_LIST_TRACKS,
  GET_ONE_TRACK,
  GET_ALL_TRACK_ARTISTS,
  GET_ALL_TRACK_GENRES,
  UPDATE_TRACK_LICENSE,
} from "./schema";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
} from "../utils";

export async function trackBulkUpsert(params) {
  const { data } = params;

  let success = 0;
  let errors = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const track of data) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await trackUpsert({
        data: {
          isrc: track.isrc,
          source: "tuned_global",
        },
      });
      success += 1;
    } catch (e) {
      errors += 1;
    }
  }

  return {
    success,
    errors,
  };
}

export async function getAllTrackArtists() {
  const data = await client.query(GET_ALL_TRACK_ARTISTS, null, "trackArtists");
  return {
    data,
    total: data.length,
  };
}

export async function getAllTrackGenres() {
  const data = await client.query(GET_ALL_TRACK_GENRES, null, "trackGenres");
  return {
    data,
    total: data.length,
  };
}

export async function trackUpsert(params) {
  const { data } = params;

  const updates =
    data?.source === "manual"
      ? {
          title: data.title,
          artists: data.artists,
          label: data.label,
          albumName: data.albumName,
          copyright: data.copyright,
          releaseDate: data.releaseDate,
          image: data.image,
          genre: data.genre,
          isExplicit: data.isExplicit,
          isLicensed: data.isLicensed,
        }
      : {};

  const track = await client.mutate(
    UPSERT_TRACK,
    {
      input: {
        ...updates,
        isrc: data.isrc,
        source: data.source,
        youtube: data.youtube,
        spotify: data.spotify,
        appleMusic: data.appleMusic,
        soundcloud: data.soundcloud,
        tikTokSound: data.tikTokSound,
        trackSource: data.trackSource,
      },
    },
    "trackUpsert"
  );

  return {
    data: track,
  };
}

export async function updateTrackLicense(params) {
  const { data } = params;

  const track = await client.mutate(
    UPDATE_TRACK_LICENSE,
    {
      input: {
        isLicensed: data.isLicensed,
        isrc: data.isrc,
      },
    },
    "updateTrackLicense"
  );

  return {
    data: track,
  };
}

export async function getListTracks(params) {
  const data = await client.query(
    GET_LIST_TRACKS,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort),
    },
    "tracks"
  );

  return {
    data: data.edges.map((e) => e.node),
    total: data.totalCount,
  };
}

export async function getManyReferenceTracks(params) {
  return getListTracks({
    ...params,
    filter: {
      [params.target]: params.id,
    },
  });
}

export async function getOneTrack({ id }) {
  const data = await client.query(
    GET_ONE_TRACK,
    {
      id,
    },
    "trackById"
  );

  return { data };
}
