import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ReportIcon from "@material-ui/icons/Report";
import { CardContent } from "@material-ui/core";
import React from "react";
import {
  BooleanField,
  Datagrid,
  EditButton,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  TextField,
  TextInput,
} from "react-admin";
import { Link } from "react-router-dom";
import { withEnums } from "data/utils";
import { DateTimeField } from "lib/components/DateTime";
import { EncodingStatusField } from "lib/components/EncodingStatusField";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { HelpInfo } from "lib/components/HelpInfo";
import { PlayVideoField } from "lib/components/PlayVideoField";
import { TimeFilterOptionsList } from "lib/components/TimeFilterOptionsList";
import { VideoUploadDetail } from "./components/VideoUploadDetail";
import { useVideoUploadListStyles } from "./styles";

const videoUploadListFilters = [
  <TextInput label="Challenge ID" source="challengeId" alwaysOn style={{ minWidth: "340px" }} />,
  <TextInput label="Video ID" source="id" style={{ minWidth: "340px" }} />,
  <TextInput label="UID or User ID" source="userId" style={{ minWidth: "340px" }} />,
];

const VideoUploadFilterSidebar = withEnums(({ enums }) => {
  const classes = useVideoUploadListStyles();

  return (
    <FilterSidebar>
      <CardContent className={classes.filterCard}>
        <div className={classes.helpButton}>
          <HelpInfo helpInfos={[
            ["Filters"],
            ["Updated", "This is when the video was last updated (encoded, uploaded, description updated, etc.)."],
            ["Encoding Status", `Requested is when the user has made the intent to start uploading a video. 
            
            Uploaded is when the user has successfully uploaded a video. 
            
            Available is when the video has finished encoding. 
            
            Error is when there has been an issue with encoding.`],
            ["Approval Status", `None is when the video has not been processed for approval yet. 
            
            Approved is when the video has been approved and is available for viewing. 
            
            Rejected is when the video has been blocked.
            
            User videos are auto-approved by default. When a user has more than 3 blocked videos, their future videos will need to be manually approved by admins.
            `],
            ["User Submitted", `Submitted is when the user has made the action to post the video. 
            
            Not submitted is when the user has uploaded the video, but not posted it.`],
            ["User Deleted", "Deleted is when the user has soft deleted the video and it is hidden."]
          ]} />
        </div>
        <TimeFilterOptionsList label="Updated" filterFieldPrefix="updated" />
        <FilterList label="Encoding Status" icon={<SlowMotionVideoIcon />}>
          {enums.videoEncodingStatus.map(encodingStatus => (
            <FilterListItem key={encodingStatus} label={encodingStatus} value={{ encodingStatus }} />
          ))}
        </FilterList>
        <FilterList label="Approval Status" icon={<ThumbUpAltIcon />}>
          {enums.videoApprovalStatus.map(approvalStatus => (
            <FilterListItem key={approvalStatus} label={approvalStatus} value={{ approvalStatus }} />
          ))}
        </FilterList>
        <FilterList label="User Submitted" icon={<PublishIcon />}>
          <FilterListItem label="submitted" value={{ isUserSubmitted: true }} />
          <FilterListItem label="not submitted" value={{ isUserSubmitted: false }} />
        </FilterList>
        <FilterList label="User Deleted" icon={<DeleteForeverIcon />}>
          <FilterListItem label="deleted" value={{ isUserDeleted: true }} />
          <FilterListItem label="not deleted" value={{ isUserDeleted: false }} />
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
});

export function VideoUploadList(props) {
  const classes = useVideoUploadListStyles();

  return (
    <List
      {...props}
      perPage={10}
      sort={{ field: "updatedAt", order: "DESC" }}
      filters={videoUploadListFilters}
      bulkActionButtons={false}
      aside={<VideoUploadFilterSidebar />}
    >
      <Datagrid expand={<VideoUploadDetail />}>
        <EncodingStatusField source="encodingStatus" label="Encoding Status" sortable={false} />
        <TextField source="approvalStatus" label="Approval Status" sortable={false} />
        <PlayVideoField source="playbackUrl" label="Video" sortable={false} />
        <BooleanField source="isUserSubmitted" label="User Submitted" sortable={false} />
        <BooleanField source="isUserDeleted" label="User Deleted" sortable={false} />
        <DateTimeField source="updatedAt" label="Last Updated" />
        <FunctionField label="Flagged Content" render={record => (
          record.flaggedCount > 0 ?
            <Link className={classes.link} to={`/flaggedActivity?filter={"activityId":"${record.id}"}`}>
              Flagged {record.flaggedCount} {record.flaggedCount > 1 ? "Times" : "Time"}
            </Link>
            : <div />
        )} />
        <FunctionField render={record => (
          <Link className={classes.link} to={`/flaggedActivity/create?activityId=${record.id}&activityType=user_video`}>
            <ReportIcon /> Flag Video
          </Link>
        )} />
        <EditButton className={classes.editButton} basePath="/videoUploads" />
      </Datagrid>
    </List>
  );
}
