import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
  uploadAssetToS3,
} from "../utils";
import {
  ADD_CLASS_PLAYLIST,
  CREATE_PLAYLIST,
  CREATE_PLAYLIST_ASSET,
  GET_LIST_PLAYLISTS,
  GET_ONE_PLAYLIST,
  REMOVE_CLASS_PLAYLIST,
  UPDATE_PLAYLIST,
} from "./schema";

export async function playlistCreate(params) {
  const { data } = params;

  const res = await client.mutate(CREATE_PLAYLIST, { name: data.name });

  const playlist = res.adminPlaylistCreate;

  return {
    data: playlist,
  };
}

export async function getListPlaylists(params) {
  const { playlists } = await client.query(GET_LIST_PLAYLISTS, {
    filter: params.filter,
    pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
    sort: convertAdminSortToGraphqlSort(params.sort),
  });

  return {
    data: playlists.edges.map((e) => e.node),
    total: playlists.totalCount,
  };
}

export async function getOnePlaylist({ id }) {
  const { playlist } = await client.query(GET_ONE_PLAYLIST, { playlistId: id });

  return { data: playlist };
}

export async function playlistUpdate(params) {
  const { data } = params;

  const thumbnailImageId =
    data.thumbnailImageUrl?.rawFile instanceof File
      ? await uploadNewAsset(
          data.thumbnailImageUrl.rawFile,
          "thumbnail",
          "image/jpeg"
        )
      : null;

  const res = await client.mutate(UPDATE_PLAYLIST, {
    input: {
      playlistId: data.id,
      name: data.name,
      displayIndex: data.displayIndex,
      thumbnailImageId,
    },
  });

  const playlist = res.adminPlaylistUpdate;

  return { data: playlist };
}

export async function playlistPublish(params) {
  const res = await client.mutate(UPDATE_PLAYLIST, {
    input: {
      playlistId: params.id,
      hidden: false,
    },
  });

  const data = res.adminCategoryUpdate;

  return { data };
}

export async function playlistUnPublish(params) {
  const res = await client.mutate(UPDATE_PLAYLIST, {
    input: {
      playlistId: params.id,
      hidden: true,
    },
  });

  const data = res.adminCategoryUpdate;

  return { data };
}

export async function playlistAddClass(params) {
  const { playlistId, classId } = params;

  const res = await client.mutate(ADD_CLASS_PLAYLIST, {
    input: {
      playlistId,
      studioClassId: classId,
    },
  });

  const data = res.adminPlaylistAddClass;

  return { data };
}

export async function playlistRemoveClass(params) {
  const { playlistId, classId } = params;

  const res = await client.mutate(REMOVE_CLASS_PLAYLIST, {
    input: {
      playlistId,
      studioClassId: classId,
    },
  });

  const data = res.adminPlaylistRemoveClass;

  return { data };
}

async function uploadNewAsset(file, assetType, contentType) {
  const res = await client.mutate(CREATE_PLAYLIST_ASSET, {
    assetType,
  });

  const { id, signedUrl } = res.adminPlaylistAssetUploadCreate;

  await uploadAssetToS3(signedUrl, file, contentType);

  return id;
}
