import { makeStyles } from "@material-ui/core";

export const PUBLISHED_ENTITY_COLOR = "rgba(183, 226, 205, 0.5)";
export const UNPUBLISHED_ENTITY_COLOR = "rgba(224, 199, 195, 0.5)";

export const usePublishedEntityListStyles = makeStyles(theme => ({
  editButton: {
    color: theme.palette.secondary.main,
  },
  detail: {
    backgroundColor: "#fafafa",
    margin: "-6px -16px",
    padding: "6px 16px",
    "& th": {
      backgroundColor: "#eaeaea",
    }
  }
}));

export function getRequiredFieldErrors(values, requiredFields) {
  const errors = {};

  requiredFields.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
