/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState, useCallback } from "react";
import Hls from "hls.js";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

export function VideoPlayer(props) {
  const { playbackUrl, classes } = props;
  const videoRef = useRef(null);
  const hlsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlePlay = e => {
    videoRef.current.play();
    e.stopPropagation(); // prevent modal from closing
  };

  const handleLoaded = useCallback(() => {
    if (Hls.isSupported() && hlsRef.current) {
      hlsRef.current.off(Hls.Events.FRAG_BUFFERED, handleLoaded);
    } else {
      videoRef.current.removeEventListener("loadedmetadata", handleLoaded);
    }
    setIsLoading(false);
  }, [hlsRef, videoRef]);

  useEffect(() => {
    if (!videoRef.current || !playbackUrl) {
      return;
    }
    setIsLoading(true);
    const videoEl = videoRef.current;
    if (Hls.isSupported()) {
      hlsRef.current = new Hls();
      hlsRef.current.loadSource(playbackUrl);
      hlsRef.current.attachMedia(videoRef.current);
      hlsRef.current.on(Hls.Events.FRAG_BUFFERED, handleLoaded);
    } else {
      videoEl.src = playbackUrl;
      videoEl.addEventListener("loadedmetadata", handleLoaded);
    }
    videoEl.volume = 0.3;
  }, [videoRef, playbackUrl, handleLoaded]);

  return (
    <>
      {isLoading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
      <video
        data-testid="video"
        controls
        ref={videoRef}
        className={classes.video}
        style={{ visibility: isLoading ? "hidden": "visible" }}
        onClick={handlePlay}
      />
    </>
  );
}

VideoPlayer.propTypes = {
  playbackUrl: PropTypes.string,
  classes: PropTypes.object,
};
