import React from "react";
import PropTypes from "prop-types";
import { useMutation, useNotify, useRecordContext } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { sleep } from "lib/utils";
import { RESOURCE_TYPE } from "lib/constants/resourceType";

const useRemoveClassFieldStyles = makeStyles(() => ({
  removeClassField: {
    display: "flex",
    alignItems: "center"
  },
  closeIcon: {
    color: "#AB003C",
    cursor: "pointer",
  }
}));

export function RemoveClassField({ playlistId, ...props }) {
  const classes = useRemoveClassFieldStyles();
  const classRecord = useRecordContext(props);

  const notify = useNotify();

  const [ removeClass ] = useMutation(
    {
      type: "removeClass",
      resource: RESOURCE_TYPE.PLAYLISTS
    }, {
      onSuccess: async () => {
        notify("Successfully removed the class from the playlist. RELOADING...");
        await sleep(1000);
        window.location.reload();
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  async function handleClick() {
    const payload = {
      playlistId,
      classId: classRecord.uuid,
    };

    await removeClass({ payload });
  }

  return (
    <div className={classes.removeClassField}>
      <CloseIcon
        fontSize="small"
        className={classes.closeIcon}
        onClick={() => handleClick()}
      />
    </div>
  );
}

RemoveClassField.propTypes = {
  playlistId: PropTypes.string,
};
