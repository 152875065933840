import { client } from "../gqlClient";
import {
  GET_LIST_CLASS_VIDEOS,
  CLASS_VIDEO_FILE_UPLOAD_CREATE,
  CLASS_VIDEO_FILE_UPLOAD_COMPLETE
} from "./schema";
import { convertAdminPgToGraphqlRelayPg, convertAdminSortToGraphqlSort } from "../utils";

export async function getListClassVideos(params) {
  const data = await client.query(
    GET_LIST_CLASS_VIDEOS,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "classVideos"
  );

  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function getManyReferenceClassVideos(params) {
  const newParams = {
    ...params,
    filter: {
      ...params.filter,
      classId: params.id
    }
  };

  return getListClassVideos(newParams);
}

export async function classVideoFileUploadCreate(classId, numParts, classVideoType) {
  const data = await client.mutate(
    CLASS_VIDEO_FILE_UPLOAD_CREATE,
    {
      input: {
        classId,
        parts: numParts,
        classVideoType,
      }
    },
    "classVideoFileUploadCreate"
  );

  return {
    s3PartsUrls: data.s3PartsUrls,
    videoId: data.id,
    uploadId: data.uploadId,
  };
}

export async function classVideoFileUploadComplete(videoId, parts, uploadId) {
  const data = await client.mutate(
    CLASS_VIDEO_FILE_UPLOAD_COMPLETE,
    {
      input: {
        videoId,
        parts,
        uploadId,
      }
    },
    "classVideoFileUploadComplete"
  );

  const success = !!data.id;
  return success;
}

export function videoTypeEnumToLabel(videoType) {
  const videoTypeParts = videoType.split("_");
  const capitalizedParts = videoTypeParts.map(x => {
    return (x.charAt(0).toUpperCase() + x.slice(1));
  });

  return capitalizedParts.join(" ");
}
