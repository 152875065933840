import { client } from "../gqlClient";
import {
  COMPLETE_FLAGGED_ACTIVITY_REVIEW,
  GET_ALL_FLAGGED_ACTIVITY,
  GET_ONE_FLAGGED_ACTIVITY,
  REPORT_ACTIVITY,
} from "./schema";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
} from "../utils";

export async function flaggedActivities(params) {
  const data = await client.query(
    GET_ALL_FLAGGED_ACTIVITY,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "flaggedActivities"
  );

  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function getOneFlaggedActivity({ id }) {
  const data = await client.query(
    GET_ONE_FLAGGED_ACTIVITY,
    {
      id
    },
    "flaggedActivityById"
  );

  return { data };
}

export async function flaggedActivityReview(params) {
  const { id, data } = params;

  const flaggedActivity = await client.mutate(
    COMPLETE_FLAGGED_ACTIVITY_REVIEW,
    {
      input: {
        reportId: id,
        reviewDecision: data.reviewDecision,
        reviewNotes: data.reviewNotes,
      }
    },
    "flaggedActivityReview"
  );

  return { data: flaggedActivity };
}

export async function flaggedActivityCreate(params) {
  const { data } = params;

  const flaggedActivity = await client.mutate(
    REPORT_ACTIVITY,
    {
      input: {
        activityId: data.activityId,
        activityType: data.activityType,
        reportReason: data.reportReason,
        description: data.description,
      }
    },
    "flaggedActivityCreate"
  );

  return { data: flaggedActivity };
}
