import { makeStyles } from "@material-ui/core/styles";

export const useUserListStyles = makeStyles(() => ({
  filterCard: {
    position: "relative",
  },
  helpButton: {
    position: "absolute",
    top: "0px",
    right: "0px"
  }
}));
