import { Typography } from "@material-ui/core";
import * as React from "react";
import { Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from "react-admin";
import { withEnums } from "data/utils";
import { DateTimeInput } from "lib/components/DateTime";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import { validateChallenge } from "./utils";

export const ChallengeEdit = withEnums(({ enums, ...props }) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm toolbar={<ChallengeEditToolbar />} validate={validateChallenge} warnWhenUnsavedChanges>
      <Typography variant="h6">Edit Challenge</Typography>
      <ReadOnlyField addLabel label="ID" source="id" />
      <ReadOnlyField addLabel label="Class ID" source="forClass.id" />
      <TextInput source="title" fullWidth />
      <TextInput source="description" fullWidth multiline />
      <SelectInput
        source="challengeStatus"
        choices={enums.challengeStatus.map(status => ({
          id: status,
          name: status
        }))}
      />
      <DateTimeInput label="Start Time" source="startTime" />
      <DateTimeInput label="End Time" source="endTime" />
    </SimpleForm>
  </Edit>
));

function ChallengeEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}
