import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNotify } from "react-admin";
import { useDropzone } from "react-dropzone";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { withEnums } from "data/utils";
import {
  categoryVideoFileUploadCreate,
  categoryVideoFileUploadComplete
} from "data/categoryVideos";
import {
  classVideoFileUploadCreate,
  classVideoFileUploadComplete,
  videoTypeEnumToLabel
} from "data/classVideos";
import { useUploadVideoStyles } from "./styles";
import { uploadFile } from "./uploadVideoFileUtil";

const DEFAULT_VIDEO_FILE_STATE = {
  percentProgress: null,
  videoId: null,
  file: null
};

export const UploadVideo = withEnums(({ ownerId, ownerType, allowedVideoTypes, helpInfo, subtitle }) => {
  if (!ownerId || !ownerType) {
    return null;
  }
  const notify = useNotify();
  const [videoFileState, setVideoFileState] = useState(DEFAULT_VIDEO_FILE_STATE);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // for class types
  const isClass = ownerType === "class";
  const [classVideoType, setClassVideoType] = useState(null);

  const handleToggleModal = () => {
    if (videoFileState.percentProgress != null) {
      return;
    }
    if (!isModalOpen) { // reset modal state
      setClassVideoType(null);
      setVideoFileState(DEFAULT_VIDEO_FILE_STATE);
    }
    setIsModalOpen(!isModalOpen);
  };
  const onDrop = async acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const file = acceptedFiles[0];
    setVideoFileState({ ...videoFileState, file });
  };
  const onDropRejected = fileRejections => {
    if (fileRejections.length === 0 || fileRejections[0].errors.length === 0) {
      return;
    }

    const error = fileRejections[0].errors[0];
    if (error.code === "file-invalid-type") {
      notify("Invalid file type. Please upload a video.");
    } else {
      notify("Error uploading video. Please try again.");
    }
  };

  let videoFileUploadCreate;
  let videoFileUploadComplete;

  switch (ownerType) {
    case "class": {
      videoFileUploadCreate = numParts => classVideoFileUploadCreate(ownerId, numParts, classVideoType);
      videoFileUploadComplete = classVideoFileUploadComplete;
      break;
    }
    case "category": {
      videoFileUploadCreate = numParts => categoryVideoFileUploadCreate(ownerId, numParts);
      videoFileUploadComplete = categoryVideoFileUploadComplete;
      break;
    }
    default:
      throw new Error("Not supported owner type");
  }

  const handleVideoSubmit = async () => {
    if (isClass && !classVideoType) {
      notify("Please select a video type.");
      return;
    }
    if (!videoFileState.file) {
      notify("Please select a video file.");
      return;
    }

    setVideoFileState({
      ...videoFileState,
      percentProgress: 0,
      videoId: null,
    });
    const success = await uploadFile(
      videoFileState.file,
      videoFileUploadCreate,
      videoFileUploadComplete,
      setVideoFileState
    );
    if (success) {
      window.location.reload();
    } else {
      notify("Error uploading video. Please try again.");
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    minSize: 1,
    multiple: false,
    maxFiles: 1,
    accept: "video/*",
    noClick: true,
  });

  const classes = useUploadVideoStyles({ isDragActive });

  const handleClassVideoTypeChange = e => setClassVideoType(e.target.value);

  const handleCancel = () => {
    // could cancel requests, but reloading is a little easier :)
    window.location.reload();
  };

  return (
    <div className={classes.uploadVideo}>
      <Button
        color="primary"
        variant="contained"
        onClick={handleToggleModal}
        size="small"
      >
        Upload New Video
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleToggleModal}
      >
        <div className={classes.modalBody}>
          {videoFileState.percentProgress === null && (
            <>
              <div className={classes.modalTop}>
                <div>
                  <div className={classes.modalTopText}>
                    <Typography variant="h6">Upload {ownerType.charAt(0).toUpperCase() + ownerType.slice(1)} Video</Typography>
                    {helpInfo && (helpInfo)}
                  </div>
                  <Typography variant="body2">{subtitle}</Typography>
                </div>
                <CloseIcon className={classes.close} title="close" onClick={handleToggleModal} />
              </div>
              <div className={classes.modalForm}>
                { // special treatment for class videos
                  isClass &&
                  <FormControl variant="outlined" className={classes.selectForm}>
                    <InputLabel>Video Type</InputLabel>
                    <Select
                      label="Video Type"
                      value={classVideoType}
                      onChange={handleClassVideoTypeChange}
                      displayEmpty
                    >
                      {allowedVideoTypes.map(videoTypeEnum => (
                        <MenuItem key={videoTypeEnum} value={videoTypeEnum}>{videoTypeEnumToLabel(videoTypeEnum)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                <div className={classes.dragAndDrop} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Typography variant="caption">
                    Drag & drop or <button className={classes.browseButton} type="button" onClick={open}>browse</button><br />
                    <span className={classes.fileName}>{videoFileState.file ? videoFileState.file.name : ""}</span>
                  </Typography>
                </div>
                <Button
                  className={classes.uploadButton}
                  color="primary"
                  variant="contained"
                  onClick={handleVideoSubmit}
                  size="medium"
                >
                  Upload
                </Button>
              </div>
            </>
          )}
          {videoFileState.percentProgress !== null && (
            <div className={classes.modalProgressContent}>
              <Typography variant="caption" component="div" color="textSecondary">Uploading...</Typography>
              <LinearProgress variant="determinate" value={videoFileState.percentProgress} style={{ width: "100%" }} />
              <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                videoFileState.percentProgress
              )}%`}</Typography>
              <Typography variant="caption" component="div" color="textSecondary">Do not close this window while video is uploading.</Typography>
              <Button
                className={classes.cancelButton}
                color="primary"
                variant="contained"
                onClick={handleCancel}
                size="medium"
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
});

UploadVideo.propTypes = {
  ownerId: PropTypes.string,
  ownerType: PropTypes.string,
  allowedVideoTypes: PropTypes.arrayOf(PropTypes.string)
};
