import { makeStyles } from "@material-ui/core/styles";

export const useUploadVideoStyles = makeStyles(() => ({
  uploadVideo: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  modalBody: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    minHeight: "40vh",
    maxHeight: "100vh",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll"
  },
  modalProgressContent: {
    flex: "1",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 16px",
  },
  modalTopText: {
    display: "flex",
    alignItems: "center"
  },
  close: {
    cursor: "pointer",
  },
  modalForm: {
    width: "100%",
    padding: "16px",
    boxSizing: "border-box"
  },
  selectForm: {
    marginBottom: "30px",
    width: "100%",
  },
  dragAndDrop: props => ({
    border: "1px dashed black",
    backgroundColor: props.isDragActive ? "#dadada" : "#fafafa",
    padding: "10px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "200px",
    textAlign: "center"
  }),
  browseButton: {
    border: "none",
    backgroundColor: "transparent",
    textDecoration: "underline",
    margin: 0,
    padding: 0,
    fontSize: "12px",
    cursor: "pointer"
  },
  uploadButton: {
    float: "right",
    marginTop: "30px"
  },
  cancelButton: {
    marginTop: "30px"
  },
  fileName: {
    fontSize: "16px"
  }
}));
