import React from "react";
import PropTypes from "prop-types";
import { useMutation, useNotify, useRecordContext } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { sleep } from "lib/utils";

const useRemoveTrackFieldStyles = makeStyles(() => ({
  removeTrackField: {
    display: "flex",
    alignItems: "center"
  },
  closeIcon: {
    color: "#AB003C",
    cursor: "pointer",
  }
}));

export function RemoveTrackField({ uuid,  ...props }) {
  const classes = useRemoveTrackFieldStyles();
  const record = useRecordContext(props);

  const notify = useNotify();

  const [removeTrack] = useMutation(
    {
      type: "delete",
      resource: "classTracks",
      payload: {
        data: {
          isrc: record.track.isrc,
          uuid,
          startsAt: 0
        }
      }
    }, {
      onSuccess: async () => {
        notify("Successfully removed the track from the class. RELOADING...");
        await sleep(1000);
        window.location.reload();
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  return (
    <div className={classes.removeTrackField}>
      <CloseIcon
        fontSize="small"
        className={classes.closeIcon}
        onClick={removeTrack}
      />
    </div>
  );
}

RemoveTrackField.propTypes = {
  uuid: PropTypes.string,
};
