import { gql } from "@apollo/client";

const FLAGGED_ACTIVITY_FRAGMENT = gql`
  fragment FlaggedActivityFragment on FlaggedActivity {
    id
    createdAt
    updatedAt
    reportStatus
    reviewDecision
    reportReason
    description
    reviewNotes
    reviewedBy {
      id
      email  
    }
    activityType
    activityId
    content {
        ... on ActivityComment {
            id
            body
        }
        ... on VideoUpload {
            id
            playbackUrl
        }
    }
  }
`;

export const GET_ALL_FLAGGED_ACTIVITY = gql`
  ${FLAGGED_ACTIVITY_FRAGMENT}
  query AllFlaggedActivity($sort: OrderByInput, $pagination: ConnectionArgs, $filter: FlaggedActivityFilterInput) {
    flaggedActivities(sort: $sort, pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          ...FlaggedActivityFragment
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ONE_FLAGGED_ACTIVITY = gql`
  ${FLAGGED_ACTIVITY_FRAGMENT}
  query FlaggedActivityById($id: String!) {
    flaggedActivityById(id: $id) {
      ...FlaggedActivityFragment
    }
  }
`;

export const COMPLETE_FLAGGED_ACTIVITY_REVIEW = gql`
  ${FLAGGED_ACTIVITY_FRAGMENT}
  mutation CompleteFlaggedActivityReview($input: FlaggedActivityReviewInput!) {
    flaggedActivityReview(input: $input) {
      ...FlaggedActivityFragment
    }
  }
`

export const REPORT_ACTIVITY = gql`
  ${FLAGGED_ACTIVITY_FRAGMENT}
  mutation ReportActivity($input: FlaggedActivityCreateInput!) {
    flaggedActivityCreate(input: $input){ 
      ...FlaggedActivityFragment
    }
  }
`;
