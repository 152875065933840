import {
  CreateButton,
  Datagrid,
  EditButton, ExportButton,
  FilterButton, FilterListItem,
  List, NumberField,
  TextField,
  TextInput,
  TopToolbar, useGetList,
  FilterList,
} from "react-admin";
import * as React from "react";
import { CardContent, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FilterSidebar } from "lib/components/FilterSidebar";

const useTagsListStyles = makeStyles(theme => ({
  editButton: {
    color: theme.palette.secondary.main,
  },
}));

const tagFilters = [
  <TextInput source="name" alwaysOn style={{ minWidth: "340px" }} />,
  <TextInput label="Tag DB ID" source="id" style={{ minWidth: "340px" }} />,
];

export function TagList(props) {
  const styles = useTagsListStyles();

  return (
    <List
      {...props}
      perPage={50}
      sort={{ field: "updatedAt", order: "DESC" }}
      bulkActionButtons={false}
      actions={<TagListActions />}
      filters={tagFilters}
      aside={<TagsFilterSidebar />}
    >
      <Datagrid>
        <TextField source="type" />
        <TextField source="name" />
        <TextField source="slug" sortable={false} />
        <NumberField source="displayIndex"/>
        <EditButton className={styles.editButton} basePath="/tags" />
      </Datagrid>
    </List>
  );
}

function TagListActions() {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );
}

function TagsFilterSidebar() {
  const tagTypes = useTagTypes();

  return (
    <FilterSidebar>
      <CardContent style={{position: "relative"}}>
        <FilterList label="Tag Type" icon={<FilterListIcon />}>
          {tagTypes.map(type => (
            <FilterListItem
              label={type}
              key={type}
              value={{ type }}
            />
          ))}
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
}

function useTagTypes() {
  const list = useGetList("tagTypes");

  const tagTypes = Object.values(list.data);

  return tagTypes.map(t => t.name);
}
