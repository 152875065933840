import React from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  TopToolbar,
  linkToRecord,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  PUBLISHED_ENTITY_COLOR,
  UNPUBLISHED_ENTITY_COLOR,
  usePublishedEntityListStyles,
} from "../../utils";

const playlistFilters = [
  <TextInput label="Name" source="name" alwaysOn style={{ minWidth: "340px" }} />,
];

export function PlaylistList(props) {
  const classes = usePublishedEntityListStyles();

  return (
    <List
      {...props}
      perPage={30}
      sort={{ field: "displayIndex", order: "ASC" }}
      bulkActionButtons={false}
      actions={<PlaylistListActions />}
      filters={playlistFilters}
    >
      <Datagrid expand={<PlaylistDetail />} rowStyle={rowStyle}>
        <TextField source="name" />
        <TextField source="displayIndex" label="Display Index" />
        <EditButton className={classes.editButton} basePath="/playlists" />
      </Datagrid>
    </List>
  );
}

function PlaylistListActions() {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <CreateButton />
      <ExportButton />
    </TopToolbar>
  );
}

function PlaylistDetail() {
  const classes = usePublishedEntityListStyles();

  return (
    <div className={classes.details}>
      <Typography variable="header1">Classes</Typography>
      <ReferenceManyField addLabel={false} reference="classes" target="playlistId" source="id" pagination={<Pagination />}>
        <Datagrid empty={<span>No classes linked</span>}>
          <FunctionField label="Class Number" render={record => {
            const linkToClass = linkToRecord("/classes", record.id);
            return (
              <Link to={linkToClass}>{record.classId}</Link>
            );
          }} />
          <TextField source="title" sortable />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
}

function rowStyle(record) {
  return {
    backgroundColor: record.hidden ? UNPUBLISHED_ENTITY_COLOR : PUBLISHED_ENTITY_COLOR,
  };
}
