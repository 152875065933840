import { makeStyles } from "@material-ui/core";

export const useFlaggedActivityListStyles = makeStyles(theme => ({
  highlightedEditButton: {
    color: theme.palette.secondary.main,
  },
  filterCard: {
    position: "relative",
  },
  helpButton: {
    position: "absolute",
    top: "0px",
    right: "0px"
  }
}));
