import * as React from "react";

import {
  BooleanField,
  Datagrid,
  EditButton,
  FilterList,
  FilterListItem,
  FunctionField,
  Link,
  List,
  ReferenceField,
  TextField,
  TextInput,
  TitlePropType,
  UrlField,
  useGetList,
  useQuery,
} from "react-admin";
import { DateTimeField } from "lib/components/DateTime";
import { PlayVideoField } from "lib/components/PlayVideoField";
import { slugToDisplayText } from "lib/utils";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { CardContent } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useEnums } from "lib/hooks";
import { HelpInfo } from "lib/components/HelpInfo";
import { useClassListStyles } from "./styles";
import { PUBLISHED_ENTITY_COLOR, UNPUBLISHED_ENTITY_COLOR } from "../../utils";

const classFilters = [
  <TextInput
    label="Number"
    source="classId"
    style={{ maxWidth: "125px" }}
    alwaysOn
  />,
  <TextInput
    label="Title"
    source="title"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <TextInput
    label="Instructor Name"
    source="instructorName"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <TextInput
    label="Track Title"
    source="trackTitle"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <TextInput
    label="Music Label"
    source="trackLabel"
    style={{ minWidth: "200px" }}
    alwaysOn
  />,
  <TextInput
    label="Track Artist Name (exact full name)"
    source="trackArtist"
    style={{ minWidth: "200px" }}
  />,
  <TextInput
    label="Track DB ID"
    source="trackId"
    style={{ minWidth: "200px" }}
  />,
  <TextInput
    label="Instructor DB ID"
    source="instructor"
    style={{ minWidth: "200px" }}
  />,
];

export function ClassList(props) {
  const classes = useClassListStyles();

  return (
    <>
      <List
        {...props}
        perPage={50}
        sort={{ field: "publishDate", order: "DESC" }}
        bulkActionButtons={false}
        filters={classFilters}
        aside={<ClassesFilterSidebar />}
      >
        <Datagrid rowStyle={rowStyle}>
          <TextField source="classId" label="Number" />
          <TextField source="title" />
          <ReferenceField
            label="Instructor"
            reference="instructors"
            source="instructor.id"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="accessType" />
          <TextField source="level" />
          <TextField source="type" />
          <FunctionField
            label="Style"
            render={(record) => slugToDisplayText(record.style)}
            source="style"
          />
          ;
          <PlayVideoField
            label="Main Video"
            source="video.hlsPlaybackUrls"
            sortable={false}
          />
          <PlayVideoField
            label="Preview Video"
            source="previewVideo.hlsPlaybackUrls"
            sortable={false}
          />
          <BooleanField label="Tracks" source="hasTracks" sortable={false} />
          <BooleanField
            label="Sections"
            source="hasSections"
            sortable={false}
          />
          <BooleanField
            label="Muted Cuepoints"
            source="hasMutedCuepoints"
            sortable={false}
          />
          <UrlField
            label={"External Preview URL"}
            source="externalPreviewUrl"
          />
          <DateTimeField source="publishDate" label="Publish Date" />
          <EditButton className={classes.editButton} basePath="/classes" />
        </Datagrid>
      </List>
    </>
  );
}

function rowStyle(record) {
  return {
    backgroundColor: record.hidden
      ? UNPUBLISHED_ENTITY_COLOR
      : PUBLISHED_ENTITY_COLOR,
  };
}

function ClassesFilterSidebar() {
  const classes = useClassListStyles();
  const { levels, types, styles } = useClassFilters();
  const { enums } = useEnums();

  return (
    <FilterSidebar>
      <CardContent style={{ position: "relative" }}>
        <div className={classes.helpButton}>
          <HelpInfo
            helpInfos={[
              ["Filters"],
              [
                "Access",
                "Access indicates the subscription plan needed to get access to the class.",
              ],
              ["Level", "Level indicates the class difficulty."],
              ["Type", "Type indicates the class type."],
              ["Style", "Style indicates the class style."],
            ]}
          />
        </div>
        {enums && (
          <FilterList label="Access" icon={<FilterListIcon />}>
            {enums.accessType.map((accessType) => (
              <FilterListItem
                label={accessType}
                key={accessType}
                value={{ accessType }}
              />
            ))}
          </FilterList>
        )}
        <FilterList label="Level" icon={<FilterListIcon />}>
          {levels.map((level) => (
            <FilterListItem
              label={level.name}
              key={level.name}
              value={{ level: level.name }}
            />
          ))}
        </FilterList>
        <FilterList label="Types" icon={<FilterListIcon />}>
          {types.map((type) => (
            <FilterListItem
              label={type.name}
              key={type.name}
              value={{ type: type.name }}
            />
          ))}
        </FilterList>
        <FilterList label="Styles" icon={<FilterListIcon />}>
          {styles.map((style) => (
            <FilterListItem
              label={style.name}
              key={style.name}
              value={{ style: style.slug }}
            />
          ))}
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
}

function useClassFilters() {
  const levelsList = useGetList("classLevels");
  const typesList = useGetList("classTypes");
  const stylesList = useGetList("classStyles");

  const levels = Object.values(levelsList.data);
  const types = Object.values(typesList.data).sort((a, b) =>
    a.name < b.name ? -1 : 1
  );
  const styles = Object.values(stylesList.data).sort((a, b) =>
    a.slug < b.slug ? -1 : 1
  );

  return { levels, types, styles };
}
