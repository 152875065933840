import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { Typography, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useConfirmModalStyles } from "./styles";

export function ConfirmButtonAndModal({ isUpdating, onConfirm, buttonText, title }) {
  const classes = useConfirmModalStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={isUpdating || isModalOpen}
        color="primary"
        variant="contained"
        size="small"
      >
        {buttonText}
      </Button>
      <Modal
        open={isModalOpen}
        onClose={handleToggleModal}
      >
        <div className={classes.modalBody}>
          <Typography className={classes.modalTitle} variant="h6">{title}</Typography>
          <CloseIcon className={classes.close} title="close" onClick={handleToggleModal} />
          <div className={classes.actionButtons}>
            <Button
              className={classes.actionButtonConfirm}
              color="primary"
              variant="contained"
              disabled={isUpdating}
              onClick={onConfirm}
              size="medium"
            >
              Confirm
            </Button>
            <Button
              className={classes.actionButtonCancel}
              color="primary"
              variant="contained"
              onClick={handleToggleModal}
              size="medium"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

ConfirmButtonAndModal.propTypes = {
  isUpdating: PropTypes.bool,
  onConfirm: PropTypes.func,
  buttonText: PropTypes.string,
  title: PropTypes.string,
};
