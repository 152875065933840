// https://docs.aws.amazon.com/mediaconvert/latest/ug/mediaconvert_error_codes.html

export const userFriendlyVideoErrors = {
  "1010": "Could not access the video file or the file is corrupted. Try uploading again. (1010)",
  "1020": "Could not open or read the video file. Try uploading again. (1020)",
  "1021": "Could not find audio in the video file. Try uploading again. (1021)",
  "1030": "Unsupported codec. (1030)",
  "1040": "Encoding settings are not compatible to process the video file. (1040)",
  "1041": "Encoding settings aren't compatible with accelerated transcoding. (1041)",
  "1042": "Encoding job doesn't require enough processing power to benefit from accelerated transcoding. (1042)",
  "1050": "Could not write video output files. Disk is full. (1050)",
  "1056": "Could not open the video file. Try uploading again. (1056)",
  "1060": "Could not clip the video file. (1060)",
  "1075": "Encoder couldn't recover when demuxing the video file. (1075)",
  "1076": "Could not read the full video file. Try uploading again. (1076)",
  "1080": "Encoding settings have incorrect configuration for MXF output settings. (1080)",
  "1091": "Issue while encrypting content. Ensure DRM integration is running properly. (1091)",
  "1092": "Could not decode one or more audio streams. (1092)",
  "1401": "Could not access video file on AWS S3. (1401)",
  "1404": "Could not find or open video file on AWS S3. (1404)",
  "1432": "Insufficient permissions to run AWS MediaConvert. (1432)",
  "1433": "Role specified to run MediaConvert job does not exist. (1433)",
  "1434": "Role specified to run MediaConvert job does not have sufficient permissions. (1434)",
  "1507": "Could not write video output files. Disk is full. (1507)",
  "1515": "Role specified to run MediaConvert job does not have sufficient permissions or is malformed. (1515)",
  "1550": "Unexpected error with the accelerated transcoding of this job. (1550)",
  "1999": "Unexpected transcoding error. Try uploading again. (1999)",
  "3400": "HLS input doesn't meet requirements needed from MediaConvert. (3400)",
  "3401": "Could not access HTTP url for input file. Not authorized. (3401)",
  "3403": "Could not access HTTP url for input file. Forbidden. (3403)",
  "3404": "Could not access HTTP url for input file. Not found. (3404)",
  "3408": "Could not retrieve file from HTTP url. Unexpected error. (3408)",
  "3450": "Could not retrieve file from HTTP url. HTTP server returned an error or failed. (3450)",
  "3451": "Could not retrieve file from HTTP url. Could not connect. (3451)",
  "3457": "Input video file could not be accessed. (3457)",
  "3999": "Unexpected error retrieving video input file from HTTP server. (3999)",
};
