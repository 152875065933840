import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { HelpInfo } from "lib/components/HelpInfo";
import { getRequiredFieldErrors } from "../../utils";

export function PlaylistCreate(props) {
  return (
    <Create {...props} mutationMode="pessimistic">
      <SimpleForm validate={validateCreatePlaylist}>
        <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
          Create Playlist
          <HelpInfo helpInfos={[
            ["Create Playlist"],
            ["Name", "Enter the name for the playlist"],
          ]} />
        </Typography>
        <TextInput label="Name" source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
}

function validateCreatePlaylist(values) {
  return getRequiredFieldErrors(values, ["name"]);
}
