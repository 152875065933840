import * as React from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  FunctionField,
  TextField,
  linkToRecord,
  useRecordContext,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { Explicit, VerifiedUser } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { addSeconds, format } from "date-fns";
import { AddTrack } from "./AddTrack";
import { RemoveTrackField } from "./RemoveTrackField";

export function Tracks(props) {
  const classRecord = useRecordContext(props);

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center" p={1}>
        <Typography variant="h6">Tracks</Typography>
        <AddTrack uuid={classRecord.uuid} />
      </Box>
      <ArrayField source="tracks">
        <Datagrid>
          <FunctionField
            label="ISRC"
            render={(record) => {
              const linkToTrack = linkToRecord("/tracks", record?.track?.id);
              return <Link to={linkToTrack}>{record?.track?.isrc}</Link>;
            }}
          />
          <BooleanField
            label="Explicit"
            source="track.isExplicit"
            TrueIcon={Explicit}
            FalseIcon={() => null}
          />
          <BooleanField
            label="Licensed"
            source="track.isLicensed"
            TrueIcon={VerifiedUser}
            FalseIcon={() => null}
          />
          <TextField label="Title" source="track.title" />
          <FunctionField
            label="Artists"
            render={(record) => (
              <span>{record?.track?.artists.join(", ")}</span>
            )}
          />
          <TextField label="Label" source="track.label" />
          <FunctionField
            label="Starts At"
            render={(record) => {
              const helperDate = addSeconds(new Date(0), record.startsAt);
              return <span>{format(helperDate, "mm:ss")}</span>;
            }}
          />
          <RemoveTrackField uuid={classRecord.uuid} />
        </Datagrid>
      </ArrayField>
    </Box>
  );
}
