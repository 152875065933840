import { gql } from "@apollo/client";

const CHALLENGE_FRAGMENT = gql`
  fragment ChallengeFragment on Challenge {
    id
    title
    description
    startTime
    endTime
    challengeStatus
    forClass {
      id
    }
  }
`;

export const GET_LIST_CHALLENGES = gql`
  ${CHALLENGE_FRAGMENT}

  query AllChallenges($sort: OrderByInput, $pagination: ConnectionArgs, $filter: ChallengeFilterInput) {
    challenges(sort: $sort, pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          ...ChallengeFragment
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ONE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  query ChallengeById($id: String!) {
    challengeById(id: $id) {
      ...ChallengeFragment
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  mutation CreateChallenge($input: ChallengeCreateInput!) {
    challengeCreate(input: $input) {
      ...ChallengeFragment
    }
  }
`;

export const UPDATE_CHALLENGE = gql`
  ${CHALLENGE_FRAGMENT}
  mutation UpdateChallenge($input: ChallengeUpdateInput!) {
    challengeUpdate(input: $input) {
      ...ChallengeFragment
    }
  }
`;
