import { Typography } from "@material-ui/core";
import * as React from "react";
import { Create, SelectInput, SimpleForm, TextInput } from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { validateTrack } from "../utils";

export const TrackCreate = withEnums(({ enums, ...props }) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm validate={validateTrack}>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Import Song
        <HelpInfo helpInfos={[
          ["Import Song"],
          ["Song ID", "Enter the Tuned Global Song ID. Ex: 1583"],
        ]} />
      </Typography>
      <SelectInput
        source="source"
        choices={enums.trackSource.map(status => ({
          id: status,
          name: status
        }))}
        defaultValue="tuned_global"
      />
      <TextInput label="ISRC" source="isrc" fullWidth />
    </SimpleForm>
  </Create>
));
