import React, { useEffect } from "react";
import { SaveButton, Toolbar } from "react-admin";
import { useFormState } from "react-final-form";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { usePublishEditToolbarStyles } from "./styles";

export function PublishEditToolbar(props) {
  const classes = usePublishEditToolbarStyles();
  const formState = useFormState();
  const { setIsFormDirty, ...otherProps } = props;

  useEffect(() => {
    setIsFormDirty(formState.dirty);
  }, [formState, setIsFormDirty]);

  return (
    <Toolbar {...otherProps} className={classes.toolbar}>
      <Typography variant="h6">Save</Typography>
      <Typography variant="body2">
        Save all metadata changes.
      </Typography>
      <br />
      <SaveButton
        className={classes.saveButton}
        redirect={false}
        disabled={!formState.dirty}
      />
    </Toolbar>
  );
}

PublishEditToolbar.propTypes = {
  setIsFormDirty: PropTypes.func,
};
