import { gql } from "@apollo/client";

const CATEGORY_VIDEO_FRAGMENT = gql`
  fragment CategoryVideoFragment on AdminCategoryVideo {
    id
    encodingStatus
    createdAt
    stepFunctionArn
    hlsPlaybackUrls {
      defaultUrl
      backUrl
      frontUrl
    }
  }
`;

export const GET_LIST_CATEGORY_VIDEOS = gql`
  ${CATEGORY_VIDEO_FRAGMENT}

  query CategoryVideos($sort: OrderByInput, $pagination: ConnectionArgs, $filter: CategoryVideoFilterInput) {
    admin {
      categoryVideos(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...CategoryVideoFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const CATEGORY_VIDEO_FILE_UPLOAD_CREATE = gql`
  mutation VideoFileUploadCreate($input: CategoryVideoFileCreateInput!) {
    adminCategoryVideoFileUploadCreate(input: $input) {
      id
      s3PartsUrls {
        partNumber
        url
      }
      uploadId
    }
  }
`;

export const CATEGORY_VIDEO_FILE_UPLOAD_COMPLETE = gql`
  mutation VideoUploadComplete($input: VideoUploadCompleteInput!) {
    adminCategoryVideoFileUploadComplete(input: $input) {
      id
    }
  }
`;
