import React from "react";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  EditButton,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  Pagination,
  ReferenceManyField,
  TextField,
  TextInput,
  TopToolbar,
  linkToRecord,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { HelpInfo } from "lib/components/HelpInfo";
import { ImportButton } from "react-admin-import-csv";
import { Link } from "react-router-dom";
import {
  PUBLISHED_ENTITY_COLOR,
  UNPUBLISHED_ENTITY_COLOR,
  usePublishedEntityListStyles,
} from "../../utils";

const categoryFilters = [
  <TextInput label="Slug" source="slug" alwaysOn style={{ minWidth: "340px" }} />,
];

const importConfig = {
  transformRows: csvRows => csvRows.map(category => ({
    slug: category.slug,
    name: category.name,
    description: category.description,
    sortIndex: +category.sortIndex,
    isOnboarding: category.isOnboarding === "checked",
    hidden: category.live !== "checked"
  }))
}

export function CategoryList(props) {
  const classes = usePublishedEntityListStyles();

  return (
    <List
      {...props}
      perPage={30}
      sort={{ field: "sortIndex", order: "ASC" }}
      bulkActionButtons={false}
      actions={<CategoryListActions />}
      filters={categoryFilters}
    >
      <Datagrid expand={<CategoryDetail />} rowStyle={rowStyle}>
        <TextField source="sortIndex" label="Sort Index" />
        <TextField source="name" />
        <BooleanField source="hasMeta.description" label="Description" sortable={false} />
        <TextField source="slug" label="Slug" />
        <BooleanField source="isOnboarding" label="Onboarding" />
        <BooleanField source="hasMedia.previewVideo" label="Preview Video" sortable={false} />
        <BooleanField source="hasMedia.thumbnail" label="Thumbnail Image" sortable={false} />
        <EditButton className={classes.editButton} basePath="/categories" />
      </Datagrid>
    </List>
  );
}

function CategoryListActions(props) {
  return (
    <TopToolbar style={{ display: "flex", alignItems: "center" }}>
      <FilterButton />
      <CreateButton />
      <ExportButton />
      <ImportButton {...props} {...importConfig} label="Bulk Import" />
      <HelpInfo helpInfos={[
        [
          "Bulk Import Categories",
          `Upload a CSV file that has the following columns. The first row contains the column names.

         slug
         name
         description
         sortIndex - value is a number
         isOnboarding - value is "checked" (without quotes) or an empty string
         hidden - value is "checked" (without quotes) or an empty string
        `
        ],
      ]} />
    </TopToolbar>
  );
}

function CategoryDetail() {
  const classes = usePublishedEntityListStyles();

  return (
    <div className={classes.details}>
      <Typography variable="header1">Classes</Typography>
      <ReferenceManyField addLabel={false} reference="classes" target="categoryId" source="id" pagination={<Pagination />}>
        <Datagrid empty={<span>No classes linked</span>}>
          <FunctionField label="Class Number" render={record => {
            const linkToClass = linkToRecord("/classes", record.id);
            return (
              <Link to={linkToClass}>{record.classId}</Link>
            );
          }} />
          <TextField source="title" sortable />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
}

function rowStyle(record) {
  return {
    backgroundColor: record.hidden ? UNPUBLISHED_ENTITY_COLOR : PUBLISHED_ENTITY_COLOR,
  };
}
