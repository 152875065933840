// e.g. input = "open-style"; output = "Open Style"
export function slugToDisplayText(slug) {
  if (!slug) {
    return "";
  }

  let words = slug.split("-");
  words = words.map(word => (word.charAt(0).toUpperCase() + word.slice(1)));

  return words.join(" ");
}

export function sleep(MS) {
  return new Promise(resolve => setTimeout(resolve, MS));
}

export function downloadDataAsJSON(fileName, data) {
  if (typeof data === "string") {
    data = JSON.parse(data);
  }

  data = JSON.stringify(data, null, 2);

  const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(data);
  const downloadAnchorNode = document.createElement("a");

  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", `${fileName}.json`);

  document.body.appendChild(downloadAnchorNode); // Required for Firefox.

  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function generateRandomPassword() {
  const outputLength = 14;
  const charset = "abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789!@#$";

  let out = "";

  for (let i = 0, n = charset.length; i < outputLength; ++i) {
    out += charset.charAt(Math.floor(Math.random() * n));
  }

  return out;
}

// Input: {id: string | boolean; ...}[]
// Output: { [id]: {...}[] } (includes id in objects)
export function normalizeById(arr) {
  return arr.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
}
