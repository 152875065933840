import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { getRequiredFieldErrors } from "../utils";

export const InstructorCreate = withEnums(({ enums, ...props }) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm validate={validateCreateInstructor}>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Create Instructor
        <HelpInfo helpInfos={[
          ["Create Instructor"],
          ["Name", "Enter the name for the instructor"],
        ]} />
      </Typography>
      <TextInput label="Name" source="name" fullWidth />
    </SimpleForm>
  </Create>
));

function validateCreateInstructor(values) {
  return getRequiredFieldErrors(values, ["name"]);
}

export default InstructorCreate;
