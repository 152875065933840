import merge from "lodash.merge";
import { defaultTheme } from "react-admin";
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";

export const theme = merge({}, defaultTheme, {
  sidebar: {
    width: 200,
  },
  menu: {
    width: 200,
  },
  palette: {
    primary: {
      light: blue.A200,
      main: blue.A400,
      dark: blue.A700,
      contrastText: "#fff",
    },
    secondary: {
      light: red.A100,
      main: red.A200,
      dark: red.A700,
      contrastText: "#fff",
    },
  },
  typography: {
    body2: { // make list items font smaller
      fontSize: "12px"
    },
  },
  overrides: {
    RaImageInput: {
      dropZone: {
        border: "1px dashed #d2d2d2",
        fontSize: "12px",
        padding: "10px",
        borderRadius: "8px",
        backgroundColor: "rgb(250, 250, 250)"
      }
    }
  }
});
