import * as React from "react";
import {
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { validateClass } from "./utils";

export const ClassCreate = withEnums(({ enums, ...props }) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm validate={validateClass}>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Create Class
        <HelpInfo helpInfos={[
          ["Create Class"],
          ["Title", "Enter in the title for the class. Maximum 255 characters."],
          ["Class ID", "Number. Unique identifier used in classes URLs"],
        ]} />
      </Typography>
      <NumberInput label="Class Number" source="classId" fullWidth />
      <TextInput source="title" fullWidth />
    </SimpleForm>
  </Create>
));
