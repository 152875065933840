import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FieldTitle, useInput } from "ra-core";
import React, { useCallback } from "react";
import { useTranslate } from "react-admin";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns";
import { DATE_FORMAT } from "./constants";

export function DateTimeInput(props) {
  return (
    <Picker PickerComponent={DateTimePicker} {...props} />
  );
}

function Picker({ PickerComponent, ...fieldProps }) {
  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    providerOptions,
  } = fieldProps;

  const { input, meta } = useInput({ source });
  const { touched, error } = meta;

  const handleChange = useCallback(value => (
    Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null)
  ), [input]);

  const translate = useTranslate();
  const translatedError = error && translate(error);

  return (
    <span>
      <MuiPickersUtilsProvider {...providerOptions}>
        <PickerComponent
          {...options}
          format={DATE_FORMAT}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          error={!!(touched && translatedError)}
          helperText={touched && translatedError}
          className={className}
          value={input.value ? new Date(input.value) : null}
          onChange={date => handleChange(date)}
          onBlur={() => input.onBlur(input.value ? new Date(input.value).toISOString() : null)}
          variant="inline"
        />
      </MuiPickersUtilsProvider>
    </span>
  )
}

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  PickerComponent: PropTypes.func,
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};
