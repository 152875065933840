import React from "react";
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  DateField,
  DateInput,
  Edit,
  FormTab,
  FunctionField,
  ImageField,
  Loading,
  SaveButton,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  useMutation,
  useNotify,
  useQuery,
  useRecordContext,
} from "react-admin";
import PropTypes from "prop-types";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import { Button } from "@material-ui/core";
import { RESOURCE_TYPE } from "lib/constants/resourceType";
import { Explicit, VerifiedUser } from "@material-ui/icons";
import { validateTrack } from "../utils";

export const withTrackGenres = (Component) => (props) => {
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "trackGenres",
    payload: {},
  });

  if (loading || !!error || !data) {
    return null;
  }

  return <Component trackGenres={data} {...props} />;
};

export const withTrackArtists = (Component) => (props) => {
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "trackArtists",
    payload: {},
  });

  if (loading || !!error || !data) {
    return null;
  }

  return <Component trackArtists={data} {...props} />;
};

export const TrackEdit = withTrackGenres(
  withTrackArtists(({ trackGenres, trackArtists, ...props }) => (
    <Edit {...props} mutationMode="pessimistic">
      <TabbedForm
        toolbar={<TrackEditToolbar />}
        validate={validateTrack}
        warnWhenUnsavedChanges
        syncWithLocation={false}
      >
        <DetailsTab trackGenres={trackGenres} trackArtists={trackArtists} />
        <FormTab label="social">
          <TextInput source="youtube" fullWidth />
          <TextInput source="appleMusic" fullWidth />
          <TextInput source="spotify" fullWidth />
          <TextInput source="tikTokSound" fullWidth />
          <TextInput source="soundcloud" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  ))
);

function TrackEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

export function DetailsTab({ trackGenres, trackArtists, ...props }) {
  const record = useRecordContext();
  const notify = useNotify();
  const [onUpdateLicense, { loading: isUpdatingLicense }] = useMutation(
    {
      type: "updateLicense",
      resource: RESOURCE_TYPE.TRACKS,
    },
    {
      onSuccess: async () => {
        notify("Successfully licensed track. RELOADING...");
        window.location.reload();
      },
      onFailure: (error) => {
        notify(`Licensing error occurred: ${error.message}`, { type: "error" });
      },
    }
  );

  const { isLicensed, source, isrc } = record;

  return (
    <>
      {source === "tuned_global" && (
        <FormTab label="details" {...props}>
          <TextField source="id" label="Database ID" />
          <ReadOnlyField addLabel label="ISRC" source="isrc" />
          <ReadOnlyField addLabel label="Source" source="source" />
          <TextField source="title" />
          <TextField source="label" />
          <FunctionField
            render={(data) => <span>{data?.artists?.join(", ")}</span>}
            label="Artists"
            source="artists"
          />
          <FunctionField
            render={(data) => <span>{data?.genre?.join(", ")}</span>}
            label="Genres"
            source="genre"
          />
          <TextField source="albumName" />
          <TextField source="copyright" />
          <DateField source="releaseDate" />
          <BooleanField
            source="isExplicit"
            label="Explicit"
            TrueIcon={Explicit}
          />
          <BooleanField
            source="isLicensed"
            label="Licensed"
            TrueIcon={VerifiedUser}
          />
          {isLicensed ? (
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                onUpdateLicense({
                  payload: { data: { isrc, isLicensed: false } },
                })
              }
            >
              Unlicense track
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                onUpdateLicense({
                  payload: { data: { isrc, isLicensed: true } },
                })
              }
            >
              {isLicensed ? "Unlicense" : "License"} track
            </Button>
          )}

          <ImageField source="image" />
        </FormTab>
      )}
      {source === "manual" && (
        <FormTab label="details" {...props}>
          <TextField source="id" label="Database ID" />
          <ReadOnlyField addLabel label="ISRC" source="isrc" />
          <ReadOnlyField addLabel label="Source" source="source" />
          <TextInput source="title" />
          <TextInput source="label" />
          <AutocompleteArrayInput
            onCreate={(name) => {
              let artistName = name;
              if (!artistName) {
                // eslint-disable-next-line no-alert
                artistName = prompt("Enter a new artist name");
              }
              const newArtist = { id: artistName, name: artistName };
              trackArtists.push(newArtist);
              return newArtist;
            }}
            source="artists"
            choices={trackArtists}
          />
          <AutocompleteArrayInput
            onCreate={(name) => {
              let genreName = name;
              if (!genreName) {
                // eslint-disable-next-line no-alert
                genreName = prompt("Enter a new genre");
              }
              const newGenre = { id: genreName, name: genreName };
              trackGenres.push(newGenre);
              return newGenre;
            }}
            source="genre"
            choices={trackGenres}
          />
          <TextInput source="albumName" />
          <TextInput source="copyright" />
          <DateInput source="releaseDate" />
          <BooleanInput source="isExplicit" label="Explicit" />
          <BooleanInput source="isLicensed" label="Licensed" />
          <TextInput source="image" label="Image URL" />
          <ImageField source="image" />
        </FormTab>
      )}
    </>
  );
}

DetailsTab.propTypes = {
  trackArtists: PropTypes.array.isRequired,
  trackGenres: PropTypes.array.isRequired,
};
