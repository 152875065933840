import { gql } from "@apollo/client";

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on ClassCategory {
    id
    createdAt
    updatedAt
    slug
    hidden
    name
    description
    sortIndex  
    thumbnailImageId
    thumbnailImageUrl
    isOnboarding
    previewVideo {
        id
        hlsPlaybackUrls {
            defaultUrl
        }
    }
  }
`;

export const GET_LIST_CATEGORIES = gql`
  ${CATEGORY_FRAGMENT}

  query AllCategories($sort: OrderByInput, $pagination: ConnectionArgs, $filter: CategoryFilterInput) {
    admin {
      categories(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...CategoryFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_CATEGORY = gql`
  ${CATEGORY_FRAGMENT}
  query CategoryById($id: String!) {
    admin {
      categoryById(id: $id) {
        ...CategoryFragment
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  ${CATEGORY_FRAGMENT}
  mutation CreateCategory($input: CategoryCreateInput!) {
    adminCategoryCreate(input: $input) {
      ...CategoryFragment
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  ${CATEGORY_FRAGMENT}
  mutation UpdateCategory($input: CategoryUpdateInput!) {
    adminCategoryUpdate(input: $input) {
      ...CategoryFragment
    }
  }
`;

export const CREATE_CATEGORY_ASSET = gql`
  mutation CategoryAssetUploadCreate($assetType: CategoryAssetType!) {
    adminCategoryAssetUploadCreate(assetType: $assetType) {
      id
      signedUrl
    }
  }
`;
