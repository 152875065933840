import React from "react";
import { FunctionField, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { createAutoLoginToken } from "../../../../data/users";

export function LoginAsUserField() {
  const notify = useNotify();

  return (
    <FunctionField label="Login As User" render={record => (
      <Button
        onClick={async () => {
          const ct = await createAutoLoginToken(record.uid);

          const url = `${process.env.REACT_APP_CLIENT_APP_URL}/login?_ct=${ct}`;
          await navigator.clipboard.writeText(url);

          notify("Auto-login URL has been copied! Use an INCOGNITO TAB unless you are already logged out of the web app!");
        }}
        color="primary"
        variant="contained"
        size="small"
      >
        Copy Login URL
      </Button>
    )} />
  );
}
