import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export function VideoUploadDetail({ record }) {
  return (
    <>
      <div>
        <u>Description</u>: {record.description}
      </div>
      <div>
        <u>Uploaded By</u>: {record.uploadedBy.username}
        <Link to={`/videoUploads?displayedFilters={"userId":true}&filter={"userId":"${record.uploadedBy.id}"}`}>
          ({record.uploadedBy.id})
        </Link>
      </div>
    </>
  );
}

VideoUploadDetail.propTypes = {
  record: PropTypes.shape({
    description: PropTypes.string,
    uploadedBy: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string
    }),
    userId: PropTypes.string
  }),
};
