import { Typography } from "@material-ui/core";
import React from "react";
import { Create, SelectInput, SimpleForm, TextInput } from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { DateTimeInput } from "lib/components/DateTime";
import { validateChallenge } from "./utils";

export const ChallengeCreate = withEnums(({ enums, ...props }) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm validate={validateChallenge}>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Create Challenge
        <HelpInfo helpInfos={[
          ["Create Challenge"],
          ["Class ID", "Enter in the class ID number to be associated with this challenge. Ex: 1583"],
          ["Title", "Enter in the title for the challenge. Maximum 255 characters."],
          ["Description", "Enter in the description for the challenge. Maximum 255 characters."],
          ["Status", "None is when the challenge is not promoted and hidden. Approved is when the challenge is promoted and visible. Deleted is when the challenge is soft deleted and hidden."],
          ["Start Time", "Set the date and time when the challenge should start being visible to users. This is in your local time zone."],
          ["End Time", "Set the date and time when the challenge should stop being visible to users. This is in your local time zone."],
        ]} />
      </Typography>
      <TextInput label="Class ID" source="forClass.id" fullWidth />
      <TextInput source="title" fullWidth />
      <TextInput source="description" fullWidth multiline />
      <SelectInput
        source="challengeStatus"
        choices={enums.challengeStatus.map(status => ({
          id: status,
          name: status
        }))}
      />
      <DateTimeInput label="Start Time" source="startTime" />
      <DateTimeInput label="End Time" source="endTime" />
    </SimpleForm>
  </Create>
));
