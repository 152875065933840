import { client } from "../gqlClient";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort,
} from "../utils";
import {
  GET_LIST_VIDEO_UPLOADS,
  GET_ONE_VIDEO_UPLOAD,
  UPDATE_VIDEO_UPLOAD
} from "./schema";

export async function getListVideoUploads(params) {
  const data = await client.query(
    GET_LIST_VIDEO_UPLOADS,
    {
      filter: params.filter,
      pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
      sort: convertAdminSortToGraphqlSort(params.sort)
    },
    "videoUploads"
  );
  return {
    data: data.edges.map(e => e.node),
    total: data.totalCount,
  };
}

export async function videoUploadUpdate(params) {
  const { id, data } = params;
  const videoUpload = await client.mutate(
    UPDATE_VIDEO_UPLOAD,
    {
      input: {
        videoId: id,
        approvalStatus: data.approvalStatus,
      }
    },
    "videoUploadAdminUpdate"
  );
  return { data: videoUpload };
}

export async function getOneVideoUpload(params) {
  const { id } = params;
  const videoUpload = await client.query(
    GET_ONE_VIDEO_UPLOAD,
    {
      input: {
        id,
      }
    },
    "videoUploadById"
  );
  return {
    data: videoUpload
  };
}
