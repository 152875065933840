import * as React from "react";
import { render } from "react-dom";
import { App } from "app";
import { initSentry } from "lib/utils/sentry";

initSentry();

render(
  <App />,
  document.getElementById("root")
);
