import { Typography } from "@material-ui/core";
import React from "react";
import { Edit, SaveButton, SelectInput, SimpleForm, Toolbar } from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { ReadOnlyField } from "lib/components/ReadOnlyField";

export const VideoUploadEdit = withEnums(({ enums, ...props }) => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm toolbar={<VideoUploadEditToolbar />} validate={validateVideoUploadEdit} warnWhenUnsavedChanges>
      <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
        Edit Video
        <HelpInfo helpInfos={[
          ["Edit Video", ""],
        ]} />
      </Typography>
      <ReadOnlyField addLabel label="Database ID" source="id" />
      <SelectInput
        source="approvalStatus"
        choices={enums.videoApprovalStatus.map(status => ({
          id: status,
          name: status
        }))}
      />
      <Typography variant="body2">
        Note: If you want to reject a video, please Flag the video instead so there is proper reporting.
      </Typography>
    </SimpleForm>
  </Edit>
));

function VideoUploadEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

const REQUIRED = ["approvalStatus"];

function validateVideoUploadEdit(values) {
  const errors = {};

  REQUIRED.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
