import { gql } from "@apollo/client";

const CLASS_VIDEO_FRAGMENT = gql`
    fragment ClassVideoFragment on ClassVideo {
        id
        encodingStatus
        error
        createdAt
        stepFunctionArn
        mediaConvertJobIds
        classVideoType
        hlsPlaybackUrls {
            defaultUrl
            backUrl
            frontUrl
        }
    }
`;

export const GET_LIST_CLASS_VIDEOS = gql`
    ${CLASS_VIDEO_FRAGMENT}

    query ClassVideos($sort: OrderByInput, $pagination: ConnectionArgs, $filter: ClassVideoFilterInput) {
        classVideos(sort: $sort, pagination: $pagination, filter: $filter) {
            totalCount
            edges {
                node {
                    ...ClassVideoFragment
                }
                cursor
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
`;

export const CLASS_VIDEO_FILE_UPLOAD_CREATE = gql`
    mutation ClassVideoFileUploadCreate($input: ClassVideoFileCreateInput!) {
        classVideoFileUploadCreate(input: $input) {
            id
            s3PartsUrls {
                partNumber
                url
            }
            uploadId
        }
    }
`;

export const CLASS_VIDEO_FILE_UPLOAD_COMPLETE = gql`
    mutation ClassVideoUploadComplete($input: VideoUploadCompleteInput!) {
        classVideoFileUploadComplete(input: $input) {
            id
        }
    }
`;
