import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import FilterListIcon from "@material-ui/icons/FilterList";
import { CardContent } from "@material-ui/core";
import * as React from "react";
import {
  Datagrid,
  EditButton,
  ExportButton,
  FilterList,
  FilterListItem,
  List,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import { withEnums } from "data/utils";
import { HelpInfo } from "lib/components/HelpInfo";
import { FilterSidebar } from "lib/components/FilterSidebar";
import { TimeFilterOptionsList } from "lib/components/TimeFilterOptionsList";
import { DateTimeField } from "lib/components/DateTime";
import { FlaggedActivityContentField } from "./components/FlaggedActivityContentField";
import { FlaggedActivityDetail } from "./components/FlaggedActivityDetail";
import { useFlaggedActivityListStyles } from "./styles";

const flaggedActivityFilters = [
  <TextInput label="Video or Comment ID" source="activityId" alwaysOn style={{ minWidth: "340px" }} />
];

const FlaggedActivityFilterSidebar = withEnums(({ enums }) => {
  const classes = useFlaggedActivityListStyles();

  return (
    <FilterSidebar>
      <CardContent className={classes.filterCard}>
        <div className={classes.helpButton}>
          <HelpInfo helpInfos={[
            ["Filters"],
            ["Reported", "This is when the video/comment has been reported by either a user or an admin user."],
            ["Type", "Video or Comment"],
            ["Status", "Reported is when the video/comment is reported. Reviewed is when an admin user has reviewed the report and made a decision. Pending review is not used."],
            ["Flagged As", "This is the reason the user or admin user has reported the video/comment."],
          ]} />
        </div>
        <TimeFilterOptionsList label="Reported" filterFieldPrefix="reported" />
        <FilterList label="Type" icon={<FilterListIcon />}>
          {enums.activityType.map(activityType => (
            <FilterListItem label={activityType} key={activityType} value={{ activityType }} />
          ))}
        </FilterList>
        <FilterList label="Status" icon={<FilterListIcon />}>
          {enums.activityReportStatus.map(reportStatus => (
            <FilterListItem label={reportStatus} key={reportStatus} value={{ reportStatus }} />
          ))}
        </FilterList>
        <FilterList label="Flagged as" icon={<FilterListIcon />}>
          {enums.activityReportReason.map(reportReason => (
            <FilterListItem label={reportReason} key={reportReason} value={{ reportReason }} />
          ))}
        </FilterList>
      </CardContent>
    </FilterSidebar>
  );
});

export function FlaggedActivityList(props) {
  const classes = useFlaggedActivityListStyles();

  return (
    <List
      {...props}
      perPage={10}
      sort={{ field: "updatedAt", order: "DESC" }}
      filters={flaggedActivityFilters}
      bulkActionButtons={false}
      aside={<FlaggedActivityFilterSidebar />}
      actions={<FlaggedActivityListActions />}
    >
      <Datagrid
        expand={<FlaggedActivityDetail />}
        isRowExpandable={row => row.reviewNotes}
      >
        <TextField source="reportReason" label="Reason" sortable={false} />
        <TextField source="reportStatus" label="Status" sortable={false} />
        <TextField source="reviewDecision" label="Decision" sortable={false} />
        <DateTimeField source="createdAt" label="Reported On" />
        <DateTimeField source="updatedAt" label="Last Updated" />
        <FlaggedActivityContentField label="Content" />
        <EditButton
          label="Review"
          icon={<PlaylistAddCheckIcon />}
          className={classes.highlightedEditButton}
          basePath="/flaggedActivity" />
      </Datagrid>
    </List>
  );
}

// using custom list actions here to hide Create button
function FlaggedActivityListActions() {
  return (
    <TopToolbar>
      <ExportButton />
    </TopToolbar>
  );
}
