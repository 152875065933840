import React from "react";
import { FunctionField, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { randomizeUserPersonalizedPlaylist } from "../../../../data/users";

export function RandomizePersonalizedPlaylistButton() {
  const notify = useNotify();

  return (
    <FunctionField label="Delete User Auth" render={record => (
        <Button
            onClick={async () => {
              await randomizeUserPersonalizedPlaylist(record.uid);
              notify("Personalized playlist generate");
            }}
            color="primary"
            variant="contained"
        >
          Randomize personalized playlist
        </Button>
    )} />
  );
}
