import { makeStyles } from "@material-ui/core/styles";

export const useNavbarStyles = makeStyles({
  appBar: {
    backgroundColor: "#000000",
  },
  typography: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    width: "89px",
    marginRight: "4px"
  },
  adminTitle: {
    position: "relative",
    top: "2px", // slight misalignment with text and logo that is caused by existing MUI styles
  },
});

export const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "white",
  },
});
