import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { HelpInfo } from "lib/components/HelpInfo";
import { getRequiredFieldErrors } from "../../utils";

export function CategoryCreate(props) {
  return (
    <Create {...props} mutationMode="pessimistic">
      <SimpleForm validate={validateCreateCategory}>
        <Typography variant="h6" style={{ display: "flex", alignItems: "center" }}>
          Create Category
          <HelpInfo helpInfos={[
            ["Create Category"],
            ["Name", "Enter the unique name for the category"],
          ]} />
        </Typography>
        <TextInput label="Name" source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
}

function validateCreateCategory(values) {
  return getRequiredFieldErrors(values, ["name"]);
}
