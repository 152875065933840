import { gql } from "@apollo/client";

const TRACK_FRAGMENT = gql`
  fragment TrackFragment on Track {
    id
    createdAt
    updatedAt
    trackId
    songId
    isrc
    title
    artists
    label
    copyright
    releaseDate
    image
    genre
    isExplicit
    isLicensed
    youtube
    spotify
    appleMusic
    soundcloud
    tikTokSound
    source
  }
`;

export const GET_ALL_TRACK_ARTISTS = gql`
  query TrackArtists {
    trackArtists {
      id
      name
    }
  }
`;

export const GET_ALL_TRACK_GENRES = gql`
  query TrackGenres {
    trackGenres {
      id
      name
    }
  }
`;

export const GET_LIST_TRACKS = gql`
  ${TRACK_FRAGMENT}
  query Tracks(
    $sort: OrderByInput
    $pagination: ConnectionArgs
    $filter: TrackFilterInput
  ) {
    tracks(sort: $sort, pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          ...TrackFragment
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ONE_TRACK = gql`
  ${TRACK_FRAGMENT}
  query TrackById($id: String!) {
    trackById(id: $id) {
      ...TrackFragment
    }
  }
`;

export const UPSERT_TRACK = gql`
  ${TRACK_FRAGMENT}
  mutation trackUpsert($input: TrackUpsertInput!) {
    trackUpsert(input: $input) {
      ...TrackFragment
    }
  }
`;

export const UPDATE_TRACK_LICENSE = gql`
  ${TRACK_FRAGMENT}
  mutation updateTrackLicense($input: TrackLicenseUpdateInput!) {
    updateTrackLicense(input: $input) {
      ...TrackFragment
    }
  }
`;
