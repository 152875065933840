import { gql } from "@apollo/client";
import { TAG_FRAGMENT } from "../tags/schema";

const STUDIO_CLASS_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment StudioClassFragment on AdminStudioClass {
    id
    classId
    accessType
    title
    slug
    hidden
    shouldBeMuted
    externalPreviewUrl
    isFamilyFriendly
    thumbnailImageUrl
    instructor {
      id
      name
    }
    style
    type
    level
    categories {
      id
      slug
      name
    }
    publishDate
    tracks {
      id
      startsAt
      track {
        id
        artists
        isExplicit
        isLicensed
        isrc
        title
        label
        source
      }
    }
    video {
      id
      hlsPlaybackUrls {
        defaultUrl
        backUrl
        frontUrl
      }
    }
    previewVideo {
      id
      hlsPlaybackUrls {
        defaultUrl
      }
    }
    sections {
      name
      cuepoints {
        name
        timeSec
      }
    }
    tags {
      ...TagFragment
    }
    mutedCuepoints {
      externalUrl
      endsAtTime
      startsAtTime
      endsAtTimeSec
      startsAtTimeSec
    }
  }
`;

const PLAYLIST_CLASS_FRAGMENT = gql`
  ${TAG_FRAGMENT}
  fragment PlaylistClassFragment on StudioClass {
    id
    classId
    accessType
    title
    slug
    hidden
    shouldBeMuted
    externalPreviewUrl
    isFamilyFriendly
    thumbnailImageUrl
    instructor {
      id
      name
    }
    style
    type
    level
    publishDate
    video {
      id
      hlsPlaybackUrls {
        defaultUrl
        backUrl
        frontUrl
      }
    }
    previewVideo {
      id
      hlsPlaybackUrls {
        defaultUrl
      }
    }
    sections {
      name
      cuepoints {
        name
        timeSec
      }
    }
    tags {
      ...TagFragment
    }
  }
`;

export const GET_LIST_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  query AllStudioClasses(
    $sort: OrderByInput
    $pagination: ConnectionArgs
    $filter: ClassFilterInput
  ) {
    admin {
      studioClasses(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...StudioClassFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_LIST_STUDIO_CLASS_BY_PLAYLIST_ID = gql`
  ${PLAYLIST_CLASS_FRAGMENT}
  query AllStudioClassesByPlaylistId(
    $playlistId: String!
    $pagination: ConnectionArgs!
  ) {
    playlist(playlistId: $playlistId) {
      id
      studioClasses(pagination: $pagination) {
        edges {
          node {
            ...PlaylistClassFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
`;

export const GET_ONE_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  query StudioClassByClassId($classId: Float!) {
    admin {
      studioClassByClassId(classId: $classId) {
        ...StudioClassFragment
      }
    }
  }
`;

export const GET_CLASS_STYLES = gql`
  query AllClassStyles {
    admin {
      allClassStyles {
        name
        slug
      }
    }
  }
`;

export const GET_CLASS_TYPES = gql`
  query AllClassTypes {
    admin {
      allClassTypes {
        name
      }
    }
  }
`;

export const GET_CLASS_LEVELS = gql`
  query AllClassLevels {
    admin {
      allClassLevels {
        name
      }
    }
  }
`;

export const CREATE_STUDIO_CLASS_ASSET = gql`
  mutation StudioClassAssetUploadCreate($assetType: StudioClassAssetType!) {
    adminStudioClassAssetUploadCreate(assetType: $assetType) {
      id
      signedUrl
    }
  }
`;

export const CREATE_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation CreateStudioClass($input: StudioClassCreateInput!) {
    adminStudioClassCreate(input: $input) {
      ...StudioClassFragment
    }
  }
`;

export const UPDATE_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation UpdateStudioClass($input: StudioClassUpdateInput!) {
    adminStudioClassUpdate(input: $input) {
      ...StudioClassFragment
    }
  }
`;

export const PUBLISH_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation PublishStudioClass($studioClassId: String!) {
    adminStudioClassPublish(studioClassId: $studioClassId) {
      ...StudioClassFragment
    }
  }
`;

export const UNPUBLISH_STUDIO_CLASS = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation studioClassUnPublish($studioClassId: String!) {
    adminStudioClassUnPublish(studioClassId: $studioClassId) {
      ...StudioClassFragment
    }
  }
`;

export const STUDIO_CLASS_ADD_TRACK = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation StudioClassAddTrack($input: ClassTrackInput!) {
    adminStudioClassAddTrack(input: $input) {
      ...StudioClassFragment
    }
  }
`;

export const STUDIO_CLASS_REMOVE_TRACK = gql`
  ${STUDIO_CLASS_FRAGMENT}
  mutation StudioClassRemoveTrack($input: ClassTrackInput!) {
    adminStudioClassRemoveTrack(input: $input) {
      ...StudioClassFragment
    }
  }
`;
