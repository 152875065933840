import React from "react";
import { Button, useMutation, useNotify, useRedirect } from "react-admin";
import { Typography } from "@material-ui/core";
import UnpublishedIcon from "@material-ui/icons/RemoveCircle";
import PublishIcon from "@material-ui/icons/Publish";
import PropTypes from "prop-types";
import { usePublishEditAsideStyles } from "./styles";

export function PublishEditAside({ record, config, isFormDirty }) {
  const classes = usePublishEditAsideStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const id = record?.id;

  // Used for studio classes to accommodate numeric class ID's.
  const uuid = record?.uuid;

  const [onPublish, { loading: isPublishing }] = useMutation(
    {
      type: "publish",
      resource: config.resource,
      payload: { id, uuid }
    }, {
      onSuccess: () => {
        redirect(`/${config.resource}`);
        notify(`${config.resourceLabel} published.`);
      },
      onFailure: error => {
        notify(
          `Publishing approval error: ${error.message}`,
          { type: "error" }
        );
      },
    });

  const [onUnpublish, { loading: isUnPublishing }] = useMutation(
    {
      type: "unPublish",
      resource: config.resource,
      payload: { id, uuid }
    }, {
      onSuccess: () => {
        redirect(`/${config.resource}`);
        notify(`${config.resourceLabel} hidden from the apps successfully.`);
      },
      onFailure: error => {
        notify(
          `Unpublishing error: ${error.message}`,
          { type: "error" }
        );
      }
    });

  const lowercaseResourceLabel = config.resourceLabel.toLowerCase();

  return (
    <div id="aside" className={classes.asideContainer}>
      {record &&
        <>
          {!record.hidden &&
            <>
              <div className={classes.asideSpaceForSave} />
              <Typography variant="h6">Unpublish</Typography>
              <Typography variant="body2">
                This {lowercaseResourceLabel} has been published. Click the Hide button below to unpublish the {lowercaseResourceLabel}.
              </Typography>
              <br />
              <Button
                fullWidth
                variant="contained"
                className={classes.publishButton}
                onClick={onUnpublish}
                disabled={isFormDirty || isUnPublishing}
              >
                <>
                  <UnpublishedIcon />
                  Hide
                </>
              </Button>
            </>
          }
          {record.hidden &&
            <>
              <div className={classes.asideSpaceForSave} />

              <Typography variant="h6">Publish</Typography>
              <Typography variant="body2">
                If the preview looks good, approve the pending changes and publish them live.
              </Typography>
              <br/>
              <Button
                fullWidth
                variant="contained"
                className={classes.publishButton}
                onClick={onPublish}
                disabled={isFormDirty || isPublishing}
              >
                <>
                  <PublishIcon />
                  Approve & Publish
                </>
              </Button>
            </>
          }
        </>
      }
    </div>
  )
}

PublishEditAside.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string || PropTypes.number,
    uuid: PropTypes.string,
    hidden: PropTypes.bool,
  }),
  config: PropTypes.shape({
    resource: PropTypes.string,
    resourceLabel: PropTypes.string,
  }),
  isFormDirty: PropTypes.bool,
};
