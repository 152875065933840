import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";
import { Typography } from "@material-ui/core";
import React from "react";
import { ReadOnlyField } from "lib/components/ReadOnlyField";
import {
  RandomizePersonalizedPlaylistButton
} from "./components/RandomizePersonalizedPlaylistButton";

export function UserEdit(props) {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm toolbar={<UserEditToolbar />} validate={validateUser} warnWhenUnsavedChanges>
        <Typography variant="h6">Edit User Roles</Typography>
        <ReadOnlyField addLabel label="Email" source="email" />
        <ReadOnlyField addLabel label="UID" source="uid" />
        <ReadOnlyField addLabel label="ID" source="id" />
        <RandomizePersonalizedPlaylistButton/>
        <ReferenceArrayInput label="Role" source="roles" reference="roles">
          <AutocompleteArrayInput optionValue="name" allowEmpty />
        </ReferenceArrayInput>
        {/*<BooleanInput label="Has Community Access" source="hasCommunityAccess" />*/}
      </SimpleForm>
    </Edit>
  );
}

function UserEditToolbar(props) {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
}

function validateUser(values) {
  const errors = {};
  const required = ["email"];

  required.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
