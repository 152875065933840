import { gql } from "@apollo/client";

const PLAYLIST_FRAGMENT = gql`
  fragment PlaylistFragment on PlaylistV2 {
    id
    name
    hidden
    displayIndex
    thumbnailImageUrl
  }
`;

export const GET_LIST_PLAYLISTS = gql`
  ${PLAYLIST_FRAGMENT}

  query AllPlaylists(
    $sort: OrderByInput
    $pagination: ConnectionArgs
    $filter: PlaylistFilterInput
  ) {
    admin {
      playlists(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...PlaylistFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_PLAYLIST = gql`
  ${PLAYLIST_FRAGMENT}
  query PlaylistById($playlistId: String!) {
    playlist(playlistId: $playlistId) {
      ...PlaylistFragment
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  ${PLAYLIST_FRAGMENT}
  mutation CreatePlaylist($name: String!) {
    adminPlaylistCreate(name: $name) {
      ...PlaylistFragment
    }
  }
`;

export const CREATE_PLAYLIST_ASSET = gql`
  mutation PlaylistAssetUploadCreate($assetType: PlaylistAssetType!) {
    adminPlaylistAssetUploadCreate(assetType: $assetType) {
      id
      signedUrl
    }
  }
`;

export const UPDATE_PLAYLIST = gql`
  ${PLAYLIST_FRAGMENT}
  mutation UpdatePlaylist($input: PlaylistUpdateInput!) {
    adminPlaylistUpdate(input: $input) {
      ...PlaylistFragment
    }
  }
`;

export const ADD_CLASS_PLAYLIST = gql`
  mutation AddClassPlaylist($input: PlaylistAddOrRemoveStudioClassInput!) {
    adminPlaylistAddClass(input: $input)
  }
`;

export const REMOVE_CLASS_PLAYLIST = gql`
  mutation RemoveClassPlaylist($input: PlaylistAddOrRemoveStudioClassInput!) {
    adminPlaylistRemoveClass(input: $input)
  }
`;
