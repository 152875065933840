import { apolloClient } from "./apolloClient";

export const client = {
  query: async (
      querySchema,
      variables,
      queryName,
      fetchPolicy="no-cache"
  ) => {
    const queryResult = await apolloClient.query({
      query: querySchema,
      variables,
      fetchPolicy
    });

    if (!queryResult?.data) {
      return null;
    }

    let { data } = queryResult;

    if (queryResult.data.admin) {
      /**
       * Starting to "namespace" admin specific resolvers,
       * which will usually be the case for the top level queries.
       * This way, both `query > ...` && `query > admin > ...` are supported.
       */
      data = data.admin;
    }

    return (queryName ? data[queryName] : data);
  },

  mutate: async (mutateSchema, variables, mutationName) => {
    const mutationResult = await apolloClient.mutate({
      mutation: mutateSchema,
      variables,
    });

    const data = mutationResult?.data;

    if (!data) {
      return null;
    }

    if (!mutationName) {
      return data;
    }

    return data[mutationName];
  },
};
