import React, { useState } from "react";
import {
  Edit,
  FunctionField,
  ImageInput,
  NumberInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import {
  PublishEditAside,
  PublishEditToolbar,
} from "lib/components/PublishEdit";
import { RESOURCE_TYPE } from "lib/constants/resourceType";
import Alert from "@material-ui/lab/Alert";
import { getRequiredFieldErrors } from "../../utils";
import { Classes } from "./components/Classes";

export function PlaylistEdit(props) {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <>
      <Edit
        actions={false}
        {...props}
        mutationMode="pessimistic"
        aside={
          <PublishEditAside
            config={{
              resource: RESOURCE_TYPE.PLAYLISTS,
              resourceLabel: "Playlist",
            }}
            isFormDirty={isFormDirty}
          />
        }
      >
        <SimpleForm
          toolbar={<PublishEditToolbar setIsFormDirty={setIsFormDirty} />}
          validate={validateEditPlaylist}
          warnWhenUnsavedChanges
        >
          <Typography variant="h6">Edit Playlist</Typography>
          <TextField source="id" label="Database ID" />
          <TextInput source="name" fullWidth />
          <NumberInput source="displayIndex" step={1} min={0} />
          <ImageInput
            source="thumbnailImageUrl"
            multiple={false}
            label="Image"
            accept="image/jpeg"
          >
            <FunctionField
              render={(record) => {
                const url = record?.rawFile
                  ? URL.createObjectURL(record.rawFile)
                  : record;
                return (
                  <>
                    <img
                      alt="thumbnail"
                      src={url}
                      style={{ width: "400px" }}
                      onError={(e) => {
                        const { target } = e;
                        target.src = PlaceholderImg;
                      }}
                    />
                    <Alert severity="warning">
                      If playlist thumbnail is missing, it will show the
                      thumbnail of the first class
                    </Alert>
                  </>
                );
              }}
            />
          </ImageInput>
        </SimpleForm>
      </Edit>

      <Show {...props} actions={null}>
        <Classes />
      </Show>
    </>
  );
}

function validateEditPlaylist(values) {
  return getRequiredFieldErrors(values, ["name"]);
}
