const REQUIRED =  ["isrc"];

export function validateTrack(values) {
  const errors = {};

  REQUIRED.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  return errors;
}
