import React from "react";
import { FunctionField, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { deleteUserAuth } from "../../../../data/users";

export function DeleteUserAuthField() {
  const notify = useNotify();

  return (
    <FunctionField label="Delete User Auth" render={record => (
      <Button
        onClick={async () => {
          // eslint-disable-next-line no-alert
          if (window.confirm("Are you sure you want to delete this user's auth and PII?")) {
            await deleteUserAuth(record.uid);
            notify("User auth and PII deleted.");
          }
        }}
        color="secondary"
        variant="contained"
        size="small"
      >
        Delete User Auth
      </Button>
    )} />
  );
}
