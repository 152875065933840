const REQUIRED = ["title", "startTime", "endTime", "challengeStatus"]

export function validateChallenge(values) {
  const errors = {};

  REQUIRED.forEach(requiredField => {
    if (!values[requiredField]) {
      errors[requiredField] = ["ra.validation.required"];
    }
  });

  if (!values.forClass || !values.forClass.id) {
    errors.forClass = { id: ["ra.validation.required"] };
  }

  return errors;
}
