import * as React from "react";
import {
  Datagrid,
  FunctionField,
  Pagination,
  ReferenceManyField,
  useMutation,
  useNotify,
  useRecordContext,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { withEnums } from "data/utils";
import { DateTimeField } from "lib/components/DateTime";
import { PlayVideoField } from "lib/components/PlayVideoField";
import { EncodingStatusField } from "lib/components/EncodingStatusField";
import { videoTypeEnumToLabel } from "data/classVideos";
import { UploadVideo } from "lib/components/UploadVideo";
import PropTypes from "prop-types";
import { ConfirmButtonAndModal } from "lib/components/ConfirmButtonAndModal";
import { HelpInfo } from "lib/components/HelpInfo";

export const Videos = withEnums(({ enums, ...props }) => (
  <Box p={1}>
    <Box display="flex" alignItems="center" p={1}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Videos</Typography>
        <HelpInfo helpInfos={[
          [
            "Main Video",
            "The main video is the video for the class."
          ],
          [
            "Preview Video",
            "The preview video is a short video that can be played in the thumbnail of a class in a list of classes."
          ],
          [
            "Encoding Status",
            `Requested is when you have made the intent to start uploading a video.
            
            Uploaded is when you have successfully uploaded a video. 
            
            Available is when the video has finished encoding and is ready to watch.
            
            Error is when there has been an issue with encoding. Click on the encoding status to see the specific error.`
          ]
        ]} />
      </div>
      <UploadVideo
        ownerId={props.record?.uuid}
        ownerType="class"
        allowedVideoTypes={enums.classVideoType}
        subtitle={props.record?.title}
        helpInfo={
          <HelpInfo helpInfos={[
            [
              "Class Front Back View",
              "This video is of a 16x18 aspect ratio, where the front facing 16x9 video is on the top and the back facing 16x9 video is on the bottom. Any other aspect ratio will not work. This is the main video for the class."
            ],
            [
              "Class Single View",
              "The video is of a 16x9 aspect ratio. This is the main video for the class."
            ],
            [
              "Class Preview",
              "The video is of a 16x9 aspect ratio. This is the preview video for the class."
            ],
            [
              "Uploading Tips",
              "Please keep your browser tab open until the upload progress bar has finished or the upload will be stopped. Ensure you have a good internet connection before starting the upload."
            ]
          ]} />
        }
      />
    </Box>
    <Box pl={1} pb={1} style={{ display: "flex" }}>
      <div>
        <Typography variant="overline">Main Video</Typography>
        <PlayVideoField source="video.hlsPlaybackUrls" />
      </div>
      <div style={{ marginLeft: "30px" }}>
        <Typography variant="overline">Preview Video</Typography>
        <PlayVideoField source="previewVideo.hlsPlaybackUrls" />
      </div>
    </Box>
    <ReferenceManyField
      label="Videos"
      reference="classVideos"
      target="id"
      filter={{ encodingStatuses: enums.videoEncodingStatus.filter(e => e !== "requested")  }}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={100}
      pagination={<Pagination />}
    >
      <Datagrid>
        <EncodingStatusField source="encodingStatus" label="Source" sortable={false} classUUID={props.record?.uuid} classSlug={props.record?.slug} />
        <DateTimeField label="Created At" source="createdAt" sortable />
        <FunctionField label="Type" render={record => (
          <span>{videoTypeEnumToLabel(record.classVideoType)}</span>
        )} />
        <PlayVideoField label="Video" source="hlsPlaybackUrls" sortable={false} />
        <FunctionField render={record => {
          if (props.record?.previewVideo?.id !== record.id && record.encodingStatus === "available" && record.classVideoType === "preview") {
            return (<SetClassVideoField uuid={props.record?.uuid} videoPropertyName="previewVideoId" videoLabel="Preview" />);
          }
          if (props.record?.video?.id !== record.id && record.encodingStatus === "available" && record.classVideoType !== "preview") {
            return (<SetClassVideoField uuid={props.record?.uuid} videoPropertyName="videoId" videoLabel="Main" />);
          }
          return null;
        }} />
      </Datagrid>
    </ReferenceManyField>
  </Box>
));

function SetClassVideoField({uuid, videoPropertyName, videoLabel, ...props}) {
  const record = useRecordContext(props);

  const notify = useNotify();

  const [updateVideoSelection, { updatingVideoSelection }] = useMutation(
    {
      type: "update",
      resource: "classes",
      payload: {
        uuid,
        data: {
          [videoPropertyName]: record?.id
        }
      }
    }, {
      onSuccess: () => {
        notify("Successfully updated the class video");
      },
      onFailure: error => notify(
        `An error occurred: ${error.message}`,
        { type: "error" }
      ),
    });

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <ConfirmButtonAndModal
        isUpdating={updatingVideoSelection}
        onConfirm={updateVideoSelection}
        buttonText={`Set as ${videoLabel}`}
        title={`Confirm Setting Video as ${videoLabel} Video`}
      />
    </div>
  );
}

SetClassVideoField.propTypes = {
  uuid: PropTypes.string,
  videoPropertyName: PropTypes.string,
  videoLabel: PropTypes.string,
}
