import { makeStyles } from "@material-ui/core";

export const useClassListStyles = makeStyles(theme => ({
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  filterCard: {
    position: "relative",
  },
  helpButton: {
    position: "absolute",
    top: "0px",
    right: "0px"
  },
}));
