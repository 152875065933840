import { makeStyles } from "@material-ui/core";

export const usePublishEditAsideStyles = makeStyles(() => ({
  asideContainer: {
    minHeight: "80vh",
    flex: "0 0 200px",
    padding: "16px",
    backgroundColor: "#ffffff",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "0px",
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  asideSpaceForSave: {
    height: "124px",
  },
  publishButton: {
    height: "37px",
    fontSize: "0.875rem",
    "& svg": {
      padding: "2px"
    }
  }
}));
