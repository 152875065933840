import { gql } from "@apollo/client";
import { ROLE_FRAGMENT } from "../roles/schema";

const USER_FRAGMENT = gql`
  ${ROLE_FRAGMENT}
  fragment UserFragment on User {
    id
    uid
    email
    hasCommunityAccess
    parentalControls {
      isEnabled
      pin
    }
    roles {
      ...RoleFragment
    }
  }
`;

export const GET_ME = gql`
  ${ROLE_FRAGMENT}
  query GetMe($input: UserMeInput) {
    me(input: $input) {
      id
      roles {
        ...RoleFragment
      }
    }
  }
`;

export const GET_LIST_USERS = gql`
  ${USER_FRAGMENT}
  query Users($sort: OrderByInput, $pagination: ConnectionArgs, $filter: UserFilterInput) {
    users(sort: $sort, pagination: $pagination, filter: $filter) {
      totalCount
      edges {
        node {
          ...UserFragment
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ONE_USER = gql`
  ${USER_FRAGMENT}
  query UserById($id: String!) {
    userById(id: $id) {
      ...UserFragment
    }
  }
`;

export const UPDATE_USER = gql`
  ${USER_FRAGMENT}
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      ...UserFragment
    }
  }
`;

export const BULK_ENABLE_COMMUNITY = gql`
    mutation userBulkEnableCommunity($emails: [String!]!) {
        userBulkEnableCommunity(emails: $emails)
    }
`;

export const GET_ONE_USER_BY_EMAIL = gql`
  ${USER_FRAGMENT}
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      ...UserFragment
    }
  }
`;

export const GET_ONE_USER_BY_UID =  gql`
  ${USER_FRAGMENT}
  query UserByUid($uid: String!) {
    userByUid(uid: $uid) {
      ...UserFragment
    }
  }
`;

export const GET_ONE_USER_BY_USERID = gql`
  ${USER_FRAGMENT}
  query UserById($id: String!) {
    userById(id: $id) {
      ...UserFragment
    }
  }
`;

export const GET_USER_PII = gql`
  query UserPII($uid: String!) {
    admin {
      user(uid: $uid) {
        PII
      }
    }
  }
`;

export const UPDATE_USER_LOGIN = gql`
  mutation AdminUserUpdateLogin($input: AdminUserUpdateLogin!) {
    adminUserUpdateLogin(input: $input)
  }
`;

export const CREATE_AUTO_LOGIN_TOKEN = gql`
  mutation CreateAutoLoginToken($uid: String!) {
    adminUserAutoLoginToken(uid: $uid)
  }
`;

export const DELETE_USER_AUTH = gql`
  mutation UserAuthDelete($uid: String!) {
    adminUserAuthDelete(input: { uid: $uid })
}
`;

export const RANDOMIZE_USER_PERSONALIZED_PLAYLIST = gql`
  mutation AdminUserRandomizePersonalizedPlaylist($uid: String!) {
    adminUserRandomizePersonalizedPlaylist(input: { uid: $uid })
  }
`
