import React from "react";
import {
  Create, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput, useGetList,
} from "react-admin";
import { getRequiredFieldErrors } from "../utils";

const withTagTypes = (Component) => (props) => {
  const { data, loading, error } = useGetList("tagTypes");

  if (loading || !!error || !data) {
    return null;
  }

  const tagTypes = Object.values(data);

  return <Component tagTypes={tagTypes} {...props} />;
};

export const TagCreate = withTagTypes(({ tagTypes, ...props }) => {
  return (
    <Create {...props} mutationMode="pessimistic">
      <SimpleForm redirect="list" validate={validateEditTag}>
        <TextInput source="name" />
        <ReferenceInput label="Type" source="type" reference="tagTypes">
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="[OPTIONAL] Slug" source="slug" />
        <NumberInput label="[OPTIONAL] Display Index" source="displayIndex" />
      </SimpleForm>
    </Create>
  );
});

const REQUIRED_FIELDS = ["name", "type"];

function validateEditTag(values) {
  return getRequiredFieldErrors(values, REQUIRED_FIELDS);
}
