import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import get from "lodash.get";
import Tooltip from "@material-ui/core/Tooltip";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import { Modal, DialogContent, Typography } from "@material-ui/core";
import { VideoPlayer } from "../VideoPlayer";
import { usePlayVideoFieldStyles, useVideoPlayerStyles } from "./styles";

export function PlayVideoField(props) {
  const { source } = props;
  const record = useRecordContext(props);

  const classes = usePlayVideoFieldStyles();
  const videoPlayerStyles = useVideoPlayerStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playbackUrl, setPlaybackUrl] = useState(null);

  const playbackData = get(record, source);

  if (!playbackData) {
    return <Typography className={classes.playVideoField}>None</Typography>;
  }

  const playbackLabelWithUrls = typeof playbackData === "object" ?
    Object.keys(playbackData)
      .filter(key => key !== "__typename" && !!playbackData[key])
      .map(key => ({ label: key === "defaultUrl" ? "Play" : key, url: playbackData[key] })) :
    [{ label: "Play", url: playbackData }];

  const handleToggleModal = newPlaybackUrl => {
    setPlaybackUrl(newPlaybackUrl);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className={classes.playVideoField}>
      {playbackLabelWithUrls.map(playbackLabelWithUrl => (
        <span key={playbackLabelWithUrl.label}>
          <Tooltip title={playbackLabelWithUrl.label}>
            <PlayCircleFilled onClick={() => handleToggleModal(playbackLabelWithUrl.url)} />
          </Tooltip>
        </span>
      ))}
      <Modal
        open={isModalOpen}
        onClose={() => handleToggleModal(null)}
      >
        <DialogContent onClick={() => handleToggleModal(null)}>
          <VideoPlayer playbackUrl={playbackUrl} classes={videoPlayerStyles} />
        </DialogContent>
      </Modal>
    </div>
  );
}

PlayVideoField.propTypes = {
  source: PropTypes.string.isRequired,
};
