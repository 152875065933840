import * as React from "react";
import { Card, CardContent, CardHeader, Typography } from "@material-ui/core";
import { HelpInfo } from "lib/components/HelpInfo";

export function Dashboard() {
  return (
    <Card>
      <CardHeader title="Welcome!" />
      <CardContent>
        <Typography>
          Select from the left navigation menu to start editing content.
          <br />
          Click on any{" "}
          <HelpInfo
            helpInfos={[
              [
                "Help Info",
                "You'll see helpful information here in other locations within this app.",
              ],
            ]}
          />{" "}
          in this application to see additional help information.
          <br />
          <br />
          <b>Categories</b>
          <div>Manage Category Metadata and Publishing</div>
          <br />
          <b>Challenges</b>
          <div>Manage Challenge Metadata and Publishing</div>
          <br />
          <b>Classes</b>
          <div>
            Manage Class Metadata, Class Tracks, Class Videos, Class Sections
            and Publishing
          </div>
          <br />
          <b>Flagged Content</b>
          <div>Manage Challenge Video or Comment Reports/Flags From Users</div>
          <br />
          <b>Instructors</b>
          <div>Manage Instructor Metadata and Publishing</div>
          <br />
          <b>Tracks</b>
          <div>Manage Tracks/Songs (manual or Tuned Global)</div>
          <br />
          <b>Users</b>
          <div>Manage Users Admin Access and Challenge Community Access</div>
          <br />
          <b>User Videos</b>
          <div>Manage Challenge Video Approvals</div>
          <br />
        </Typography>
      </CardContent>
    </Card>
  );
}

Dashboard.propTypes = {};
