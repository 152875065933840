import React from "react";
import { FunctionField, useNotify } from "react-admin";
import { Button } from "@material-ui/core";
import { getUserPII } from "../../../../data/users";
import { downloadDataAsJSON } from "../../../../lib/utils";

export function ExportPIIField() {
  const notify = useNotify();

  return (
    <FunctionField label="PII" render={record => (
      <Button
        onClick={async () => {
          notify("Downloading PII. Please wait a few seconds.");

          const data = await getUserPII(record.uid);
          downloadDataAsJSON(record.email, data);
        }}
        color="primary"
        variant="contained"
        size="small"
      >
        Export PII
      </Button>
    )} />
  );
}
