import { gql } from "@apollo/client";

export const TAG_FRAGMENT = gql`
  fragment TagFragment on Tag {
    id
    displayIndex
    name
    previewVideoId
    slug
    thumbnailImageId
    type
  }
`;

export const GET_LIST_TAGS = gql`
  ${TAG_FRAGMENT}
  query Tags(
    $sort: OrderByInput,
    $pagination: ConnectionArgs,
    $filter: TagFilterInput
  ) {
    admin {
      tags(sort: $sort, pagination: $pagination, filter: $filter) {
        totalCount
        edges {
          node {
            ...TagFragment
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_TAG = gql`
  ${TAG_FRAGMENT}
  query TagById($id: String) {
    admin {
      tagById(id: $id) {
        ...TagFragment
      }
    }
  }
`;

export const CREATE_TAG = gql`
    ${TAG_FRAGMENT}
    mutation TagCreate($input: TagCreateInput!) {
        adminTagCreate(input: $input) {
            ...TagFragment
        }
    }
`;

export const UPDATE_TAG = gql`
    ${TAG_FRAGMENT}
    mutation TagUpdate($input: TagUpdateInput!) {
        adminTagUpdate(input: $input) {
            ...TagFragment
        }
    }
`;
