import { client } from "../gqlClient";
import {
  CREATE_TAG,
  GET_LIST_TAGS,
  GET_ONE_TAG,
  UPDATE_TAG
} from "./schema";
import {
  convertAdminPgToGraphqlRelayPg,
  convertAdminSortToGraphqlSort
} from "../utils";

export async function getListTags(params) {
  const vars = {
    filter: params.filter,
    pagination: convertAdminPgToGraphqlRelayPg(params.pagination),
    sort: convertAdminSortToGraphqlSort(params.sort),
  };

  const { tags } = await client.query(GET_LIST_TAGS, vars);

  return {
    data: tags.edges.map(e => e.node),
    total: tags.totalCount,
  };
}

export async function getOneTag({ id }) {
  const { tagById } = await client.query(GET_ONE_TAG, {id});

  return {
    data: tagById,
  };
}

export async function getTagTypes() {
  const vars = {
    pagination: convertAdminPgToGraphqlRelayPg({
      page: 1,
      // Grab "all" tags; realistically should not get to this amount with current use case.
      perPage: 100000,
    })
  };

  const { tags } = await client.query(GET_LIST_TAGS, vars);

  const reducedTagTypes = tags.edges.reduce((acc, edge) => {
    const tag = edge.node;

    if (!acc[tag.type]) {
      acc[tag.type] = 1;
    }

    return acc;
  }, {});

  const tagTypes = Object.keys(reducedTagTypes).map((tt) => {
    return {id: tt, name: tt};
  });

  return {
    data: tagTypes,
    total: tagTypes.length
  };
}

export async function createTag(params) {
  const { data } = params;

  const input = {...data};
  delete input.__typename;

  const res = await client.mutate(
    CREATE_TAG,
    { input },
  );

  return {
    data: res.adminTagCreate,
  };
}

export async function updateTag(params) {
  const { data } = params;

  const input = {...data};
  delete input.__typename;

  const res = await client.mutate(
    UPDATE_TAG,
    { input },
  );

  return {
    data: res.adminTagUpdate
  };
}
